<template>
  <el-dialog :title="type === 1 ? '手动入场' : '编辑'" :visible="visible" :close-on-click-modal="false" class="form-inline-edit" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <!-- <el-form-item label="车牌类型：" prop="plateType" v-if="type !== 2">
        <el-radio-group v-model="datas.plateType">
          <el-radio label="1">正式车牌</el-radio>
          <el-radio label="0">临时车牌</el-radio>
          <el-radio label="2">VIN码</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item v-if="datas.plateType === '2'" label="VIN：" prop="carVinNumber">
        <el-input v-model="datas.carVinNumber" :disabled="type !== 1" placeholder="请输入VIN"/>
      </el-form-item>
      <el-form-item v-else-if="datas.plateType === '0' || datas.plateType === '1' || type === 2" label="车牌号/VIN：" prop="carPlateNumber">
        <el-input v-model="datas.carPlateNumber" :disabled="type !== 1" placeholder="请输入车牌号/VIN" />
      </el-form-item>
      <el-form-item label="颜色：" prop="color">
        <el-select v-model="datas.color" clearable placeholder="请选择颜色">
          <el-option
            v-for="item in colors"
            :key="item"
            :label="item"
            :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="品牌：" prop="brand">
        <el-input v-model="datas.brand" placeholder="请输入品牌"/>
      </el-form-item>
      <el-form-item label="车系：" prop="serial">
        <el-input v-model="datas.serial" placeholder="请输入车系"/>
      </el-form-item>
      <el-form-item label="车型：" prop="type">
        <el-input v-model="datas.type" placeholder="请输入车型"/>
      </el-form-item>
      <el-form-item label="道闸口：" prop="gateName">
        <el-select v-if="shopList.length !== 0" v-model="datas.gateName" clearable placeholder="请选择道闸口" >
          <el-option
            v-for="item in shopList"
            :key="item.deviceName"
            :label="item.deviceName"
            :value="item.deviceName" />
        </el-select>
        <el-select v-if="shopList.length === 0" v-model="deviceList[0].deviceName" placeholder="请选择道闸口">
          <el-option
            :label="deviceList[0].deviceName"
            :value="deviceList[0].deviceName" />
        </el-select>
        <!-- <el-input v-model="datas.gateName" placeholder="请输入道闸口"/> -->
      </el-form-item>
      <el-form-item label="手动入场备注：" prop="remark">
        <el-input v-model="datas.remark" :autosize="{ minRows: 4}" type="textarea" placeholder="请输入备注" maxlength="100" show-word-limit/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from '@/gobal/lib/validate.js'
import { BASE_EDIT_FORM } from './index-config'
export default {
  data() {
    const that = this
    return {
      visible: false,
      type: 1,
      loading: false,
      datas: {
        carPlateNumber: '',
        carVinNumber: '',
        platetype: '1',
        vehicleType: '',
        color: '',
        brand: '',
        serial: '',
        gateName: '',
        type: '',
        remark: ''
      },
      rules: {
        gateName: [{ required: true, message: '请输入道闸口', trigger: 'change' }],
        plateType: [{ required: true, message: '请选择车牌类型', trigger: 'change' }],
        carPlateNumber: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
        carVinNumber: [{ required: true, message: '请输入VIN', trigger: 'blur' }]
      },
      colors: that.$store.state.common.colors,
      shopList: [],
      deviceList: [
        { deviceName: '' }
      ]
    }
  },
  computed: {
    sourceData() {
      return this.$store.state.common.sourceData || []
    }
  },
  mounted() {
    this.getShopList()
  },
  methods: {
    handleChange(value) {
      console.log(value)
    },
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const url = this.baseUrl + '/car/' + (this.type === 1 ? 'create' : 'update')
          const customParams = {
            carSource: 'EXTERNAL',
            carCategory: 'EXTERNAL',
            shopId: Number(localStorage.getItem('shopId')),
            status: 1
          }
          const params = this.$util.preProcessData(Object.assign({}, this.datas, customParams))
          // eslint-disable-next-line no-empty
          if (!('brand' in params)) {
            params.brand = ''
          } else if (!('serial' in params)) {
            params.serial = ''
          } else if (!('type' in params)) {
            params.type = ''
          }
          if (this.datas.plateType === '2') {
            // VIN码
            delete params.carPlateNumber
          } else {
            // 车牌号
            delete params.carVinNumber
          }
          this.$util['post'](this, {
            url,
            params,
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed && this.type !== 1) {
                this.$message.success((this.type === 1 ? '新增' : '修改') + '成功')
                this.$emit('refresh')
                this.cancel()
              }
            },
            complete: (res) => {
              // 手动入场需要先新增车辆在手动入场 errorCode：12003 是车牌号已经创建 则直接手动入场
              if (this.type === 2) {
                this.loading = false
              } else if (this.type === 1 && res.errorCode === 12003 || res.succeed) {
                this.manualEntry(params)
              } else {
                this.loading = false
              }
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    manualEntry(params) {
      const url = this.baseUrlWay + '/gate/approach/manual'
      delete params.carSource
      delete params.carCategory
      this.$util['post'](this, {
        url,
        params,
        config: null,
        errorInfo: false,
        success: (res) => {
          if (res.succeed) {
            this.$message.success('手动入场成功')
            this.$emit('refresh')
            this.cancel()
          }
        },
        complete: (res) => {
          this.loading = false
        }
      })
    },
    // 获取道闸口
    getShopList() {
      const params = {
        shopId: Number(localStorage.getItem('shopId'))
      }
      const url = this.baseUrl + '/shop/join/find'
      this.$util['post'](this, {
        url,
        params,
        success: (res) => {
          if (res.succeed) {
            if (res.data && res.data.length > 0) {
              if (res.data[0].devices.length !== 0) {
                this.shopList = res.data[0].devices
              } if (res.data[0].devices.length === 0) {
                this.deviceList = [{ deviceName: res.data[0].shopName + '入道口' }]
                this.datas.gateName = res.data[0].shopName + '入道口'
              }
            }
          }
        },
        complete: (res) => {
          this.loading = false
        }
      })
    },
    show(data, type) {
      this.type = type
      this.datas = Object.assign({}, BASE_EDIT_FORM, data)
      if (data.carVinNumber && data.carVinNumber !== '') {
        this.$set(this.datas, 'plateType', '2')
      } else {
        this.$set(this.datas, 'plateType', '1')
      }
      if (type === 2) {
        this.getGateName(this.datas.approachId)
      }
      this.visible = true
    },
    // 查询道闸口
    getGateName(approachId) {
      const customParams = {
        perPage: 1,
        page: 1,
        pageStart: 1,
        shopId: Number(localStorage.getItem('shopId')),
        approachId: approachId
      }
      const params = this.$util.preProcessData(Object.assign({}, customParams))

      this.$util.post(this, {
        url: this.baseUrl + '/car/attendance/pageable',
        params,
        config: {},
        success: (res) => {
          console.log(res)
          this.$set(this.datas, 'gateName', res.data.content[0].gateName)
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas = Object.assign({}, BASE_EDIT_FORM, {})
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">

</style>
