<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  font-family: '汉仪中宋简';
}
.el-input input {
  font-family: '汉仪中宋简';
}
.el-textarea textarea {
  font-family: '汉仪中宋简';
}
#app {
  width: 100%;
  height: 100%;
  font-size: 14px;
}

@font-face {
  font-family: "汉仪中宋简";
  src: url("./gobal/css/mbfonts/汉仪中宋简.ttf");
}

@font-face {
  font-family: "CorpoA";
  src: url("./gobal/css/mbfonts/MBCorpoATitleCond-Regular.otf");
}

@font-face {
  font-family: "CorpoS";
  src: url("./gobal/css/mbfonts/MBCorpoSText-Light.otf");
}
</style>
