<template>
  <div class="main-header-box">
    <div class="system-name">
      <img src="../assets/benz-logo-text.png" alt="加载失败" >
      <span>{{ systemName }}</span>
    </div>
    <div class="header-right">
      <span class="gobal-search">
        <el-input
          v-model="searchFilter"
          placeholder="请输入搜索内容"
          suffix-icon="el-icon-search" />
      </span>

      <span class="user-name">
        <el-dropdown>
          <span class="el-dropdown-link">
            <em class="el-icon-user-solid"/>
            {{ userName
            }}<em class="el-icon-arrow-down el-icon--right"/>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="updatePwd">修改密码</el-dropdown-item>
            <!--            <el-dropdown-item>基本信息</el-dropdown-item>-->
            <el-dropdown-item @click.native="topage('appDownload')">App下载</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
      <span class="options-icon">
        <em class="el-icon-s-tools"/>
      </span>
      <span class="options-icon">
        <em class="el-icon-message-solid"/>
      </span>
      <em class="el-icon-switch-button" title="退出" @click="logOut"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchFilter: ''
    }
  },
  computed: {
    userName() {
      return localStorage['userName']
    }
  },
  methods: {
    logOut() {
      this.$util.logOut(this)
    },
    updatePwd() {
      this.$router.push({ name: 'updatePwd' })
    },
    topage(name) {
      this.$router.push({ name: name })
    }
  }
}
</script>

<style lang="less">
@contentHeight: 50px;
.main-header-box {
	width: 100%;
	height: @contentHeight;
	background-color: @header-bg;
	color: @light-bg;
	display: flex;
	.system-name {
		width: 410px;
		height: 100%;
		font-size: 16/192rem;
		text-indent: 0.3em;
		line-height: 70px;

		// font-family: 'trends';
		img {
			width: 162px;
			height: 40px;
      position: relative;
      margin-top: 5px;
			float: left;
			margin-left: 0.5em;
		}
    span {
      position: relative;
      margin-left: 0.5em;
    }
	}
	.header-right {
		flex-grow: 1;
		padding-right: 20px;
		text-align: right;
		height: 100%;
		line-height: @contentHeight;
    color: @header-option-color;
    font-size: 16px;
    .gobal-search {
      width: 200px;
      margin-right: 3em;
      display: inline-block;
      .el-input {
        &__inner {
          background-color: transparent;
          border-radius: 20px;
        }
      }
    }
		.el-dropdown-link {
			cursor: pointer;
			color: @header-option-color;
      font-size: 16px;
      &:hover {
        color: @light-bg;
      }
		}
		.user-name {
			margin-right: 3em;
		}
    .options-icon {
      margin-right: 3em;
      em {
        cursor: pointer;
        &:hover {
          color: @light-bg;
        }
      }
    }
		.el-icon-switch-button {
			cursor: pointer;
		}
	}
}
</style>
