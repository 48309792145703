const BASE_EDIT_FORM = {
  fatherOrganizationId: null,
  flag: 'Y',
  organizationAddr: '',
  organizationEmail: '',
  organizationName: '',
  organizationPhone: '',
  organizationType: '',
  remark: '',
  status: 1,
  organizationId: ''
}
export { BASE_EDIT_FORM }
