const validate = {
  // 是否为整数
  isInteger(rule, value, callback) {
    if ((value || value === 0) && value % 1 !== 0) {
      callback(new Error('只能为整数'))
    } else {
      callback()
    }
  },
  // 是否为URL
  isUrl(rule, value, callback) {
    var regx = new RegExp(
      '^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
      'i'
    )
    if ((value || value === 0) && !regx.test(value)) {
      callback(new Error('url格式不正确'))
    } else {
      callback()
    }
  },
  // 是否为IP地址
  isIp(rule, value, callback) {
    const regx = /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[0-9])\.((1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.){2}(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/
    if ((value || value === 0) && !regx.test(value)) {
      callback(new Error('IP地址格式不正确'))
    } else {
      callback()
    }
  },
  // 是否为端口
  isPort(rule, value, callback) {
    if ((value || value === 0)) {
      if (/^[1-9]\d*|0$/.test(value) && value * 1 >= 0 && value * 1 <= 65535) {
        callback()
      } else {
        callback(new Error('端口格式不正确'))
      }
    } else {
      callback()
    }
  },
  // 是否为MAC地址
  isMacAddress(rule, value, callback) {
    const regx = /^[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}:[A-Fa-f\d]{2}$/
    if ((value || value === 0) && !regx.test(value)) {
      callback(new Error('mac地址不正确！'))
    } else {
      callback()
    }
  },
  // 验证手机号码
  validatePhone(rule, value, callback) {
    const regx = /^[1]{1}[0-9]{10}$/
    if ((value || value === 0) && !regx.test(value)) {
      callback(new Error('手机号格式不正确！'))
    } else {
      callback()
    }
  },
  // 验证邮箱
  validateEmail(rule, value, callback) {
    const regx = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    if ((value || value === 0) && !regx.test(value)) {
      callback(new Error('邮箱格式不正确！'))
    } else {
      callback()
    }
  },
  // 验证用户名
  validateUserName(rule, value, callback) {
    const regx = /^[a-zA-Z]{1}[a-zA-Z0-9_]+$/
    if ((value || value === 0) && !regx.test(value)) {
      callback(new Error('以英文字母开头，只能包含英文字母、数字、下划线！'))
    } else {
      callback()
    }
  }

}
export default validate
