const BASE_EDIT_FORM = {
  carPlateNumber: '',
  carVinNumber: '',
  platetype: '',
  vehicleType: '',
  color: '',
  brand: '',
  serial: '',
  type: '',
  gateName: '',
  remark: '',
  carCategory: ''
}
export { BASE_EDIT_FORM }
