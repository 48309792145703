import axios from 'axios'
import Vue from 'vue'
import handleError from './handleErrer'

const util = {}
const uploadInterface = ['/material/create/local', '/api/ismp/version/create/file']

// 退出
const logOut = (vm) => {
  vm.$message.success('退出成功')
  localStorage.removeItem('token')
  localStorage.removeItem('userName')
  localStorage.removeItem('shopId')
  localStorage.removeItem('shopName')
  localStorage.removeItem('roles')
  if (localStorage.getItem('singlePoint')) {
    localStorage.removeItem('singlePoint')
    window.open(vm.singleLogoutUrl)
    setTimeout(() => {
      vm.$router.push({ name: 'login', query: { v: new Date().getTime() }})
    }, 2000)
  } else {
    vm.$router.push({ name: 'login', query: { v: new Date().getTime() }})
  }
}

util.ajax = function(vm) {
  window.ajaxConfig = Object.assign({}, window.ajaxConfig)
  var utilAjax = axios.create({
    baseURL: '/',
    timeout: 30000
  })
  let isGet = ''
  // 自动构造请求头
  utilAjax.interceptors.request.use(config => {
    config.timeout = window.ajaxConfig.timeOut || 30000
    isGet = config.method === 'get'
    if (uploadInterface.indexOf(config.url) > -1) {
      // 上传二进制文件
      config.headers = {
        'Content-Type': 'multipart/form-data;charset=UTF-8'
      }
      config.timeout = 60 * 60 * 1000 * 10
    } else {
      if (/* config.method === "post" && */ !config.headers['Content-Type']) {
        config.headers = {
          'Content-Type': 'application/json'
        }
      }
    }
    config.headers['Accept'] = '*/*' // 低版本的火狐问题
    var token = localStorage['token']
    // 刷新token
    if (config.url.indexOf('auth/token') !== -1) {
      token = localStorage['refreshToken']
    }
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    } else {
      console.log('丢失token!')
    }
    return config
  })
  // 自动处理响应
  utilAjax.interceptors.response.use(
    response => {
      var status = response.status
      if (status === 200) {
        return Promise.resolve(response)
      }
      !isGet && vm.$message.error(response.data.errorMessage)
      return Promise.reject(response)
    },
    error => {
      var response = error.response
      var request = error.request
      var message = '请求异常'
      if (response.data && response.data.error === 'invalid_token') {
        vm.$message.error('token失效，请重新登录')
        logOut(vm)
        return
      }
      if (!isGet) {
        if (response) {
          if (response.data && (response.data.errorMessage || response.data.error_description)) {
            vm.$message.error(response.data.errorMessage || response.data.error_description)
          } else {
            // vm.$message.error(message)
            handleError(message)
          }
        } else if (request && request.status === 0) {
          vm.$message.error('服务不可用')
        } else {
          vm.$message.error(message)
        }
      }
      if (!response) {
        response = {}
      }
      if (!response.data) {
        response.data = {}
      }
      response.data._messageFlag = true
      return Promise.reject(response)
    }
  )

  Vue.prototype.$http = utilAjax
}

util.post = function(vm, obj) {
  obj = responseObj(vm, obj)
  vm.$http
    .post(
      obj.url,
      obj.params,
      obj.config
    )
    .then(response => {
      if (response && response.data) {
        if (obj.config !== null) {
          obj.success(response.data)
        } else {
          if (response.data.succeed) {
            // 成功
            obj.success(response.data)
          } else {
            // 如果创建车辆的车牌号相同则不报错
            if (response.data.errorCode !== 12003) {
              vm.$message.error(response.data.errorMessage)
            }
            // 失败
            if (obj.errorInfo) {
              obj.error(response.data)
            }
          }
        }
      } else {
        console.log('response响应为空！')
      }
      obj.complete(response.data)
    })
    .catch(error => {
      // 其他错误信息待规整
      if (obj.commonComplete) {
        obj.complete()
      }
      throw error
    })
}

util.put = function(vm, obj) {
  obj = responseObj(vm, obj)
  vm.$http
    .put(obj.url, obj.params)
    .then(response => {
      if (response && response.data) {
        if (obj.config !== null) {
          obj.success(response.data)
        } else {
          if (response.data.succeed) {
            // 成功
            obj.success(response.data)
          } else {
            vm.$message.error(response.data.errorMessage)
            // 失败
            if (obj.errorInfo) {
              obj.error(response.data)
            }
          }
        }
      } else {
        console.log('response响应为空！')
      }
      obj.complete(response.data)
    })
    .catch(error => {
      // 其他错误信息待规整
      if (obj.commonComplete) {
        obj.complete()
      }
      throw error
    })
}
// 删除
util.delete = function(vm, obj) {
  obj = responseObj(vm, obj)
  vm.$http
    .delete(obj.url)
    .then(response => {
      if (response && response.data) {
        if (obj.config !== null) {
          obj.success(response.data)
        } else {
          if (response.data.succeed) {
            // 成功
            obj.success(response.data)
          } else {
            vm.$message.error(response.data.errorMessage)
            // 失败
            if (obj.errorInfo) {
              obj.error(response.data)
            }
          }
        }
      } else {
        console.log('response响应为空！')
      }
      obj.complete(response.data)
    })
    .catch(error => {
      // 其他错误信息待规整
      if (obj.commonComplete) {
        obj.complete()
      }
      throw error
    })
}

// get
util.get = function(vm, obj) {
  obj = responseObj(vm, obj)
  vm.$http
    .get(obj.url)
    .then(response => {
      if (response && response.data) {
        if (obj.config !== null || obj.isImg) {
          obj.success(response.data)
        } else {
          if (response.data.succeed) {
            // 成功
            obj.success(response.data)
          } else {
            // 失败
            if (obj.errorInfo) {
              obj.error(response.data)
            } else {
              vm.$message.error(response.data.errorMessage)
            }
          }
        }
      } else {
        console.log('response响应为空！')
      }
      obj.complete(response.data)
    })
    .catch(error => {
      // 其他错误信息待规整
      if (obj.commonComplete) {
        obj.complete()
      }
      throw error
    })
}

const responseObj = function(vm, obj) {
  return Object.assign(
    {
      commonComplete: true, // error与catch都使用complete
      errorInfo: true, // 显示错误信息
      url: '',
      async: false,
      params: {},
      config: null,
      isImg: false,
      success: function() {},
      error: function(response) {
        if (
          !obj.isImg &&
          response.code !== vm.base.responseCode.PARAMSERR &&
          response.message
        ) {
          vm.$message({
            type: 'error',
            message: response.message,
            offset: 100
          })
        }
      },
      complete: function() {}
    },
    obj
  )
}
// 退出
util.logOut = function(vm) {
  util.put(vm, {
    url: vm.otherUrl + 'oauth/logout',
    params: {},
    config: {},
    success: () => {
      logOut(vm)
    }
  })
}

// 深度拷贝函数
util.deepCopy = function(data) {
  const t = util.type(data)
  let o, i, ni
  if (t === 'array') {
    o = []
  } else if (t === 'object') {
    o = {}
  } else {
    return data
  }
  if (t === 'array') {
    for (i = 0, ni = data.length; i < ni; i++) {
      o.push(this.deepCopy(data[i]))
    }
    return o
  } else if (t === 'object') {
    for (i in data) {
      o[i] = this.deepCopy(data[i])
    }
    return o
  }
}
util.type = function(obj) {
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  return map[toString.call(obj)]
}
/**
 * 判断是否在某个时间段
 * @param {*} timeList 时间段
 * @param {*} time 时间
 * @returns
 */
util.atSomePointInTime = function(time, timeList = []) {
  let result = false
  const compareTime = parseInt(time.replace(':', ''))
  timeList.forEach(item => {
    if (!result) {
      const times = item.split('-')
      const startTime = parseInt(times[0].replace(':', ''))
      const endTime = parseInt(times[1].replace(':', ''))
      if (endTime < startTime) {
        if (compareTime >= startTime || compareTime < endTime) {
          result = true
        }
      } else {
        if (compareTime >= startTime && compareTime < endTime) {
          result = true
        }
      }
    }
  })
  return result
}

// 时间格式转换
util.dateFormat = (dateTime, formatType) => {
  // 输入dateTime： 时间戳,可以是字符串
  // 输出： 格式化时间，依赖Date转化
  var v = ''
  if (dateTime) {
    if (isNaN(Number(dateTime))) {
      return ''
    }
    dateTime = Number(dateTime)

    var date = new Date(dateTime)
    // var format = 'yyyy-MM-dd hh:mm:ss';
    var format = formatType
    if (typeof formatType === 'undefined') {
      format = 'yyyy-MM-dd hh:mm:ss'
    }
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    var hour = date.getHours()
    var minute = date.getMinutes()
    var second = date.getSeconds()
    // var weekDay = date.getDay();
    var ms = date.getMilliseconds()
    var weekDayString = ''

    v = format
    // Year
    v = v.replace(/yyyy/g, year)
    v = v.replace(/YYYY/g, year)
    v = v.replace(/yy/g, (year + '').substring(2, 4))
    v = v.replace(/YY/g, (year + '').substring(2, 4))

    // Month
    var monthStr = '0' + month
    v = v.replace(/MM/g, monthStr.substring(monthStr.length - 2))

    // Day
    var dayStr = '0' + day
    v = v.replace(/dd/g, dayStr.substring(dayStr.length - 2))

    // hour
    var hourStr = '0' + hour
    v = v.replace(/HH/g, hourStr.substring(hourStr.length - 2))
    v = v.replace(/hh/g, hourStr.substring(hourStr.length - 2))

    // minute
    var minuteStr = '0' + minute
    v = v.replace(/mm/g, minuteStr.substring(minuteStr.length - 2))

    // Millisecond
    v = v.replace(/sss/g, ms)
    v = v.replace(/SSS/g, ms)

    // second
    var secondStr = '0' + second
    v = v.replace(/ss/g, secondStr.substring(secondStr.length - 2))
    v = v.replace(/SS/g, secondStr.substring(secondStr.length - 2))

    // weekDay
    v = v.replace(/E/g, weekDayString)
  }

  return v
}
/**
 * 将日期字符串 转换为日期
 * @param {*} dateStr
 * @returns
 */
util.formatDate = (dateStr) => {
  return dateStr ? new Date(dateStr.replace(/-/g, '/')) : ''
}

util.isEmpty = (obj) => {
  if (typeof obj === 'undefined' || obj === null || obj === '' || JSON.stringify(obj) === '[]') {
    return true
  } else {
    return false
  }
}

util.preProcessData = (formData) => {
  /* 删除空值 */
  Object.keys(formData).forEach(item => {
    if (util.isEmpty(formData[item])) {
      delete formData[item]
    }
  })
  return formData
}

export default util
