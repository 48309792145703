const BASE_EDIT_FORM = {
  userName: '',
  userPassword: '',
  userCode: '',
  roleIds: [],
  shopIds: [],
  userAddr: '',
  userGender: '1',
  userEmail: '',
  userPhone: '',
  userId: '',
  userType: [],
  remark: ''
}
export { BASE_EDIT_FORM }
