<template>
  <CommonPage :show-page="false" :loading="loading" class="user-manager-screen-box" @query="search" @reset="reset">
    <template slot="filters">
      <el-form ref="defaultForm" :model="filters" :rules="rules" inline>
        <el-form-item label="用户名：" prop="userName">
          <el-input v-model="filters.userName" placeholder="请输入用户名"/>
        </el-form-item>
        <el-form-item label="用户类型：" prop="userType">
          <el-select v-model="filters.userType" placeholder="请选择用户类型">
            <el-option v-for="(item, index) in userTypeList" :key="index" :label="item.description" :value="item.code"> {{ item.description }} </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属角色：" prop="roleId">
          <el-select v-model="filters.roleId" placeholder="请选择角色">
            <el-option v-for="(item, index) in roleList" :key="index" :label="item.roleName" :value="item.roleId"> {{ item.roleName }} </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属经销商：" prop="shopId">
          <el-select v-model="filters.shopId" placeholder="请选择经销商">
            <el-option v-for="(item, index) in shopList" :key="index" :label="item.shopName" :value="item.shopId"> {{ item.shopName }} </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <template slot="btnOptions">
      <div>
        <el-button type="primary" @click="editUser(1)">添加用户</el-button>
        <EditUser ref="editUser" @refresh="queryPage(templateParams)"/>
      </div>
    </template>
    <template slot="dataTable">
      <CommonTable
        :header-list="headerList"
        :table-data="tableData"
        :loading="loading"
        :pager="pager"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
import EditUser from './edit-user.vue'

// 格式化请求参数
const formatParams = (params) => {
  const result = {}
  for (const key in params) {
    if (params[key] || params[key] === 0) {
      result[key] = params[key]
    }
  }
  return result
}
export default {
  components: { CommonPage, CommonTable, EditUser },
  data() {
    return {
      // table头部
      headerList: [
        {
          label: '登录名',
          param: 'userCode'
        },
        {
          label: '用户名',
          param: 'userName'
        },
        {
          label: '用户类型',
          param: 'userType',
          render: (h, data) => {
            const userType = data.row.userType ? data.row.userType.split(',') : []
            const result = userType.map(item => {
              return this.userTypeMap[item]
            })
            return h('span', {}, result.join(','))
          }
        },
        {
          label: '联系手机',
          param: 'userPhone'
        },
        {
          label: '账号状态',
          render: (h, data) => {
            return h('span', {}, data.row.status === 0 ? '停用' : '启用')
          }
        },
        {
          label: '操作',
          width: '200px',
          render: (h, data) => {
            const result = []
            const edit = h('span', {
              on: {
                click: () => {
                  this.editUser(2, data.row)
                }
              },
              class: 'table-text-btn'
            }, [
              h('em', { class: 'el-icon-edit' }),
              '编辑'
            ])

            const reset = h('span', {
              on: {
                click: () => {
                  this.resetPwd(data.row)
                }
              },
              class: 'table-text-btn'
            }, [
              h('em', { class: 'el-icon-refresh-left' }),
              '重置密码'
            ])
            if (data.row.userCode !== 'admin' || localStorage.getItem('userName') === 'admin') {
              result.push(edit)
              result.push(reset)
            }
            return h('div', {}, result)
          }
        }
      ],
      tableData: [],
      loading: false,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filters: {
        userName: '',
        shopId: '',
        roleId: '',
        userType: ''
      },
      rules: {},
      templateParams: {} // 临时请求参数
    }
  },
  computed: {
    roleList() {
      return this.$store.state.common.roleList || []
    },
    shopList() {
      return this.$store.state.common.shopList || []
    },
    userTypeList() {
      return this.$store.state.common.constData.userTypes || []
    },
    userTypeMap() {
      const result = {}
      this.userTypeList.forEach(item => {
        result[item.code] = item.description
      })
      return result
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    queryPage(filters) {
      this.loading = true
      let params = Object.assign({}, filters, {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        pageStart: 1
      })
      params = formatParams(params)
      this.$util.post(this, {
        url: this.baseUrl + '/user/find/pageable',
        params,
        success: (res) => {
          this.templateParams = params
          this.pager.total = res.data.totalElements || 0
          this.tableData = res.data.content || []
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    search() {
      this.pager.currentPage = 1
      // const params = {
      //   shopId: localStorage.getItem('shopId')
      // }
      this.queryPage(this.filters)
    },
    reset() {
      this.$refs.defaultForm.resetFields()
      this.pager.currentPage = 1
      this.search()
    },
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage(this.templateParams)
    },
    handleSizeChange(pageSize) {
      this.pager.pageSize = pageSize
      this.queryPage(this.templateParams)
    },
    editUser(type, data = {}) {
      this.$refs.editUser.show(data, type)
    },
    resetPwd(item) {
      this.$confirm(`是否确认重置用户-${item.userCode}密码`, '密码重置提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$util.put(this, {
          url: this.baseUrl + `/user/${item.userId}/resetPassword`,
          config: null,
          success: () => {
            this.$message.success('密码重置成功')
          }
        })
      }).catch(() => {
        // 取消的提示
      })
    }
  }
}
</script>

<style lang="less">
  .user-manager-screen-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding:0 20/192rem;
  }
</style>
