<template>
  <CommonPage :show-page="false" class="org-manager-box" @query="search" @reset="reset">
    <template slot="filters">
      <el-form ref="defaultForm" :model="filters" :rules="rules" inline label-width="90px">
        <el-form-item label="车牌号：" prop="carPlateNumber">
          <el-input v-model="filters.carPlateNumber" placeholder="请输入车牌号"/>
        </el-form-item>
        <el-form-item label="日期：" prop="datetimerange">
          <el-date-picker
            v-model="filters.datetimerange"
            type="datetimerange"
            clearable
            start-placeholder="开始日期"
            end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item label="品牌：" prop="brand">
          <!-- <el-select v-model="filters.brand" clearable placeholder="请选择品牌">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
          <el-input v-model="filters.brand" />
        </el-form-item>
        <el-form-item label="车系：" prop="serial">
          <!-- <el-select v-model="filters.serial" clearable placeholder="请选择车系">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
          <el-input v-model="filters.serial" />
        </el-form-item>
        <el-form-item label="车型：" prop="type">
          <!-- <el-select v-model="filters.type" clearable placeholder="请选择车型">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
          <el-input v-model="filters.type" />
        </el-form-item>
        <el-form-item label="颜色：" prop="color">
          <el-select v-model="filters.color" clearable placeholder="请选择颜色">
            <el-option
              v-for="item in colors"
              :key="item"
              :label="item"
              :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="在场状态：" prop="status">
          <el-select v-model="filters.status" clearable placeholder="请选择在场状态">
            <el-option
              v-for="item in carStatuses"
              v-if="item.description !== '无状态'"
              :key="item.code"
              :label="item.description"
              :value="item.code" />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <template slot="btnOptions">
      <div>
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="edit(1, {})">手动入场</el-button>
        <!-- <el-button style="float: right" type="primary" size="medium" icon="el-icon-plus" @click="exportExcel">导出</el-button> -->

        <Edit ref="edit" @refresh="queryPage(templateFilters)"/>
        <reception ref="reception" @refresh="queryPage(templateFilters)" />
        <electronicRelease ref="electronicRelease" @refresh="queryPage(templateFilters)" />
        <reviewVehicle ref="reviewVehicle" />
      </div>
    </template>
    <template slot="dataTable">
      <CommonTable
        :header-list="headerList"
        :table-data="tableData"
        :loading="loading"
        :pager="pager"
        show-selection
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
import reviewVehicle from './vehicle-info.vue'
import Edit from './edit.vue'
import { mapActions } from 'vuex'
import reception from './reception.vue'
import electronicRelease from './electronic-release'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
  components: { CommonPage, CommonTable, Edit, reception, electronicRelease, reviewVehicle },
  data() {
    const that = this
    return {
      headerList: [
        { label: '车牌号/VIN',
          render: (h, data) => {
            return h('span', {}, data.row.carPlateNumber ? data.row.carPlateNumber : data.row.carVinNumber)
          }
        },
        { label: '在场状态',
          render: (h, data) => {
            // return h('span', {}, that.carStatuses.find(item => item.code === data.row.status)['description'])
            return h('span', {}, data.row.departureTime === null ? '在场' : '离场')
          }
        },
        { label: '品牌', param: 'brand' },
        { label: '车系', param: 'serial' },
        { label: '车型', param: 'type' },
        { label: '颜色', param: 'color' },
        { label: '是否接待',
          render: (h, data) => {
            return h('span', {}, data.row.receptive === 'Y' ? '是' : '否')
          }
        },
        { label: '入场时间', param: 'approachTime' },
        {
          label: '操作',
          width: '340px',
          render: (h, data) => {
            const buttons = []
            buttons.push(
              h('span', {
                on: {
                  click: () => {
                    this.edit(2, data.row)
                  }
                },
                class: 'table-text-btn'
              }, [
                h('em', { class: 'el-icon-edit' }),
                '编辑'
              ])
            )
            // 有进场信息才能接待
            if (data.row.approachId) {
              buttons.push(
                h('span', {
                  on: {
                    click: () => {
                      this.reviewVehicle(1, data.row)
                    }
                  },
                  class: 'table-text-btn'
                }, [
                  h('em', { class: 'el-icon-search' }),
                  '查看'
                ])
              )
              if (!data.row.departureId) {
                const text = data.row.receptive && data.row.receptive === 'N' ? '接待' : data.row.receptive && data.row.receptive === 'Y' ? '更新接待' : ''
                buttons.push(
                  h('span', {
                    on: {
                      click: () => {
                        this.reception(1, data.row)
                      }
                    },
                    class: 'table-text-btn'
                  }, [
                    h('em', { class: 'el-icon-user-solid' }),
                    text
                  ])
                )
              }
            }
            if (data.row.receptive === 'Y' && !data.row.departureId) {
              buttons.push(
                h('span', {
                  on: {
                    click: () => {
                      this.electronicRelease(!data.row.auditId ? 1 : 2, data.row)
                    }
                  },
                  class: 'table-text-btn'
                }, [
                  h('em', { class: 'el-icon-tickets' }),
                  !data.row.auditId ? '发起放行' : '放行审批'
                ])
              )
              buttons.push(
                h('span', {
                  on: {
                    click: () => {
                      this.reception(2, data.row)
                    }
                  },
                  class: 'table-text-btn'
                }, [
                  h('em', { class: 'el-icon-document' }),
                  '补录离场信息'
                ])
              )
            }
            return h('div', {}, buttons)
          }
        }
      ],
      tableData: [],
      loading: false,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filters: {
        carPlateNumber: '',
        datetimerange: [],
        brand: '',
        serial: '',
        type: '',
        color: '',
        status: ''
      },
      colors: that.$store.state.common.colors,
      rules: {},
      templateFilters: {}
    }
  },
  computed: {
    carStatuses() {
      return this.$store.state.common.constData.carStatuses || []
    }
  },
  created() {
    this.search()
    this.$bus.$on('gobalFresh', () => {
      this.reset()
    })
  },
  mounted() {},
  methods: {
    ...mapActions(['refreshOrg']),
    queryPage(requestParams) {
      this.loading = true
      const customParams = {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        sortField: 'approachTime',
        pageStart: 1,
        // dateFrom: this.filters.datetimerange && this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[0]) : '',
        // dateTo: this.filters.datetimerange && this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[1], 'yyyy-MM-dd 23:59:59') : '',
        dateFrom: this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[0], 'yyyy-MM-dd hh:mm:ss') : '',
        dateTo: this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[1], 'yyyy-MM-dd hh:mm:ss') : '',
        shopId: Number(localStorage.getItem('shopId')),
        carSource: 'EXTERNAL'
        // carCategory: 'EXTERNAL',
      }
      // if (requestParams.status === '') {
      //   this.$set(customParams, 'status', 0)
      // }
      const params = this.$util.preProcessData(Object.assign({}, requestParams, customParams))
      const url = this.baseUrl + '/car/pageable'
      this.$util['post'](this, {
        url,
        params,
        config: {},
        success: (res) => {
          this.templateFilters = this.$util.deepCopy(requestParams)
          this.pager.total = res.data.totalElements || 0
          this.tableData = res.data.content || []
          // if (requestParams.status === 1) {
          //   res.data.content.filter(content => content.departureTime !== null)
          //   this.tableData = res.data.content || []
          // } else if (requestParams.status === 2) {
          //   res.data.content.filter(content => content.departureTime === null)
          //   this.tableData = res.data.content || []
          // } else {
          //   this.tableData = res.data.content || []
          // }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    search() {
      this.pager.currentPage = 1
      this.queryPage(this.filters)
    },
    reset() {
      this.$refs.defaultForm.resetFields()
      this.pager.currentPage = 1
      this.search()
    },
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage(this.templateFilters)
    },
    handleSizeChange(pageSize) {
      this.pager.pageSize = pageSize
      this.queryPage(this.templateFilters)
    },
    // 接待
    reception(type, datas = {}) {
      this.$refs.reception.show(datas, type)
    },
    // 电子放行单
    electronicRelease(type, datas = {}) {
      console.log(type)
      this.$refs.electronicRelease.show(datas, type)
    },
    // 查看车辆详情
    reviewVehicle(type, datas = {}) {
      this.$refs.reviewVehicle.show(datas, type)
    },
    edit(type, datas = {}) {
      this.$refs.edit.show(datas, type)
    },
    deleteOrg(item) {
      this.$confirm(`是否确认删除组织-${item.organizationName}`, '组织删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$util.delete(this, {
          url: this.baseUrl + `/organization/${item.organizationId}/delete`,
          config: null,
          success: () => {
            this.$message.success('组织删除成功')
            this.refreshOrg(this)
            this.queryPage(this.templateParams)
          }
        })
      }).catch(() => {
        // 取消的提示
      })
    },
    // 下载全部表格
    exportExcel() {
      import('@/assets/excel/Export2Excel.js').then(excel => {
        const tHeader = ['姓名', '年龄', '...'] // 导出的excel表头名信息
        const filterVal = ['name', 'age', '...'] // 导出的excel表头字段名，需要导出表格字段名
        const list = JSON.parse(JSON.stringify(this.tableData))
        const data = this.formatJson(filterVal, list)
        const autoWidth = true
        excel.export_json_to_excel({
          header: tHeader, // 表头
          data, // 数据
          filename: '实时数据', // 名称
          autoWidth: autoWidth // 宽度自适应
        })
      })
    },
    // 格式转换,不需要改动
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    }
    // exportExcel() {
    //   console.log(XLSX)
    //   var wb = XLSX.utils.table_to_book(document.querySelector('#out-table'))
    //   var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
    //   try {
    //     FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'sheetjs.xlsx')
    //   } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
    //   return wbout
    // }
  }
}
</script>

<style lang="less">
  .org-manager-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 16/192rem 16/192rem 16/192rem;
  }
</style>
