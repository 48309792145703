<template>
  <CommonPage :show-page="false" class="org-manager-box" @query="search" @reset="reset">
    <template slot="filters">
      <el-form ref="defaultForm" :model="filters" :rules="rules" inline label-width="90px">
        <el-form-item label="组织名称：" prop="organizationName">
          <el-input v-model="filters.organizationName" placeholder="请输入组织名称"/>
        </el-form-item>
      </el-form>
    </template>
    <template slot="btnOptions">
      <div>
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="editOrg(1)">新增组织</el-button>
        <EditOrg ref="editOrg" @refresh="queryPage(templateFilters)"/>
      </div>
    </template>
    <template slot="dataTable">
      <CommonTable
        :header-list="headerList"
        :table-data="tableData"
        :loading="loading"
        :pager="pager"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
import EditOrg from './edit-org.vue'
import { mapActions } from 'vuex'
export default {
  components: { CommonPage, CommonTable, EditOrg },
  data() {
    return {
      headerList: [
        { label: '组织名称', param: 'organizationName' },
        { label: '联系电话', param: 'organizationPhone' },
        { label: '联系邮箱', param: 'organizationEmail' },
        { label: '更新时间', param: 'updateTime' },
        { label: '组织类型', param: 'organizationType' },
        { label: '状态', render: (h, data) => {
          return h('span', {}, data.row.status === 0 ? '停用' : '启用')
        } },
        {
          label: '操作',
          width: '200px',
          render: (h, data) => {
            return h('div', {}, [
              h('span', {
                on: {
                  click: () => {
                    this.editOrg(2, data.row)
                  }
                },
                class: 'table-text-btn'
              }, [
                h('em', { class: 'el-icon-edit' }),
                '编辑'
              ]),
              h('span', {
                on: {
                  click: () => {
                    this.deleteOrg(data.row)
                  }
                },
                class: 'table-text-btn'
              }, [
                h('em', { class: 'el-icon-delete' }),
                '删除'
              ])
            ])
          }
        }
      ],
      tableData: [],
      loading: false,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filters: {
        organizationName: ''
      },
      rules: {},
      templateFilters: {}
    }
  },
  created() {
    this.search()
  },
  methods: {
    ...mapActions(['refreshOrg']),
    queryPage(requestParams) {
      this.loading = true
      const params = Object.assign({}, requestParams, {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        pageStart: 1
      })
      this.$util.post(this, {
        url: this.baseUrl + '/organization/find/pageable',
        params,
        config: {},
        success: (res) => {
          this.templateFilters = this.$util.deepCopy(requestParams)
          this.pager.total = res.data.totalElements || 0
          this.tableData = res.data.content || []
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    search() {
      this.pager.currentPage = 1
      this.queryPage(this.filters)
    },
    reset() {
      this.$refs.defaultForm.resetFields()
      this.pager.currentPage = 1
      this.search()
    },
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage(this.templateFilters)
    },
    handleSizeChange(pageSize) {
      this.pager.pageSize = pageSize
      this.queryPage(this.templateFilters)
    },
    editOrg(type, datas = {}) {
      this.$refs.editOrg.show(datas, type)
    },
    deleteOrg(item) {
      this.$confirm(`是否确认删除组织-${item.organizationName}`, '组织删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$util.delete(this, {
          url: this.baseUrl + `/organization/${item.organizationId}/delete`,
          config: null,
          success: () => {
            this.$message.success('组织删除成功')
            this.refreshOrg(this)
            this.queryPage(this.templateParams)
          }
        })
      }).catch(() => {
        // 取消的提示
      })
    }
  }
}
</script>

<style lang="less">
  .org-manager-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 16/192rem 16/192rem 16/192rem;
  }
</style>
