<template>
  <CommonPage :show-page="false" :loading="loading" class="device-smart-screen-box" @query="search" @reset="reset">
    <template slot="filters">
      <el-form ref="defaultForm" :model="filters" :rules="rules" inline>
        <el-form-item label="设备名称：" prop="screenName">
          <el-input v-model="filters.screenName" placeholder="请输入设备名称"/>
        </el-form-item>
        <el-form-item label="设备编码：" prop="screenCode">
          <el-input v-model="filters.screenCode" placeholder="请输入投屏编码"/>
        </el-form-item>
        <el-form-item label="设备IP：" prop="screenIp">
          <el-input v-model="filters.screenIp" placeholder="请输入设备IP"/>
        </el-form-item>
      </el-form>
    </template>
    <template slot="btnOptions">
      <div class="device-statistics">
        <div class="btn-left">
          <el-button v-if="isShowBotton('2-1-1')" type="primary" size="medium" icon="el-icon-plus" @click="addDevice">添加设备</el-button>
        </div>
        <div class="btn-right">
          <span class="device-statistics-title">设备汇总</span>
          <span class="device-total" title="总设备数"><em class="el-icon-monitor"/> {{ deviceTotal.online + deviceTotal.offline }}</span>
          <span class="device-online" title="在线设备"><em class="el-icon-monitor"/> {{ deviceTotal.online }}</span>
          <span class="device-offline" title="离线设备"><em class="el-icon-monitor"/>  {{ deviceTotal.offline }}</span>
        </div>
      </div>
    </template>
    <template slot="dataTable">
      <CommonTable
        :header-list="headerList"
        :table-data="tableData"
        :loading="loading"
        :pager="pager"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"/>
      <EditDevice ref="editDevice" @refresh="queryPage(templateParams)"/>
      <AddDevice ref="addDevice" @refresh="queryPage(templateParams)"/>
      <DeviceLog ref="deviceLog"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
import EditDevice from './edit-device.vue'
import AddDevice from './add-device-new.vue'
import DeviceLog from './device-log.vue'
import util from '@/utils/util.js'
export default {
  components: { CommonPage, CommonTable, EditDevice, AddDevice, DeviceLog },
  data() {
    return {
      // table头部
      headerList: [
        {
          label: '设备名称',
          param: 'screenName'
        },
        {
          label: '设备ID',
          param: 'screenCode'
        },
        {
          label: '设备IP',
          param: 'screenIp'
        },
        {
          label: '使用情况',
          render: (h, data) => {
            return h('span', {}, data.row.flag === 'Y' ? '投屏中' : '未投屏')
          }
        },
        {
          label: '当前投屏码',
          param: 'projectionCode'
        },
        {
          label: '当前版本',
          param: 'terminalVersion'
        },
        {
          label: '监控状态',
          param: 'status',
          render: (h, data) => {
            const className = data.row.online === 'Y' ? 'status-normal' : 'status-offline'
            return h('div', {
              style: 'display: flex;justify-content: center;'
            }, [
              h('div', {
                class: 'device-status-mark ' + className
              })
            ])
          }
        },
        {
          label: '操作',
          width: '200px',
          render: (h, data) => {
            const res = []
            if (util.isShowBotton('2-1-2', this)) {
              res.push(h('span', {
                on: {
                  click: () => {
                    this.editDevice(data.row)
                  }
                },
                class: 'table-text-btn'
              }, [
                h('em', { class: 'el-icon-edit' }),
                '编辑'
              ]))
            }

            if (util.isShowBotton('2-1-3', this)) {
              res.push(h('span', {
                on: {
                  click: () => {
                    this.deleteDevice(data.row)
                  }
                },
                class: 'table-text-btn'
              }, [
                h('em', { class: 'el-icon-delete' }),
                '删除'
              ]))
            }

            if (util.isShowBotton('2-1-4', this)) {
              res.push(h('span', {
                on: {
                  click: () => {
                    this.showLog(data.row)
                  }
                },
                class: 'table-text-btn'
              }, [
                h('em', { class: 'el-icon-document' }),
                '日志'
              ]))
            }

            return h('div', {}, res)
          }
        }
      ],
      tableData: [],
      loading: false,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filters: {
        screenName: '',
        screenCode: '',
        screenIp: ''
      },
      rules: {},
      deviceTotal: {
        online: 0,
        offline: 0
      },
      templateParams: {}, // 临时请求参数
      timerTimeout: null
    }
  },
  created() {
    if (this.deviceOption.autoRefreshDevice) {
      this.autoRefresh()
    }
  },
  mounted() {
    this.$bus.$on('gobalFresh', () => {
      this.reset()
    })
    this.search()
  },
  beforeDestroy() {
    clearTimeout(this.timerTimeout)
    this.timerTimeout = null
  },
  methods: {
    isShowBotton(key) {
      return util.isShowBotton(key, this)
    },
    autoRefresh() {
      this.timerTimeout = setTimeout(() => {
        if (localStorage.getItem('shopId')) {
          this.queryPage(this.templateParams)
        }
        this.autoRefresh()
      }, this.deviceOption.refreshFrequency)
    },
    queryPage(filters) {
      this.loading = true
      const params = Object.assign({}, {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        pageStart: 1
      }, filters)
      this.$util.post(this, {
        url: this.baseUrl + '/screen/bo/find/pageable',
        params,
        config: {},
        success: (res) => {
          this.templateParams = params
          this.pager.total = res.data.totalElements || 0
          this.tableData = res.data.content || []
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    search() {
      if (!localStorage.getItem('shopId')) {
        this.$message.warning('请选择门店后进行操作')
        return
      }
      this.pager.currentPage = 1
      const params = {
        screenName: this.filters.screenName,
        screenCode: this.filters.screenCode,
        screenIp: this.filters.screenIp,
        shopId: localStorage.getItem('shopId')
      }
      this.queryPage(params)
      this.searchTotal()
    },
    searchTotal() {
      this.$util.post(this, {
        url: this.baseUrl + '/screen/bo/find',
        params: {
          shopId: localStorage.getItem('shopId')
        },
        config: {},
        success: (res) => {
          this.deviceTotal.online = 0
          this.deviceTotal.offline = 0;
          (res.data || []).forEach(item => {
            if (item.online === 'N') {
              this.deviceTotal.offline++
            } else {
              this.deviceTotal.online++
            }
          })
        }
      })
    },
    reset() {
      if (this.$refs['defaultForm']) {
        this.$refs.defaultForm.resetFields()
      }
      this.pager.currentPage = 1
      this.search()
    },
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage(this.templateParams)
    },
    handleSizeChange(pageSize) {
      this.pager.pageSize = pageSize
      this.queryPage(this.templateParams)
    },
    addDevice() {
      if (!localStorage.getItem('shopId')) {
        this.$message.warning('请选择门店后进行操作')
        return
      }
      this.$refs.addDevice.show()
    },
    editDevice(item) {
      if (!localStorage.getItem('shopId')) {
        this.$message.warning('请选择门店后进行操作')
        return
      }
      this.$refs.editDevice.show(item, 2)
    },
    deleteDevice(item) {
      if (!localStorage.getItem('shopId')) {
        this.$message.warning('请选择门店后进行操作')
        return
      }
      this.$confirm(`是否确认删除投屏设备-${item.screenName || item.screenCode}`, '设备删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$util.delete(this, {
        //   url: this.baseUrl + `/screen/bo/${item.screenId}/delete`,
        //   config: null,
        //   success: () => {
        //     this.$message.success('投屏设备删除成功')
        //     this.queryPage(this.templateParams)
        //   }
        // })
        this.$util.put(this, {
          url: this.baseUrl + `/screen/bo/${item.screenId}/relieve`,
          params: {},
          success: () => {
            this.$message.success('投屏设备删除成功')
            this.queryPage(this.templateParams)
          }
        })
      }).catch(() => {
        // 几点取消的提示
      })
    },
    showLog(item) {
      this.$refs.deviceLog.show(item)
    },
    refreshCode() {
      this.loading = true
      this.$util.get(this, {
        url: this.baseUrl + '/screen/bo/refresh/projectionCode',
        params: {},
        config: null,
        errorInfo: false,
        success: (res) => {
          res.succeed && this.queryPage(this.templateParams)
        },
        complete: () => {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="less">
  .device-smart-screen-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding:0 20/192rem;
    .device-statistics {
      width: 100%;
      display: flex;
      .btn-left {
        flex: 1;
      }
      .btn-right {
        height: 30/192rem;
        line-height: 30/192rem;
        span {
          margin-left: 30/192rem;
          font-weight: bold;
          &.device-total {
            color: #409EFF;
          }
          &.device-online {
            color: #67C23A;
          }
          &.device-offline {
            color: #909090;
          }
        }
      }
    }
    .device-status-mark {
      width: 10/192rem;
      height: 10/192rem;
      border-radius: 5/192rem;
      &.status-normal {
        background-color: #67C23A;
      }
      &.status-error {
        background-color: #F56C6C;
      }
      &.status-offline {
        background-color: #909090;
      }
    }
  }
</style>
