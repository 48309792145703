const BASE_EDIT_FORM = {
  plateNumber: '',
  customerName: '',
  customerPhone: '',
  customerGender: '',
  principal: '',
  milage: '',
  businessType: '',
  remark: ''
}
export { BASE_EDIT_FORM }
