<template>
  <div :class="!isCollapse ? 'collapse' : ''" class="left-menu-box">
    <el-menu
      :collapse="isCollapse"
      default-active="1"
      class="el-menu-vertical-demo"
      unique-opened
    >
      <template v-for="item in roleMenuList">
        <el-submenu
          v-if="item.children"
          :key="item.resourceId"
          :index="item.resourceId"
        >
          <template slot="title">
            <em :class="item.icon"/>
            <span slot="title">{{ item.name }}</span>
          </template>
          <template v-for="child in item.children">
            <el-menu-item
              v-if="!child.children"
              :key="child.resourceId"
              :index="child.resourceId"
              @click="toPage(child)"
            >{{ child.name }}</el-menu-item
            >

            <el-submenu
              v-else
              :key="child.resourceId"
              :index="child.resourceId"
            >
              <span slot="title">{{ child.name }}</span>
              <template v-for="lastChild in child.children">
                <el-menu-item
                  :key="lastChild.resourceId"
                  :index="lastChild.resourceId"
                  @click="toPage(lastChild)"
                >{{ lastChild.name }}</el-menu-item
                >
              </template>
            </el-submenu>
          </template>
        </el-submenu>
        <el-menu-item
          v-else
          :key="item.resourceId"
          :index="item.resourceId"
          @click="toPage(item)"
        >
          <em :class="item.icon"/>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </template>
    </el-menu>
    <div class="mennu-collapse-btn" @click="$emit('collapse')">
      <em
        :class="
          isCollapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'
        "
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      meunItemList: []
    }
  },
  computed: {
    ...mapGetters(['menuList']),
    roleMenuList() {
      const menuList = this.$util.deepCopy(this.menuList)
      const result = []
      if (localStorage.getItem('userName') !== 'admin') {
        this.rebuildMenus(menuList, result)
        return result
      }
      return menuList
    },
    rolePage() {
      const roles = localStorage.getItem('roles')
      const roleArr = roles ? roles.split(',') : []
      const result = []
      roleArr.forEach(item => {
        const permission = this.rolePermission[item] || this.rolePermission['DEFAULT']
        permission.forEach(child => {
          const menus = child.split('-')
          let currentMenu = ''
          menus.forEach(menu => {
            currentMenu = currentMenu + menu
            result.indexOf(currentMenu) < 0 && result.push(currentMenu)
            currentMenu += '-'
          })
        })
      })
      return result
    }
  },
  mounted() {
    console.log(this.meunItemList)
    this.meunItemList.some(item => !item.children && this.$router.push({ name: item.rescourceCode, query: { v: new Date().getTime() }}))
  },
  methods: {
    treeToArr(data, pid = null, res = []) {
      data.forEach(item => {
        res.push({ pid: pid, ...item })
        if (item.children && item.children.length !== 0) {
          this.treeToArr(item.children, item.resourceId, res)
        }
      })
      return res
    },
    arrToTree(arr, pid = null) {
      const res = []
      arr.forEach(item => {
        if (item.pid === pid) {
          // 这样每次都需要遍历整个数组，因此时间复杂度为 n*n
          // const children = arrToTree(arr, item.resourceId)
          // 往下递归时，每次数组的大小都在减小，每次都筛选掉父代元素，最终的时间复杂度为 n*logn
          const children = this.arrToTree(arr.filter(v => v.pid !== pid), item.resourceId)
          if (children.length) {
            res.push({ ...item, children })
          } else {
            res.push({ ...item })
          }
        }
      })
      return res
    },
    toPage(item) {
      this.$router.push({ name: item.rescourceCode, query: { v: new Date().getTime() }})
    },
    rebuildMenus(sourceData, result) {
      const sourceDataList = this.treeToArr(sourceData)
      console.log(sourceDataList)
      const arr = [...sourceDataList].filter(x => [...this.rolePage].some(y => y === x.resourceId))
      this.meunItemList = arr
      this.arrToTree(arr).forEach(item => {
        result.push(item)
      })
      // sourceData.forEach((item) => {
      //   if (this.rolePage.length === 0 || this.rolePage.indexOf(item.resourceId) >= 0) {
      //     const current = this.$util.deepCopy(item)
      //     result.push(current)
      //     if (item.children) {
      //       current.children = []
      //       item.children.forEach((child) => {
      //         if (this.rolePage.length === 0 || this.rolePage.indexOf(child.resourceId) >= 0) {
      //           current.children.push(child)
      //           this.meunItemList.push(this.$util.deepCopy(child))
      //         }
      //       })
      //     }
      //   }
      // })
      // console.log(result)
    }
  }
}
</script>

<style lang="less">
.left-menu-box {
	width: 65px;
	// height: 100%;
	background-color: @menu-bg;
	position: relative;
	max-width: 200px;
	border-right: solid 1px #e6e6e6;
	box-sizing: border-box;
	overflow: hidden;
	&.collapse {
		width: 200px;
	}
	.mennu-collapse-btn {
		width: 100%;
		height: 30/192rem;
		position: absolute;
		bottom: 0;
		text-align: center;
		cursor: pointer;
    color: #CCCCCC;
	}
	.el-menu {
		border: none;
		background-color: @menu-bg;
		.el-submenu {
			&__title {
				color: @menu-text-color;
				&:hover {
					background-color: transparent;
					color: @menu-text-hover;
				}
			}
			.el-menu-item {
				padding: 0;
				min-width: 100/192rem;
				background-color: @menu-sub-bg;
				color: @menu-text-color;
				&.is-active {
					background-color: @menu-active-bg;
					color: @menu-text-hover;
				}
				&:hover {
					color: @menu-text-hover;
				}
			}
		}
	}
}
</style>
