<template>
  <el-dialog :title="type === 1 ? '新增经销商' : '编辑经销商'" :visible="visible" :close-on-click-modal="false" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item label="经销商名称：" prop="shopName">
        <el-input v-model="datas.shopName" placeholder="请输入经销商名称"/>
      </el-form-item>
      <el-form-item label="经销商编码：" prop="gssnCode">
        <el-input v-model="datas.gssnCode" placeholder="请输入经销商编码"/>
      </el-form-item>
      <el-form-item label="所属组织：" prop="organizationId">
        <el-select v-model="datas.organizationId" placeholder="请选择所属组织">
          <el-option v-for="(item, index) in orgList" :key="index" :label="item.organizationName" :value="item.organizationId">{{ item.organizationName }}</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="店铺区域：" prop="functionalArea">
        <el-input v-model="datas.shopArea" placeholder="请输入店铺区域"/>
      </el-form-item>
      <!-- <el-form-item label="辅助代码：" prop="gssnCode">
        <el-input v-model="datas.gssnCode" placeholder="请输入辅助代码"/>
      </el-form-item> -->
      <el-form-item label="经销商类型：" prop="shopType">
        <el-input v-model="datas.shopType" placeholder="请输入经销商类型"/>
      </el-form-item>
      <el-form-item label="联系电话：" prop="shopPhone">
        <el-input v-model="datas.shopPhone" placeholder="请输入联系电话"/>
      </el-form-item>
      <el-form-item label="联系邮箱：" prop="shopEmail">
        <el-input v-model="datas.shopEmail" placeholder="请输入联系邮箱"/>
      </el-form-item>
      <el-form-item label="地址" prop="areas">
        <el-cascader
          v-model="datas.areas"
          :options="areas"
          placeholder="请选择地址"
        />
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="datas.remark" placeholder="请输入备注"/>
      </el-form-item>
      <el-form-item label="自动放行：" prop="autoReleaseAll">
        <el-radio-group v-model="datas.autoReleaseAll">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { BASE_EDIT_FORM } from './index-config'
export default {
  data() {
    return {
      visible: false,
      type: 1,
      loading: false,
      datas: {
        shopName: '',
        dmsCode: '',
        shopId: '',
        organizationId: '',
        shopType: '',
        shopPhone: '',
        shopEmail: '',
        gssnCode: '',
        remark: '',
        autoReleaseAll: 'Y',
        areas: []
      },
      rules: {
        shopName: [{ required: true, message: '请输入经销商名称', trigger: 'blur' }],
        gssnCode: [{ required: true, message: '请输入经销商编码', trigger: 'blur' }],
        areas: [{ required: false, message: '请选择地址', trigger: 'blur', type: 'array' }],
        organizationId: [{ required: true, message: '请选择所属组织', trigger: 'change' }]
      },
      version: 0
    }
  },
  computed: {
    areas() {
      return this.$store.state.common.areas || []
    },
    orgList() {
      return this.$store.state.common.orgList || []
    }
  },
  methods: {
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const url = this.baseUrl + '/shop/' + (this.type === 1 ? 'create' : 'update')
          const customParams = {}
          console.log(this.version)
          if (this.type === 2) {
            customParams.version = this.version
          }
          console.log(customParams)
          const params = Object.assign({}, this.datas, customParams)

          if (params.areas && params.areas.length > 0) {
            params.shopAddr = params.areas.join(',')
            delete params.areas
          }

          this.$util.post(this, {
            url,
            params,
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.$message.success((this.type === 1 ? '新增' : '修改') + '经销商成功')
                this.$emit('refresh')
                this.cancel()
                this.$bus.$emit('refreshOrg')
              }
            },
            complete: () => {
              this.loading = false
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    show(data, type) {
      this.type = type
      if (type !== 1) {
        this.datas = Object.assign({}, BASE_EDIT_FORM, data)
        if (data.shopAddr) {
          this.datas.areas = data.shopAddr.split(',')
        }
        this.version = data.version
      }
      this.visible = true
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas.shopId = ''
      this.version = 0
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">

</style>
