<template>
  <el-dialog :title="type === 1 ? '接待确认' : type === 2 ? '补录离场信息' : type === 3 ? '电子放行单' : ''" :visible="visible" :close-on-click-modal="false" class="form-inline-edit" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item label="车牌号/VIN：" prop="plateNumber">
        <el-input v-model="datas.plateNumber" placeholder="请输入车牌号/VIN" disabled/>
      </el-form-item>
      <el-form-item label="客户姓名：" prop="customName">
        <el-input v-model="datas.customName" :disabled="type === 2" placeholder="请输入客户姓名"/>
      </el-form-item>
      <el-form-item label="手机号：" prop="phone">
        <el-input v-model="datas.phone" :disabled="type === 2" placeholder="请输入手机号"/>
      </el-form-item>
      <el-form-item label="性别：" prop="sex">
        <el-radio-group v-model="datas.sex">
          <el-radio label="1">男</el-radio>
          <el-radio label="0">女</el-radio>
          <el-radio label="2">保密</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="里程表信息：" prop="odometerInformation">
        <el-input v-model="datas.odometerInformation" :disabled="type === 2" placeholder="请输入里程表信息"/>
        km
      </el-form-item>
      <el-form-item v-if="type === 1" label="业务类型：" prop="businessType">
        <el-select v-model="datas.businessType" :disabled="type === 2" placeholder="请选择业务类型">
          <el-option
            v-for="item in businessTypeList"
            :key="item.code"
            :label="item.description"
            :value="item.code"/>
        </el-select>
      </el-form-item>
      <el-form-item label="业务人员：" prop="businessPeople">
        <el-input v-model="datas.businessPeople" :disabled="type === 2" placeholder="请输入业务人员"/>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="datas.remark" :autosize="{ minRows: 4}" type="textarea" placeholder="请输入备注" maxlength="100" show-word-limit/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from '@/gobal/lib/validate.js'
import { BASE_EDIT_FORM } from './index-config'
export default {
  data() {
    return {
      visible: false,
      type: 1, // 1 接待 2 补录离场信息 3电子放行单
      loading: false,
      datas: {
        plateNumber: '',
        customName: '',
        phone: '',
        sex: '',
        businessPeople: '',
        odometerInformation: '',
        businessType: '',
        remark: ''
      },
      rules: {
        plateNumber: [{ required: true, message: '请输入车牌号/VIN', trigger: 'blur' }],
        phone: [{ required: false, validator: validate.validatePhone, trigger: 'blur' }],
        businessType: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
        businessPeople: [{ required: true, message: '请输入接待人', trigger: 'blur' }]
      }
    }
  },
  computed: {
    businessTypeList() {
      return this.$store.state.common.constData.businessTypes || []
    },
    orgList() {
      return this.$store.state.common.orgList || []
    }
  },
  methods: {
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const url = this.baseUrl + '/organization/' + (this.type === 1 ? 'create' : 'update')
          const params = Object.assign({}, this.datas, customParams)
          if (this.type === 1) {
            delete params.userId
          } else {
            delete params.userPassword
            delete params.roleIds
            delete params.shopIds
          }
          this.$util['post'](this, {
            url,
            params,
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.$message.success('接待成功')
                this.$emit('refresh')
                this.cancel()
              }
            },
            complete: () => {
              this.loading = false
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    show(data, type) {
      this.datas = Object.assign({}, BASE_EDIT_FORM, data)
      this.visible = true
      this.type = type
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas = Object.assign({}, BASE_EDIT_FORM, {})
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">

</style>
