<template>
  <el-dialog :title="type === 1 ? '添加设备' : '编辑设备'" :visible="visible" :close-on-click-modal="false" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item label="设备名称：" prop="screenName">
        <el-input v-model="datas.screenName" placeholder="请输入设备名称"/>
      </el-form-item>
      <el-form-item label="设备ID：" prop="screenCode">
        <el-input v-model="datas.screenCode" disabled placeholder="请输入设备ID"/>
      </el-form-item>
      <el-form-item label="设备IP：" prop="screenIp">
        <el-input v-model="datas.screenIp" placeholder="请输入设备Ip"/>
      </el-form-item>
      <el-form-item label="设备端口：" prop="screenPort">
        <el-input v-model="datas.screenPort" placeholder="请输入设备端口"/>
      </el-form-item>
      <el-form-item label="MAC地址：" prop="screenMac">
        <el-input v-model="datas.screenMac" placeholder="请输入MAC地址" @change="changeMac"/>
      </el-form-item>
      <el-form-item v-if="type === 2" label="当前投屏码：" prop="projectionCode">
        <div class="form-full">{{ datas.projectionCode }}</div>
      </el-form-item>
      <el-form-item v-if="type === 2" label="接收端版本：" prop="terminalVersion">
        <div class="form-full">{{ datas.terminalVersion }}</div>
      </el-form-item>
      <el-form-item label="设备状态：" prop="type">
        <el-select v-model="datas.type" placeholder="请选择设备状态">
          <el-option v-for="(item, index) in deviceStatusList" :key="index" :label="item.description" :value="item.code"/>
        </el-select>
      </el-form-item>
      <el-form-item label="所属功能区：" prop="functionalArea">
        <el-select v-model="datas.functionalArea" placeholder="请选择所属功能区">
          <el-option v-for="(item, index) in functionalAreaList" :key="index" :label="item.name" :value="item.metadataId">{{ item.name }}</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="显示投屏码：" prop="showProjectionCode">
        <el-radio v-model="datas.showProjectionCode" label="Y">显示</el-radio>
        <el-radio v-model="datas.showProjectionCode" label="N">隐藏</el-radio>
      </el-form-item>
      <el-form-item label="隐藏设备：" prop="hide">
        <el-radio v-model="datas.hide" label="Y">显示</el-radio>
        <el-radio v-model="datas.hide" label="N">隐藏</el-radio>
      </el-form-item>
      <el-form-item label="设备有效期：" prop="effectTime">
        <el-date-picker v-model="datas.effectTime" type="datetimerange" placeholder="datetime"/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button type="primary" @click="saveData">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from '@/gobal/lib/validate.js'
const dataTemplate = {
  screenId: '',
  screenName: '',
  screenIp: '',
  screenPort: '',
  screenMac: '',
  screenCode: '',
  status: '',
  functionalArea: '',
  showProjectionCode: 'Y',
  hide: 'Y',
  effectTime: [],
  type: '',
  terminalVersion: '',
  projectionCode: ''
}
export default {
  data() {
    return {
      visible: false,
      loading: false,
      type: 1,
      datas: {
        screenId: '',
        screenName: '',
        screenIp: '',
        screenPort: '',
        screenMac: '',
        screenCode: '',
        status: '',
        functionalArea: '',
        showProjectionCode: 'Y',
        hide: 'Y',
        effectTime: [],
        type: '',
        terminalVersion: '',
        projectionCode: '',
        shopId: ''
      },
      rules: {
        // screenName: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        screenId: [{ required: true, message: '请输入设备ID', trigger: 'blur' }],
        screenIp: [{ required: false, validator: validate.isIp, trigger: 'blur' }],
        screenPort: [{ required: false, validator: validate.isPort, trigger: 'blur' }],
        screenMac: [{ required: false, validator: validate.isMacAddress, trigger: 'blur' }]
      }
    }
  },
  computed: {
    // 设备状态
    deviceStatusList() {
      return this.$store.state.common.constData.projectionDeviceStatus || []
    },
    // 功能展示区
    functionalAreaList() {
      const arr = this.$store.state.common.sourceData || []
      return arr.filter(item => {
        return item.subtype === 'SCREEN_FUNCTION_AREA'
      })
    }
  },
  methods: {
    show(data, type) {
      this.type = type
      if (type === 2) {
        this.$set(this, 'datas', {
          screenId: data.screenId,
          screenName: data.screenName,
          screenIp: data.screenIp,
          screenPort: data.screenPort,
          screenMac: data.screenMac,
          screenCode: data.screenCode,
          status: data.status,
          functionalArea: parseInt(data.functionalArea) || '',
          showProjectionCode: data.showProjectionCode,
          hide: data.hide,
          effectTime: [],
          type: parseInt(data.type) === 0 || parseInt(data.type) ? parseInt(data.type) : '',
          terminalVersion: data.terminalVersion,
          projectionCode: data.projectionCode,
          shopId: data.shopId
        })
        if (data.validPeriodFrom) {
          this.datas.effectTime.push(this.$util.formatDate(data.validPeriodFrom))
          this.datas.effectTime.push(this.$util.formatDate(data.validPeriodTo))
        }
      } else {
        this.$set(this, 'datas', this.$util.deepCopy(dataTemplate))
      }

      // this.datas = this.$util.deepCopy(data)
      this.visible = true
    },
    saveData() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const url = this.baseUrl + '/screen/bo/' + (this.type === 1 ? 'create' : 'update')
          const customParams = {}
          // 日期
          if (this.datas.effectTime && this.datas.effectTime[0]) {
            customParams.validPeriodFrom = this.datas.effectTime[0].getTime()
            customParams.validPeriodTo = this.datas.effectTime[1].getTime()
          }

          this.$util.post(this, {
            url,
            params: Object.assign({}, this.datas, customParams),
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.$message.success((this.type === 1 ? '新增' : '修改') + '投屏设备成功')
                this.$emit('refresh')
                this.cancel()
              }
            },
            complete: () => {
              this.loading = false
            }
          })
          this.cancel()
        } else {
          this.loading = false
        }
      })
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas.shopId = ''
      this.loading = false
      this.visible = false
    },
    changeMac() {
      this.datas.screenCode = 'UP_' + this.datas.screenMac.replaceAll(':', '')
    }
  }
}
</script>

<style lang="less" scoped>
.el-form {
  .form-full {
    width: 234px;
  }
}
</style>
