<!-- 投屏报告 -->
<template>
  <div class="screencast-report-box">
    <el-row :gutter="12" class="row-line">
      <el-col :span="24">
        <el-card shadow="hover">
          <el-form
            ref="defaultForm"
            :model="filters"
            :rules="rules"
            inline
            label-width="90px"
          >
            <el-form-item label="日期：" prop="datetimerange">
              <el-date-picker
                v-model="filters.datetimerange"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                clearable
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="getDate"
              />
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-row v-loading="isProjectionLoading" :gutter="12" class="row-line">
      <el-col :span="24">
        <el-card shadow="hover">
          <div>
            <p class="statistics-card-title" style="margin-bottom: 10px">
              经销商投屏报告
            </p>
            <div class="screencast-report-panel">
              <el-row :gutter="12">
                <el-col
                  v-for="(item, index) in screencastReportList"
                  :span="item.span"
                  :key="index"
                >
                  <p>{{ item.label }}</p>
                  <p :style="item.style">{{ screenData[item.key] || 0 }}</p>
                  <p>{{ item.unit }}</p>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row v-loading="isProjectionLoading" :gutter="12" class="row-line">
      <el-col :span="12">
        <el-card shadow="hover">
          <div style="height: 783px; display: flex; flex-direction: column">
            <p class="statistics-card-title" style="margin-bottom: 10px">
              设备列表
            </p>
            <div class="device-table">
              <el-table
                :data="
                  device.tableData.slice(
                    (currentPage - 1) * pageSize,
                    currentPage * pageSize
                  )
                "
                style="width: 100%"
              >
                <el-table-column prop="screenName" label="设备名称"/>
                <el-table-column label="当前是否在线">
                  <template slot-scope="scope">
                    {{ scope.row.online === 'Y' ? '是' : '否' }}
                  </template>
                </el-table-column>
                <el-table-column prop="times" label="投屏次数"/>
                <el-table-column prop="durationMax" label="最大投屏时长（秒）"/>
                <el-table-column prop="duration" label="投屏总时长（秒）"/>
              </el-table>
              <div style="text-align: right; margin-top: 20px">
                <el-pagination
                  :total="device.total"
                  :current-page.sync="currentPage"
                  :page-size="pageSize"
                  background
                  layout="prev, pager, next"

                />

              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-row :gutter="12" class="row-line">
          <el-col :span="24">
            <el-card shadow="hover" class="statistics-card">
              <div
                id="todayScreencastingUseNum"
                class="traffic-statistics"
              />
            </el-card>
          </el-col>
          <el-col :span="24" style="margin-top: 20px">
            <el-card shadow="hover" class="statistics-card">
              <div
                id="todayScreencastingUseTime"
                class="traffic-statistics"
              />
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row v-loading="isUserLoading" :gutter="12" class="row-line">
      <el-col :span="24">
        <el-card shadow="hover" class="statistics-card">
          <div>
            <p class="statistics-card-title" style="margin-bottom: 10px">
              员工投屏报告
            </p>
            <div
              id="todayScreencastingUseReport"
              class="traffic-statistics"
            />
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      filters: {
        datetimerange: []
      },
      rules: {},
      isProjectionLoading: false,
      screencastReportList: [
        {
          label: '投屏数量',
          value: '0',
          style: 'color:#409EFF;font-size: 32px',
          unit: '',
          key: 'screenCount',
          span: 4
        },
        {
          label: '投屏次数',
          value: '0',
          style: 'color:#606266;font-size: 32px',
          unit: '',
          key: 'totalTimes',
          span: 5
        },
        {
          label: '投屏时长',
          value: '0',
          style: 'color:#67C23A;font-size: 32px',
          unit: '分钟',
          key: 'totalDuration',
          span: 6
        },
        {
          label: '平均投屏时长',
          value: '0',
          style: 'color:#E6A23C;font-size: 32px',
          key: 'averageDuration',
          unit: '分钟',
          span: 5
        }
        // {
        //   label: '平均时长',
        //   value: '23',
        //   style: 'color:#F56C6C;font-size: 32px',
        //   unit: '分钟',
        //   span: 4
        // }
      ],
      screenData: {},
      device: {
        tableData: [],
        total: 0
      },
      pageSize: 10,
      currentPage: 1,
      // 设备列表
      tableData: [],

      // 员工投屏次数
      isUserLoading: false
    }
  },
  watch: {
    '$store.state.report.shopId'(newVal, oldVal) {
      console.log(newVal, '新的门店id')
      this.getPageData()
    }
  },
  mounted() {
    this.getPageData()
  },
  methods: {
    getPageData() {
      // let data = {
      //   startTime: '2020-01-01 15:59:10',
      //   endTime: '2023-04-23 15:59:10',
      //   shopId: 17,
      // };
      const data = {
        startTime: this.filters.datetimerange.length
          ? this.filters.datetimerange[0]
          : new Date().toLocaleString('lt-LT'),
        endTime: this.filters.datetimerange
          ? this.filters.datetimerange[1]
          : new Date().toLocaleString('lt-LT'),
        shopId: this.$store.state.report.shopId
      }
      if (this.filters.datetimerange.length === 0) {
        data['startTime'] = this.$util.dateFormat(new Date(), 'yyyy-MM-dd 00:00:00')
        data['endTime'] = this.$util.dateFormat(new Date(), 'yyyy-MM-dd 23:59:59')
      }

      // 按投屏设备/功能区统计
      this.projectionStatistics(data)
      this.usersProjectionStatistics(data)
    },

    // 时间范围改变
    getDate(date) {
      console.log(date)
      this.getPageData()
    },

    projectionStatistics(data) {
      this.isProjectionLoading = true
      const params = this.$util.preProcessData(Object.assign({}, data))
      const url = this.baseUrl + '/report/screen/projection/statistics'
      this.$util.post(this, {
        url,
        params,
        config: {},
        success: (res) => {
          console.log(res)
          if (res.succeed) {
            console.log(res.data, '1111')
            this.isProjectionLoading = false
            this.screenData = res.data
            // 秒钟转分钟
            for (const key in this.screenData) {
              if (key === 'totalDuration') {
                this.screenData['totalDuration'] = parseFloat(this.screenData['totalDuration'] / 60).toFixed(2)
              } if (key === 'averageDuration') {
                this.screenData['averageDuration'] = parseFloat(this.screenData['averageDuration'] / 60).toFixed(2)
              }
            }
            const useNumData = this.objArrtransArr(
              res.data.projectionByAreas,
              'times',
              'functionalArea'
            )
            const useTimeData = this.objArrtransArr(
              res.data.projectionByScreens,
              'times',
              'screenName'
            )
            console.log(useNumData, useTimeData)
            this.device.tableData = res.data.projectionByScreens
            this.device.total = res.data.projectionByScreens.length

            // 今日投屏次数占比
            this.todayScreencastingUseNum(useNumData)
            // 今日投屏时常占比
            this.todayScreencastingUseTime(useTimeData)
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isProjectionLoading = false
        }
      })
    },

    // 员工投屏次数统计
    usersProjectionStatistics(data) {
      this.isUserLoading = true
      const params = this.$util.preProcessData(Object.assign({}, data))
      const url = this.baseUrl + '/report/screen/user/projection/statistics'
      this.$util.post(this, {
        url,
        params,
        config: {},
        success: (res) => {
          console.log(res)
          if (res.succeed) {
            const data = this.dealLineData(res.data)
            this.todayScreencastingUseReport(data)
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isUserLoading = false
        }
      })
    },

    // 将数据处理成柱状图形式
    dealLineData(data) {
      const obj = {
        xAxis: [],
        yAxis: []
      }
      data.forEach((item) => {
        obj.xAxis.push(item.userName)
        obj.yAxis.push(item.count)
      })
      return obj
    },

    // 对象数组内部的key值转成value、name形式
    objArrtransArr(olddata, oldval, oldname) {
      const newArr = []
      olddata.forEach((item) => {
        // 定义数组内部对象形式
        const obj = {}
        obj.value = item[oldval]
        obj.name = item[oldname]
        // 将对象数据推到数组中
        newArr.push(obj)
      })
      return newArr
    },

    todayScreencastingUseNum(data) {
      const myChart = echarts.init(
        document.getElementById('todayScreencastingUseNum')
      )
      const option = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: '投屏使用次数占比'
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 40,
          top: 20,
          bottom: 20
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      }
      option && myChart.setOption(option)
    },

    todayScreencastingUseTime(data) {
      const myChart = echarts.init(
        document.getElementById('todayScreencastingUseTime')
      )
      const option = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: '投屏使用时长占比'
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 40,
          top: 20,
          bottom: 20
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      }
      option && myChart.setOption(option)
    },

    todayScreencastingUseReport(data) {
      const myChart = echarts.init(
        document.getElementById('todayScreencastingUseReport')
      )
      const option = {
        xAxis: {
          type: 'category',
          data: data.xAxis
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: data.yAxis,
            type: 'bar',
            label: {
              // 柱图头部显示值
              show: true,
              position: 'top',
              color: '#333',
              fontSize: '18px',
              formatter: (params) => {
                return params.value[params.encode.x[0]]
              }
            }
          }
        ]
      }
      option && myChart.setOption(option)
    }
  }
}
</script>

<style lang='less'>
.screencast-report-box {
  padding: 20/192rem;
  .row-line {
    margin-bottom: 20/192rem;
    .statistics-card-title {
      font-size: 23/192rem;
      font-weight: bold;
      margin-bottom: 10/192rem;
    }
    .screencast-report-panel {
      margin-top: 40/192rem;
      .el-row {
        .el-col p {
          display: flex;
          justify-content: center;
          margin-bottom: 5/192rem;
        }
      }
    }
    .statistics-card {
      overflow-y: hidden;
      position: relative;
      height: 400px;
      .traffic-statistics {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 400px;
        margin: 20/192rem;
      }
    }
    .device-table {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
