<template>
  <div class="main-box">
    <MainHeader/>
    <div class="main-box-content">
      <LeftMenu
        :is-collapse="isCollapse"
        @collapse="isCollapse = !isCollapse"
      />

      <div :class="{ 'no-collapse': !isCollapse }" class="main-content">
        <PageNav/>
        <div class="main-content-page">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/main-header'
import LeftMenu from '@/components/left-menu'
import PageNav from '@/components/page-nav'
import { mapActions } from 'vuex'
export default {
  components: {
    MainHeader,
    LeftMenu,
    PageNav
  },
  data() {
    return {
      isCollapse: true
    }
  },
  mounted() {
    this.initGlobalState(this)
  },
  methods: {
    ...mapActions(['initGlobalState'])
  }
}
</script>

<style lang="less">
@headerHeight: 50px;
@isCollapseWidth: 200px;
.main-box {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	&-content {
		width: 100%;
		height: calc(~'100% - ' @headerHeight);
		display: flex;
		.main-content {
			height: 100%;
			background-color: #f5f5f5;
			width: calc(~'100% - 65px');
			display: flex;
			flex-direction: column;
      overflow-y: scroll;
			// flex-wrap: wrap;
			&-page {
				width: 100%;
				flex: 1;
			}
			&.no-collapse {
				width: calc(~'100% - ' @isCollapseWidth);
			}
		}
	}
}
</style>
