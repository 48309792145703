<template>
  <!-- 电子放行单 -->
  <el-dialog :title="'发起放行申请'" :visible="visible" :close-on-click-modal="false" class="form-inline-edit" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item label="车牌号/VIN：" prop="carPlateNumber">
        <el-input v-model="datas.carPlateNumber" placeholder="请输入车牌号/VIN" disabled/>
      </el-form-item>
      <el-form-item label="客户姓名：" prop="customerName">
        <el-input v-model="datas.customerName" placeholder="请输入客户姓名" disabled />
      </el-form-item>
      <el-form-item label="手机号：" prop="customerPhone">
        <el-input v-model="datas.customerPhone" placeholder="请输入手机号" disabled/>
      </el-form-item>
      <el-form-item label="性别：" prop="customerGender">
        <el-radio-group v-model="datas.customerGender" disabled>
          <el-radio v-for="item in genders" :label="item.code" :key="item.code">{{ item.description }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="里程表信息：" prop="mileage">
        <el-input v-model="datas.mileage" placeholder="请输入里程表信息" disabled/>
        km
      </el-form-item>
      <el-form-item label="接待人：" prop="salesman">
        <el-input v-model="datas.salesman" placeholder="请输入业务人员" disabled/>
      </el-form-item>
      <el-form-item label="审批人：" prop="reviewer">
        <el-select v-model="datas.reviewer" :disabled="type === 2" filterable placeholder="请选择审批人">
          <el-option
            v-for="item in reviewerList"
            :key="item.userId"
            :label="item.userName"
            :value="item.userId"/>
        </el-select>
      </el-form-item>
      <el-form-item label="接待备注：" prop="receptionRemark">
        <el-input v-model="datas.receptionRemark" :autosize="{ minRows: 4}" type="textarea" placeholder="请输入接待备注" maxlength="100" show-word-limit disabled/>
      </el-form-item>
      <el-form-item label="申请备注：" prop="remark">
        <el-input v-model="datas.remark" :autosize="{ minRows: 4}" type="textarea" placeholder="请输入申请备注" maxlength="100" show-word-limit/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button v-if="type === 2" :loading="loading" type="primary" @click="overRule" >撤销</el-button>
      <el-button v-if="type === 1" :loading="loading" type="primary" @click="save" >{{ type === 1 ? '创建' : '更新' }}</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from '@/gobal/lib/validate.js'
export default {
  data() {
    return {
      visible: false,
      type: 1, // 1 创建电子放行单 2 已经创建
      receptionId: '', // 接待id
      auditId: '',
      loading: false,
      datas: {
        carPlateNumber: '',
        customerName: '',
        customerPhone: '',
        customerGender: '',
        salesman: '',
        mileage: '',
        businessType: '',
        remark: '',
        receptionRemark: ''
      },
      reviewerList: [],
      version: 0,
      rules: {
        carPlateNumber: [{ required: true, message: '请输入车牌号/VIN', trigger: 'blur' }],
        customerPhone: [{ required: false, validator: validate.validatePhone, trigger: 'blur' }],
        businessType: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
        salesman: [{ required: true, message: '请输入接待人', trigger: 'blur' }],
        reviewer: [{ required: true, message: '请选择审批人', trigger: 'change' }]
      }
    }
  },
  computed: {
    genders() {
      return this.$store.state.common.constData.genders || []
    },
    sourceData() {
      return this.$store.state.common.sourceData || []
    }
  },
  methods: {
    // 撤销电子放行单
    overRule() {
      this.$confirm(`是否确认撤回审核`, '审核提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = false
        this.$util.put(this, {
          url: this.baseUrl + `/audit/${this.auditId}/cancel`,
          params: {},
          success: res => {
            this.$message.success('撤销成功')
            this.$emit('refresh')
            this.cancel()
          },
          complete: res => {
            this.loading = false
          }
        })
      })
    },
    save() {
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          console.log(this.datas)
          const customParams = {
            shopId: Number(localStorage.getItem('shopId')),
            mileage: Number(this.datas.mileage),
            auditType: 'ELECTRONIC_RELEASE',
            version: this.version,
            approachId: this.datas.approachId
          }
          if (this.type === 2) {
            customParams.auditId = this.auditId
          }
          let url = this.baseUrl + '/audit/departureApply/'
          this.type === 1 ? url += 'create' : url += 'update'
          const params = this.$util.preProcessData(Object.assign({}, this.datas, customParams))
          const requestType = this.type === 1 ? 'post' : 'put'
          this.loading = true
          this.$util[requestType](this, {
            url,
            params,
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.$message.success(this.type === 1 ? '审批已提交' : '审批已更新')
                this.$emit('refresh')
                this.cancel()
              }
            },
            complete: () => {
              this.loading = false
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    // 根据approachId 获取接待信息
    getReceptiveInfo(approachId, type) {
      const params = {
        shopId: Number(localStorage.getItem('shopId')),
        approachId: approachId
      }
      let url = ''
      if (type === 1) {
        url = this.baseUrl + '/customerReception/pageable'
      } else {
        // url = this.baseUrl + '/audit/pageable';
        // params.auditType = 'ELECTRONIC_RELEASE';
        url = this.baseUrl + '/customerReception/pageable'
      }
      this.$util['post'](this, {
        url,
        params,
        config: null,
        errorInfo: false,
        success: (res) => {
          console.log(res)
          const list = res.data.content
          if (list.length > 0) {
            this.receptionId = list[0].receptionId
            this.$set(this, 'datas', {
              approachId: approachId,
              carPlateNumber: list[0].carPlateNumber,
              customerName: list[0].customerName,
              customerPhone: list[0].customerPhone,
              customerGender: list[0].customerGender,
              salesman: list[0].salesman,
              mileage: list[0].mileage,
              businessType: list[0].businessType,
              receptionRemark: list[0].remark
            })
            if (type === 2) {
              this.getReviewer(this.auditId)
            }
          }
        },
        complete: () => {
        }
      })
    },
    show(data, type) {
      const { approachId, carPlateNumber, receptive } = data
      // 已经接待查询接待信息
      if (receptive === 'Y') {
        this.getReceptiveInfo(approachId, type)
      }
      // 已经创建放行单
      if (type === 2) {
        console.log(data)
        this.$set(this, 'auditId', data.auditId)
        if (receptive !== 'Y') {
          this.getReviewer(data.auditId)
        }
        // 获取审批人
      }
      this.datas = Object.assign({}, this.datas, { approachId, carPlateNumber })
      console.log(this.datas)
      this.visible = true
      this.type = type

      // 获取审批人列表
      this.getReviewerList()
    },
    getReviewerList() {
      const params = this.$util.preProcessData({
        perPage: 10000,
        page: 1,
        pageStart: 1,
        userType: this.ROLE_TYPE
      })
      this.$util.post(this, {
        url: this.baseUrl + '/user/find/join/pageable',
        params,
        success: (res) => {
          this.reviewerList = res.data.content.filter(item => {
            console.log(item.userRoleInfos.map(roleItem => roleItem.roleInfo.roleName))
            return item.userRoleInfos.map(roleItem => roleItem.roleInfo['roleCode']).includes('APPROVEUSERS')
          })
          // this.reviewerList = res.data.content.filter(item => item['roleCode'] && item['roleCode'].indexOf('APPROVEUSERS') > -1) || []
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    // 获取审批信息
    getReviewer(auditId) {
      const params = this.$util.preProcessData(Object.assign({}, {
        perPage: 1,
        page: 1,
        pageStart: 1,
        auditId: auditId
      }))
      this.$util.post(this, {
        url: this.baseUrl + '/audit/pageable',
        params,
        config: {},
        success: (res) => {
          console.log(res)
          const list = res.data.content
          if (list.length > 0) {
            this.$set(this.datas, 'remark', list[0].remark)
            this.$set(this.datas, 'reviewer', Number(list[0].reviewer))
            console.log(this.datas.reviewer)
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas = Object.assign({}, this.datas, {})
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">

</style>
