import Customize from './customize'
export default {
  state: () => ({
    constData: {}, // 系统常量
    sourceData: [], // 元数据
    areas: [], // 区域集合
    areaMap: {}, // 区域Map
    orgList: [], // 单位
    roleList: [], // 角色列表
    shopList: [], // 门店列表
    colors: Customize.colors
  }),
  mutations: {},
  actions: {
    initGlobalState(context, state) {
      context.dispatch('getAreas', state)
      context.dispatch('getOrgList', state)
      context.dispatch('getSystemConst', state)
      context.dispatch('getSourceData', state)
      context.dispatch('getRoleList', state)
      context.dispatch('getShopList', state)
    },
    // 刷新组织机构
    refreshOrg(context, state) {
      context.dispatch('getOrgList', state)
    },
    // 获取系统常量
    getSystemConst(state, vm) {
      vm.$util.get(vm, {
        url: vm.baseUrl + '/common/system/const',
        params: {},
        config: null,
        errorInfo: false,
        success: (res) => {
          state.state.constData = res.data
        }
      })
    },
    // 获取系统常量
    getSourceData(state, vm) {
      vm.$util.post(vm, {
        url: vm.baseUrl + '/common/metadata/find',
        params: {
          // subtype: 'SCREEN_FUNCTION_AREA'
        },
        config: null,
        errorInfo: false,
        success: (res) => {
          state.state.sourceData = res.data || []
          console.log(state)
        }
      })
    },
    // 获取区域数据
    getAreas(state, vm) {
      vm._ajax.get('/areas.json').then(response => {
        const data = response.data
        let index = 0
        const dataMap = {}
        const parentArr = []
        const areasArr = []
        for (const i in data) {
          for (const j in data[i]) {
            state.state.areaMap[j] = data[i][j]
            dataMap[j] = { label: data[i][j], value: j }
            if (index === 0) {
              parentArr.push(j)
            } else {
              if (!dataMap[i].children) {
                dataMap[i].children = [dataMap[j]]
              } else {
                dataMap[i].children.push(dataMap[j])
              }
            }
          }
          index++
        }
        parentArr.forEach(item => {
          areasArr.push(dataMap[item])
        })
        state.state.areas = areasArr
      })
    },
    // 单位列表
    getOrgList(state, vm) {
      vm.$util.post(vm, {
        url: vm.baseUrl + '/organization/find/pageable',
        params: {
          perPage: 10000,
          page: 1,
          pageStart: 1
        },
        config: null,
        errorInfo: false,
        success: (res) => {
          state.state.orgList = res.data.content || []
        }
      })
    },
    // 角色列表
    getRoleList(state, vm) {
      vm.$util.post(vm, {
        url: vm.baseUrl + '/authority/role/pageable',
        params: {
          perPage: 10000,
          page: 1,
          pageStart: 1
        },
        config: null,
        errorInfo: false,
        success: (res) => {
          state.state.roleList = res.data.content || []
        }
      })
    },
    // 门店列表
    getShopList(state, vm) {
      vm.$util.post(vm, {
        url: vm.baseUrl + '/shop/join/pageable',
        params: {
          perPage: 10000,
          page: 1,
          pageStart: 1
        },
        config: null,
        errorInfo: false,
        success: (res) => {
          state.state.shopList = res.data.content || []
        }
      })
    }
  }
}
