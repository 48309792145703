<template>
  <div class="parking-report">
    <el-row :gutter="12">
      <el-col v-loading="isLoadingAttendance" :span="12">
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>外部（客户）车辆</span>
          </div>
          <el-row>
            <el-col
              :span="12"
              style="height: 300px; background: #5bd7a6; color: #fff"
              class="flexCenter vehicle-content"
            >
              <div>
                <p><img src="@/assets/vehicle1.png" alt="" ></p>
                <p>累计入场</p>
                <p>{{ attendanceSum.approachCount }}辆</p>
              </div>
            </el-col>
            <el-col :span="12">
              <el-row>
                <el-col
                  :span="24"
                  style="height: 150px; background: #409eff; color: #fff"
                  class="flexCenter vehicle-content"
                >
                  <div>
                    <p><img src="@/assets/vehicle2.png" alt="" ></p>
                    <p>在场车辆累计</p>
                    <p>{{ attendanceSum.totelPresent }}辆</p>
                  </div>
                </el-col>
                <el-col
                  :span="24"
                  style="height: 150px; background: #909399; color: #fff"
                  class="flexCenter vehicle-content"
                >
                  <div>
                    <p><img src="@/assets/vehicle3.png" alt="" ></p>
                    <p>出场累计</p>
                    <p>{{ attendanceSum.departureCount }}辆</p>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <!-- <el-col :span="4">
        <div class="" style="text-align: center; margin-top: 50px">
          <p>今日</p>
          <p>{{ new Date() }}</p>
        </div>
      </el-col> -->
      <el-col v-loading="isLoadingInterior" :span="12">
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>内部车辆</span>
          </div>
          <el-row :gutter="24">
            <el-col
              v-for="(item, index) in interiorVehicleData"
              :span="6"
              :key="index"
              class="interior-vehicle"
            >
              <div
                :style="{ border: '1px solid ' + item.color }"
                class="interior-vehicle-graph"
              >
                <div
                  :style="{
                    height: (200 * item.out) / (item.out + item.in) + 'px',
                    color: item.color,
                  }"
                  class="interior-vehicle-graph-top"
                >
                  <p>已开出</p>
                  <p>{{ item.departure }}辆</p>
                </div>
                <div
                  :style="{ background: item.color }"
                  class="interior-vehicle-graph-bottom"
                >
                  <p>{{ item.present }}辆</p>
                  <p>在场</p>
                </div>
              </div>
              <div :style="{ color: item.color }" class="interior-vehicle-num">
                <img :src="item.photo" alt="" >
                <p>{{ item.name }}</p>
                <p>{{ item.count }}辆</p>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="hover" style="margin-top: 20px">
      <div slot="header" class="clearfix">
        <span>客户类型统计</span>
      </div>
      <el-row v-loading="isLoadingCustom" :gutter="12">
        <el-col :span="8" class="statistics-echats">
          <div class="statistics-card1">
            <div
              id="benchiSize"
              class="traffic-statistics"
              style="margin: 0"
            />
          </div>
        </el-col>
        <el-col :span="8" class="statistics-echats">
          <div class="statistics-card1">
            <div
              id="appointment"
              class="traffic-statistics"
              style="margin: 0"
            />
          </div>
        </el-col>
        <el-col :span="8" class="statistics-echats">
          <div class="statistics-card1">
            <div
              id="interiorProportion"
              class="traffic-statistics"
              style="margin: 0"
            />
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-row :gutter="12" style="margin-top: 20px">
      <el-col :span="24" class="statistics-echats">
        <el-card
          v-loading="isLoadingFlow"
          shadow="hover"
          class="statistics-card"
        >
          <div>
            <div id="vehicleStatistics" class="traffic-statistics"/>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import echarts from 'echarts'
import vehicle4 from '@/assets/vehicle4.png'
import vehicle5 from '@/assets/vehicle5.png'
import vehicle6 from '@/assets/vehicle6.png'
import vehicle7 from '@/assets/vehicle7.png'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      // 外部车辆进出场统计合计
      attendanceSum: {
        appointmentRate: null,
        approach: 0,
        departure: 0,
        present: 0,
        approachCount: 0,
        departureCount: 0,
        totelPresent: 0
      },
      isLoadingAttendance: false,
      interiorVehicleData: [
        {
          out: 0,
          in: 0,
          color: '#5BD7A6',
          name: '试驾车辆',
          key: 'TEST_DRIVE',
          photo: vehicle4
        },

        {
          out: 0,
          in: 0,
          color: '#67C23A',
          name: '公务车辆',
          key: 'OFFICIAL',
          photo: vehicle5
        },
        {
          out: 0,
          in: 0,
          color: '#E6A23C',
          name: '救援车辆',
          key: 'INSURANCE',
          photo: vehicle6
        },
        // {
        //   out: 0,
        //   in: 0,
        //   color: '#F56C6C',
        //   name: '公众车辆',
        //   key: 'PUBLIC',
        //   photo: vehicle7,
        // },
        // {
        //   out: 0,
        //   in: 0,
        //   color: '#666',
        //   name: '私有车辆',
        //   key: 'PRIVATE',
        //   photo: vehicle4,
        // },
        {
          out: 0,
          in: 0,
          color: '#333333',
          name: '员工车辆',
          key: 'EMPLOYEE',
          photo: vehicle4
        }
      ],
      isLoadingInterior: false,
      isLoadingCustom: false,
      isLoadingFlow: false
    }
  },
  watch: {
    '$store.state.report.shopId'(newVal, oldVal) {
      console.log(newVal, '新的门店id')
      this.getPageData()
    }
  },
  mounted() {
    this.getPageData()
  },
  methods: {
    getPageData() {
      this.getAttendanceStatisticsr()
      this.getStatusStatistics()
      this.customerStatistics()
      this.flowStatistics()
    },

    // 外部车辆进出场台次统计
    getAttendanceStatisticsr() {
      const data = {
        startTime: new Date().toLocaleString('lt-LT'),
        endTime: new Date().toLocaleString('lt-LT'),
        shopId: this.$store.state.report.shopId
      }
      this.isLoadingAttendance = true
      const params = this.$util.preProcessData(Object.assign({}, data))
      const url = this.baseUrl + '/report/car/attendance/statistics'
      this.$util.post(this, {
        url,
        params,
        config: {},
        success: (res) => {
          console.log(res)
          if (res.succeed) {
            this.attendanceSum = res.data
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isLoadingAttendance = false
        }
      })
    },

    // 内/外部车辆当前在/离场台数统计
    getStatusStatistics() {
      const shopId = this.$store.state.report.shopId
      this.isLoadingInterior = true
      const url = this.baseUrl + `/report/car/statistics/${shopId}/byType`
      this.$util.get(this, {
        url,
        config: {},
        success: (res) => {
          console.log(res)
          if (res.succeed) {
            this.interiorVehicleData = this.interiorVehicleData.map((item) => {
              const car = { ...item }
              res.data.internal.forEach((resItem) => {
                if (resItem.carCategory && item.key === resItem.carCategory) {
                  item = { ...car, ...resItem }
                }
              })

              return item
            })
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isLoadingInterior = false
        }
      })
    },

    // 客户类型统计
    customerStatistics() {
      const data = {
        startTime: new Date().toLocaleString('lt-LT'),
        endTime: new Date().toLocaleString('lt-LT'),
        shopId: this.$store.state.report.shopId
        // startTime: '2020-01-01 15:59:10',
        // endTime: '2023-04-23 15:59:10',
        // shopId: 1,
      }
      this.isLoadingCustom = true
      const params = this.$util.preProcessData(Object.assign({}, data))
      const url = this.baseUrl + '/report/customer/statistics'
      this.$util.post(this, {
        url,
        params,
        config: {},
        success: (res) => {
          console.log(res)
          if (res.succeed) {
            this.isLoadingCustom = false
            // 奔驰占比
            this.benchiSizeStatistics(res.data.benzProportion)
            // 预约到店率
            this.appointmentStatistics(res.data.appointmentProportion)
            // 内部车辆占比
            this.interiorProportionStatistics(res.data.internalProportion)
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isLoadingCustom = false
        }
      })
    },

    flowStatistics() {
      const data = {
        startTime: new Date().toLocaleString('lt-LT'),
        ednTime: new Date().toLocaleString('lt-LT'),
        shopId: this.$store.state.report.shopId
      }
      // let data = {
      //   startTime: '2020-01-01 15:59:10',
      //   endTime: '2023-04-23 15:59:10',
      //   shopId: 1,
      // };
      this.isLoadingFlow = true
      const params = this.$util.preProcessData(Object.assign({}, data))
      const url = this.baseUrl + '/report/car/flow/statistics'
      this.$util.post(this, {
        url,
        params,
        config: {},
        success: (res) => {
          console.log(res)
          if (res.succeed) {
            this.isLoadingFlow = false
            // 车辆分时统计
            const resData = this.dealVehicleData(res.data)
            console.log(resData)
            this.vehicleStatistics(resData)
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isLoadingFlow = false
        }
      })
    },

    // 处理数据
    dealVehicleData(data) {
      if (!data.length) return []
      const resData = {
        approachData: [],
        xAxis: [],
        departureData: []
      }
      data.forEach((item) => {
        resData.approachData.push(item.approach)
        resData.xAxis.push(item.time)
        resData.departureData.push(item.departure)
      })
      return resData
    },

    vehicleStatistics(data) {
      const myChart = echarts.init(
        document.getElementById('vehicleStatistics')
      )
      const option = {
        dataZoom: {
          id: 'dataZoomX',
          show: true, // 是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
          backgroundColor: 'rgba(47,69,84,0)', // 组件的背景颜色
          type: 'slider', // slider表示有滑动块的，inside表示内置的
          dataBackground: {},
          left: '8%',
          right: '8%'
        },
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        title: {
          text: '车流量分时统计'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        legend: {
          bottom: '15%'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '25%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: data.xAxis
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '进场',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(128, 255, 165)'
                },
                {
                  offset: 1,
                  color: 'rgb(1, 191, 236)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: data.approachData
          },
          {
            name: '出场',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(0, 221, 255)'
                },
                {
                  offset: 1,
                  color: 'rgb(77, 119, 255)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: data.departureData
          }
        ]
      }
      option && myChart.setOption(option)
    },

    // 奔驰占比
    benchiSizeStatistics(benzProportion) {
      const myChart = echarts.init(document.getElementById('benchiSize'))
      const option = {
        tooltip: {
          show: false,
          trigger: 'item'
        },
        title: {
          text: `奔驰占比\n${(benzProportion * 100).toFixed(2)}%`,
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: 25
          }
        },
        color: ['#5370C6', '#E9E9E9'],
        legend: {
          top: '5%',
          left: 'center',
          show: false
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter: function(val) {
                // 让series 中的文字进行换行
                return val.name.split(' ').join('\n')
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: benzProportion, name: '' },
              { value: 1 - benzProportion, name: '' }
            ]
          }
        ]
      }
      option && myChart.setOption(option)
    },
    // 预约到店率
    appointmentStatistics(appointmentProportion) {
      const myChart = echarts.init(document.getElementById('appointment'))
      const option = {
        tooltip: {
          trigger: 'item',
          show: false
        },
        color: ['#FFC03D', '#E9E9E9'],
        title: {
          text: `预约到店率\n${(appointmentProportion * 100).toFixed(2)}%`,
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: 25
          }
        },
        legend: {
          top: '5%',
          left: 'center',
          show: false
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter: function(val) {
                // 让series 中的文字进行换行
                return val.name.split(' ').join('\n')
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: appointmentProportion, name: '' },
              { value: 1 - appointmentProportion, name: '' }
            ]
          }
        ]
      }
      option && myChart.setOption(option)
    },
    // 内部车辆占比
    interiorProportionStatistics(internalProportion) {
      const myChart = echarts.init(
        document.getElementById('interiorProportion')
      )
      const option = {
        tooltip: {
          show: false,
          trigger: 'item'
        },
        title: {
          text: `内部车离场占比\n${(internalProportion * 100).toFixed(2)}%`,
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: 25
          }
        },
        color: ['#F49A23', '#E9E9E9'],
        // title: {
        //   text: '111',
        //   x: 'center',
        //   y: '105',
        //   textStyle: {
        //     fontSize: 25
        //   }
        // },
        legend: {
          top: '5%',
          left: 'center',
          show: false
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter: function(val) {
                // 让series 中的文字进行换行
                return val.name.split(' ').join('\n')
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: internalProportion, name: '' },
              { value: 1 - internalProportion, name: '' }
            ]
          }
        ]
      }
      option && myChart.setOption(option)
    }
  }
}
</script>

<style lang='less'>
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.parking-report {
  padding: 20/192rem;
  > .el-row {
    display: flex;
    align-items: stretch;
    .el-card {
      height: 100%;
    }
  }
  .vehicle-content {
    img {
      width: 35/192rem;
    }
    p {
      text-align: center;
    }
  }
  .interior-vehicle {
    display: flex;
    flex-direction: column;
    &-graph {
      height: 200px;
      display: flex;
      flex-direction: column;
      font-size: 14/192rem;
      &-top {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
      }
      &-bottom {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: #fff;
      }
    }
    &-num {
      height: 100px;
      text-align: center;
      img {
        width: 20%;
        margin-top: 10/192rem;
      }
    }
  }

  .statistics-echats {
    margin-top: 20/192rem;
    position: relative;
    .statistics-card::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .statistics-card {
      overflow-y: hidden;
      position: relative;
      height: 320px;
      width: 100%;
      .traffic-statistics {
        position: absolute;
        left: 0;
        top: 0;
        width: 90%;
        height: 300px;
        margin: 20/192rem;
      }
    }
    .statistics-card1 {
      overflow-y: hidden;
      position: relative;
      height: 380px;
      width: 100%;
      .traffic-statistics {
        position: absolute;
        left: 0;
        top: 0;
        width: 90%;
        height: 380px;
        margin: 20/192rem;
      }
    }
  }
}
</style>
