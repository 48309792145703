<template>
  <div class="page-not-found">
    <em class="el-icon-search"/><br >
    <span
      class="tip-text"
    >暂未找到页面 点击<span
      class="go-home"
      @click="$router.push({ name: 'smartScreen' })"
    >这里</span
    >回到主页</span
    >
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.page-not-found {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  .el-icon-search {
    color: #409eff;
    font-size: 168px;
  }
  .tip-text {
    font-size: 18px;
    color: #262626;
    margin-top: 20px;
    .go-home {
      color: #409eff;
      cursor: pointer;
      &:hover {
        color: fade(#409eff, 70%);
      }
    }
  }
}
</style>
