<!-- 车辆详情 -->
<template>
  <el-dialog :visible="visible" :close-on-click-modal="false" width="800px" title="查看" class="vehicle-info-box" @close="cancel">
    <el-row :gutter="24">
      <el-col :span="12">
        <div>
          <div>
            <div class="label">车牌号：</div><span>{{ datas.carPlateNumber }}</span>
          </div>
          <div>
            <div class="label">品牌：</div><span>{{ datas.brand }}</span>
          </div>
          <div>
            <div class="label">车系：</div><span>{{ datas.serial }}</span>
          </div>
          <div>
            <div class="label">车型：</div><span>{{ datas.type }}</span>
          </div>
          <div>
            <div class="label">颜色：</div><span>{{ datas.color }}</span>
          </div>
          <div>
            <div class="label">入场时间：</div><span>{{ datas.approachTime }}</span>
          </div>
          <div>
            <div class="label">离场时间：</div><span>{{ datas.departureTime }}</span>
          </div>
        </div>
        <div class="actions"><el-button :type="actionType === 1 ? 'primary' : ''" @click="changeInfo(1)">接待信息</el-button> <el-button :type="actionType === 2 ? 'primary' : ''" @click="changeInfo(2)">放行审批</el-button></div>
        <div v-if="actionType === 1" style="margin-top: 20px">
          <div>
            <div class="label">姓名：</div><span>{{ receptiveInfo.customerName }}</span>
          </div>
          <div>
            <div class="label">手机号：</div><span>{{ receptiveInfo.customerPhone }}</span>
          </div>
          <div>
            <div class="label">性别：</div><span>{{ receptiveInfo.customerGender ? genders.filter(item => item.code === receptiveInfo.customerGender)[0].description : '' }}</span>
          </div>
          <div>
            <div class="label">里程表：</div><span>{{ receptiveInfo.mileage }}</span>
          </div>
          <div>
            <div class="label">业务类型：</div><span>{{ receptiveInfo.businessType ? businessTypeList.filter(item => item.code === receptiveInfo.businessType)[0].description : '' }}</span>
          </div>
          <div>
            <div class="label">业务人员：</div><span>{{ receptiveInfo.salesman }}</span>
          </div>
          <div>
            <div class="label">备注信息：</div><span>{{ receptiveInfo.remark }}</span>
          </div>
        </div>
        <div v-if="actionType === 2" style="margin-top: 20px">
          <div>
            <div class="label">申请人：</div><span>{{ electronicReleaseInfo.createBy }}</span>
          </div>
          <div>
            <div class="label">申请时间：</div><span>{{ electronicReleaseInfo.createTime }}</span>
          </div>
          <div>
            <div class="label">审批人：</div><span>{{ electronicReleaseInfo.reviewerName }}</span>
          </div>
          <div>
            <div class="label">审批时间：</div><span>{{ electronicReleaseInfo.reviewTime }}</span>
          </div>
          <div>
            <div class="label">审批状态：</div><span>{{ electronicReleaseInfo.approachFlag ? approvalFlags.find(item => item.code === electronicReleaseInfo.approachFlag)['description'] : '' }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <img :src="datas.approachImage" style="width: 100%" alt="">
        <img :src="datas.departureImage" style="width: 100%" alt="">
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      type: 1,
      datas: {},
      visible: false,
      actionType: 1, // 1接待信息 2电子放行单
      receptiveInfo: {},
      electronicReleaseInfo: {}
    }
  },
  computed: {
    genders() {
      return this.$store.state.common.constData.genders || []
    },
    sourceData() {
      return this.$store.state.common.sourceData || []
    },
    businessTypeList() {
      return this.$store.state.common.constData.businessTypes || []
    },
    approvalFlags() {
      return this.$store.state.common.constData.approvalFlags || []
    }
  },
  mounted() {},
  methods: {
    // 根据approachId 获取接待信息
    getReceptiveInfo(approachId) {
      const params = {
        approachId: approachId,
        shopId: Number(localStorage.getItem('shopId'))
      }
      const url = this.baseUrl + '/customerReception/pageable'
      this.$util['post'](this, {
        url,
        params,
        config: null,
        errorInfo: false,
        success: (res) => {
          console.log(res)
          const list = res.data.content
          if (list.length > 0) {
            this.$set(this, 'receptiveInfo', {
              createUserInfo: list[0].createUserInfo,
              carPlateNumber: list[0].carPlateNumber,
              customerName: list[0].customerName,
              customerPhone: list[0].customerPhone,
              customerGender: list[0].customerGender,
              salesman: list[0].salesman,
              mileage: list[0].mileage,
              businessType: list[0].businessType,
              remark: list[0].remark
            })
          }
        }
      })
    },
    // 电子放行单
    getElectronicRelease(approachId) {
      const params = {
        approachId: approachId,
        shopId: Number(localStorage.getItem('shopId')),
        // auditType: 'ELECTRONIC_RELEASE'
        page: 1,
        pageStart: 1,
        perPage: 10
      }
      const url = this.baseUrl + '/audit/pageable'
      this.$util['post'](this, {
        url,
        params,
        config: null,
        errorInfo: false,
        success: (res) => {
          console.log(res)
          const list = res.data.content
          if (list.length > 0) {
            this.$set(this, 'electronicReleaseInfo', {
              carPlateNumber: list[0].carPlateNumber || {},
              customerName: list[0].customerName,
              customerPhone: list[0].customerPhone,
              customerGender: list[0].customerGender,
              salesman: list[0].salesman,
              mileage: list[0].mileage,
              businessType: list[0].businessType,
              remark: list[0].remark,
              createBy: list[0].createBy,
              createTime: list[0].createTime
            })
            console.log(list[0].auditId)
            // 查询审批信息
            if (list[0].auditId) {
              const params = {
                perPage: 10000,
                page: 1,
                pageStart: 1
              }
              this.$util.post(this, {
                url: this.baseUrl + '/user/find/pageable',
                params,
                success: (res) => {
                  const reviewerList = res.data.content || []
                  if (list[0].flag !== 'N') {
                    this.$set(this.electronicReleaseInfo, 'reviewTime', list[0].updateTime)
                  }
                  this.$set(this.electronicReleaseInfo, 'approachFlag', list[0].flag)
                  if (list.length > 0) {
                    const d = reviewerList.find(item => item.userId === Number(list[0].reviewer))
                    console.log(d)
                    this.$set(this.electronicReleaseInfo, 'reviewerName', d['userName'])
                    console.log(this.electronicReleaseInfo.reviewer)
                  }
                }
              })
            }
          }
        }
      })
    },
    changeInfo(type) {
      this.actionType = type
      if (type === 1) {
        this.getReceptiveInfo(this.datas.approachId)
      } else if (type === 2) {
        this.getElectronicRelease(this.datas.approachId)
      }
    },
    show(data, type) {
      this.type = type
      this.datas = Object.assign({}, data)
      console.log(this.datas)
      this.visible = true
      // 获取接待信息
      this.getReceptiveInfo(data.approachId)
    },
    cancel() {
      this.datas = {}
      this.loading = false
      this.visible = false
      this.actionType = 1
    }
  }
}

</script>

<style lang='less'>
.vehicle-info-box {
  .label {
    display: inline-block;
    width: 80px;
    text-align: right;
    margin-bottom: 10px;
  }
  .actions {
    display: flex;
    justify-content: space-around;
  }
}
</style>
