<template>
  <div class="common-table-box">
    <div class="common-table-container" >
      <el-table
        id="out-table"
        ref="table"
        :data="tableData"
        :highlight-current-row="hightLight"
        empty-text="暂无数据"
        stripe
        fit
        @selection-change="selectionChange"
        @row-click="rowClick"
      >
        <template slot="empty">
          <p class="empty-text">暂无数据</p>
        </template>
        <!-- 选择 -->
        <el-table-column v-if="showIndex" type="index" width="50" align="center"/>
        <el-table-column v-if="showSelection" type="selection" fixed="left" width="55" align="center" />
        <template v-for="(item, idx) in headerList">
          <!-- 特殊处理列 -->
          <el-table-column
            v-if="item.render"
            :key="idx"
            :prop="item.param ? item.param : null"
            :label="item.label ? item.label : null"
            :width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :align="item.align ? item.align : 'center'"
            :fixed="item.fixed ? item.fixed : null"
            :show-overflow-tooltip="item.tooltip ? false : true"
            min-width="10"
          >
            <template slot-scope="scope">
              <ex-slot :render="item.render" :row="scope.row" :index="scope.$index" :column="item" />
            </template>
          </el-table-column>
          <!-- 正常列 -->
          <el-table-column
            v-else
            :key="idx"
            :prop="item.param ? item.param : null"
            :label="item.label ? item.label : null"
            :width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :align="item.align ? item.align : 'center'"
            :fixed="item.fixed ? item.fixed : null"
            :formatter="item.formatter"
            :show-overflow-tooltip="item.tooltip ? false : true"
            min-width="10"
          />
        </template>
      </el-table>
    </div>

    <div v-if="showPage" class="layout-content-page">
      <slot name="totalData"/>
      <el-pagination
        v-if="showPagination"
        :total="pager.total"
        :page-size="pager.pageSize"
        :current-page="pager.currentPage"
        :page-sizes="pageSizes"
        :background="true"
        layout="total, prev, pager, next, sizes, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
// 自定义内容的组件
var exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null
    }
  },
  render: (h, context) => {
    const params = {
      row: context.props.row,
      index: context.props.index
    }
    if (context.props.column) params.column = context.props.column
    return context.props.render(h, params)
  }
}

export default {
  components: { exSlot },
  props: {
    showIndex: {
      type: Boolean,
      default: false
    },
    hightLight: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    },
    headerList: {
      type: Array,
      default: () => []
    },
    showSelection: {
      type: Boolean,
      default: () => false
    },
    inTableHeight: {
      type: [Number, String, Function],
      default: () => null
    },
    // 是否显示分页
    showPagination: {
      type: Boolean,
      default: true
    },
    pager: {
      // 分页信息
      type: Object,
      default: function() {
        return {
          currentPage: 1,
          pageSize: 10,
          total: 0
        }
      }
    },
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 20, 30, 40, 50]
      }
    },
    // 是否展示分页
    showPage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },

  methods: {
    /**
     * 选择框选择后更改,事件分发
     */
    selectionChange(selection) {
      this.$emit('selection-change', selection)
    },
    /**
     * 点击事件
     */
    rowClick(row, column, event) {
      this.$emit('row-click', row, column, event)
    },

    // size发生变化时，令currentPgae为1，并发送自定义事件
    handleSizeChange(val) {
      this.pager.currentPage = 1
      this.$emit('handleSizeChange', val)
    },
    // currentPage发生变化时，发送自定义事件
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val)
    },
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    }
  }
}
</script>
<style lang="less">
.common-table-box{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    .empty {
        width: 100/192rem;
    }
    .empty-text {
        margin-top: -25/192rem;
    }
    .common-table-container {
      width: 100%;
      height: calc(~"100% - 50px");
      overflow-y: auto;
      position: absolute;
      .el-table {
        border: 1px solid #e1e1e1;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        .el-table__fixed {
          &-header-wrapper {
            .el-table-column--selection {
                background-color: #eceeef !important;
            }
          }

        }
        &__header-wrapper {

            tr th {
                background-color: #eceeef;
            }
        }
      }
    }

}
.layout-content-page {
    padding-top: 10/192rem;
    text-align: right;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.el-pagination {
    flex: 1;
}
</style>
