import Vue from 'vue'
import './gobal/css/font.css'
import '@/gobal/css/common.less'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import '@/gobal/css/re-components.less'

import store from './store'
import util from './gobal/lib/util'
import Axios from 'axios'

// require("/static/config.js");

Vue.config.productionTip = false
Vue.prototype = Vue.prototype = Object.assign(Vue.prototype, window.g)
Vue.prototype._ajax = Axios
Vue.prototype.$util = util
Vue.use(ElementUI)

Vue.prototype.$bus = new Vue()

new Vue({
  router: router,
  store,
  created() {
    util.ajax(this)
  },
  render: h => h(App)
}).$mount('#app')

// rem 屏幕尺寸
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0]
  let htmlWidth =
    document.documentElement.clientWidth || document.body.clientWidth
  if (htmlWidth <= 1366) {
    htmlWidth = 1366
  }
  htmlDom.style.fontSize = `${htmlWidth / 10}px`
}
window.onresize = setHtmlFontSize
setHtmlFontSize()
