<template>
  <CommonPage :show-page="false" :loading="loading" class="shop-manager-box" @query="search" @reset="reset">
    <template slot="filters">
      <el-form ref="defaultForm" :model="filters" :rules="rules" inline>
        <el-form-item label="经销商名称：" prop="shopName">
          <el-input v-model="filters.shopName" placeholder="请输入经销商名称"/>
        </el-form-item>
      </el-form>
    </template>
    <template slot="btnOptions">
      <div>
        <el-button v-if="isShow" type="primary" @click="editShop(1)" >新增经销商</el-button>
        <EditShop ref="editShop" @refresh="queryPage(templateParams)" />
      </div>
    </template>
    <template slot="dataTable">
      <CommonTable
        :header-list="headerList"
        :table-data="tableData"
        :loading="loading"
        :pager="pager"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
import EditShop from './edit-shop.vue'
export default {
  components: { CommonPage, CommonTable, EditShop },
  data() {
    return {
      isShow: localStorage.getItem('userName') === 'admin',
      // table头部
      headerList: [
        {
          label: '经销商名称',
          param: 'shopName'
        },
        {
          label: '经销商编号',
          param: 'gssnCode'
        },
        {
          label: '状态',
          render: (h, data) => {
            return h('span', {}, data.row.status === 0 ? '停用' : '启用')
          }
        },
        {
          label: '经销商类型',
          param: 'shopType'
        },
        {
          label: '所属集团',
          render: (h, data) => {
            return h('span', {}, data.row.organizationInfo ? data.row.organizationInfo.organizationName : '')
          }
        },
        {
          label: '操作',
          width: '200px',
          render: (h, data) => {
            return h('div', {}, [
              h('span', {
                on: {
                  click: () => {
                    this.editShop(2, data.row)
                  }
                },
                class: 'table-text-btn'
              }, [
                h('em', { class: 'el-icon-edit' }),
                '编辑'
              ]),
              h('span', {
                on: {
                  click: () => {
                    this.deleteShop(data.row)
                  }
                },
                class: 'table-text-btn'
              }, [
                h('em', { class: 'el-icon-delete' }),
                '删除'
              ])
            ])
          }
        }
      ],
      tableData: [],
      loading: false,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filters: {
        shopName: ''
      },
      rules: {},
      templateParams: {} // 临时请求参数
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    /**
     * 分页数据检索
     * @param filters 检索条件
     */
    queryPage(filters = {}) {
      this.loading = true
      const params = Object.assign({}, {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        pageStart: 1
      }, filters)
      this.$util.post(this, {
        url: this.baseUrl + '/shop/join/pageable',
        params,
        config: {},
        success: (res) => {
          this.templateParams = params
          this.pager.total = res.data.totalElements || 0
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = res.data.content || []
          })
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    // 查询
    search() {
      this.pager.currentPage = 1
      const params = {
        shopName: this.filters.shopName || undefined
      }
      this.queryPage(params)
    },
    // 重置
    reset() {
      this.$refs.defaultForm.resetFields()
      this.pager.currentPage = 1
      this.search()
    },
    // 翻页
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage()
    },
    // 分页显示数量改变
    handleSizeChange(pageSize) {
      this.pager.pageSize = pageSize
      this.queryPage()
    },
    /**
     * 编辑经销商
     * @param type 1 新增 2 编辑
     * @param datas 数据对象 新增不用🚢
     */
    editShop(type, datas = {}) {
      this.$refs.editShop.show(datas, type)
    },
    // 删除经销商
    deleteShop(item) {
      this.$confirm(`是否确认删除经销商-${item.shopName}`, '经销商删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$util.delete(this, {
          url: this.baseUrl + `/shop/${item.shopId}/delete`,
          config: null,
          success: () => {
            this.$message.success('经销商删除成功')
            this.queryPage(this.templateParams)
          }
        })
      }).catch(() => {
        // 取消的提示
      })
    }
  }
}
</script>

<style lang="less">
  .shop-manager-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding:0 20/192rem 20/192rem 20/192rem;
  }
</style>
