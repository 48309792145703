<template>
  <el-dialog :visible="visible" :close-on-click-modal="false" title="放行审批" class="form-inline-edit" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item label="车牌号/VIN：" prop="carPlateNumber">
        <el-input v-model="datas.carPlateNumber" placeholder="请输入车牌号/VIN" disabled/>
      </el-form-item>
      <el-form-item label="客户姓名：" prop="customerName">
        <el-input v-model="datas.customerName" disabled placeholder="请输入客户姓名"/>
      </el-form-item>
      <el-form-item label="手机号：" prop="customerPhone">
        <el-input v-model="datas.customerPhone" disabled placeholder="请输入手机号"/>
      </el-form-item>
      <!-- <el-form-item label="性别：" prop="customerGender">
        <el-radio-group v-model="datas.customerGender" disabled>
          <el-radio label="1">男</el-radio>
          <el-radio label="0">女</el-radio>
          <el-radio label="2">保密</el-radio>
      <el-form-item label="性别：" prop="customerGender">
        <el-radio-group v-model="datas.customerGender" :disabled="type === 2">
          <el-radio v-for="item in genders" :label="item.code" :key="item.code">{{ item.description }}</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="性别：" prop="customerGender">
        <el-radio-group v-model="datas.customerGender" disabled>
          <el-radio v-for="item in genders" :label="item.code" :key="item.code">{{ item.description }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="里程表信息：" prop="mileage">
        <el-input v-model="datas.mileage" disabled placeholder="请输入里程表信息"/>
        km
      </el-form-item>
      <el-form-item label="业务类型：" prop="businessType" >
        <el-select v-model="datas.businessType" placeholder="请选择业务类型" disabled>
          <el-option
            v-for="item in businessTypeList"
            :key="item.code"
            :label="item.description"
            :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="业务人员：" prop="salesman">
        <el-input v-model="datas.salesman" disabled placeholder="请输入业务人员"/>
      </el-form-item>
      <el-form-item label="接待备注：" prop="receptionRemark">
        <el-input v-model="datas.receptionRemark" :autosize="{ minRows: 4}" disabled type="textarea" placeholder="请输入备注" maxlength="100" show-word-limit/>
      </el-form-item>
      <el-form-item label="申请备注：" prop="remark">
        <el-input v-model="datas.remark" :autosize="{ minRows: 4}" disabled type="textarea" placeholder="请输入备注" maxlength="100" show-word-limit/>
      </el-form-item>
      <el-form-item label="审批意见：" prop="reason">
        <el-input v-model="datas.reason" type="text" placeholder="请输审批意见"/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" type="primary" @click="save">通过</el-button>
      <el-button :loading="loading" plain @click="refuse">驳回</el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from '@/gobal/lib/validate.js'
export default {
  data() {
    return {
      visible: false,
      type: 1, // 1 接待 2 补录离场信息 3电子放行单
      loading: false,
      datas: {
        carPlateNumber: '',
        customerName: '',
        customerPhone: '',
        customerGender: '',
        salesman: '',
        mileage: '',
        businessType: '',
        remark: '',
        receptionRemark: ''
      },
      rules: {
        carPlateNumber: [{ required: true, message: '请输入车牌号/VIN', trigger: 'blur' }],
        customerPhone: [{ required: false, validator: validate.validatecustomerPhone, trigger: 'blur' }],
        businessType: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
        salesman: [{ required: true, message: '请输入接待人', trigger: 'blur' }]
      }
    }
  },
  computed: {
    genders() {
      return this.$store.state.common.constData.genders || []
    },
    businessTypeList() {
      return this.$store.state.common.constData.businessTypes || []
    },
    sourceData() {
      return this.$store.state.common.sourceData || []
    }
  },
  methods: {
    save() {
      this.$confirm(`是否确认通过审核`, '审核提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = false
        this.$util.put(this, {
          url: this.baseUrl + `/audit/${this.datas.auditId}/agree`,
          params: {},
          success: res => {
            this.$message.success('审批通过')
            this.$emit('refresh')
            this.cancel()
          },
          complete: res => {
            this.loading = false
          }
        })
      })
    },
    refuse() {
      if (!this.datas.reason) {
        this.$message.warning('请输入审核意见')
        return
      }
      this.$confirm(`是否确认驳回审核`, '审核提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = false
        this.$util.put(this, {
          url: this.baseUrl + `/audit/${this.datas.auditId}/refuse?reason=${this.datas.reason}`,
          params: {},
          success: res => {
            this.$message.success('已驳回')
            this.$emit('refresh')
            this.cancel()
          },
          complete: res => {
            this.loading = false
          }
        })
      })
    },
    show(data, type) {
      console.log(data)
      this.$set(this, 'datas', {
        auditId: data.auditId,
        carPlateNumber: data.carPlateNumber,
        customerName: data.receptionInfo.customerName,
        customerPhone: data.receptionInfo.customerPhone,
        customerGender: data.receptionInfo.customerGender,
        salesman: data.salesman || data.receptionInfo.salesman,
        mileage: data.mileage || data.receptionInfo.mileage,
        businessType: data.businessType || data.receptionInfo.businessType,
        receptionRemark: data.receptionInfo.remark,
        remark: data.remark

      })
      this.$set(this.datas, 'reason', '')
      this.visible = true
      this.type = type
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.$set(this, 'datas', {})
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">

</style>
