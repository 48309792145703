export default {
  isShowBotton(key, that) {
    if (localStorage.getItem('userName') === 'admin') {
      return true
    } else {
      const roles = localStorage.getItem('roles')
      // 经销商管理员
      if (roles.indexOf('DEALERADMINISTRATOR') > -1) {
        if (that.roleButtonPermission['DEALERADMINISTRATOR'].find(item => item === key)) {
          return true
        }
      } else if (roles.indexOf('APPROVEUSERS') > -1) {
        // 审批用户
        if (that.roleButtonPermission['APPROVEUSERS'].find(item => item === key)) {
          return true
        }
      } else {
        // 普通用户
        if (that.roleButtonPermission['DEFAULT'].find(item => item === key)) {
          return true
        }
      }
      return false
    }
  }
}
