<template>
  <div class="login-box">
    <div class="login-container">
      <div class="login-content">
        <div class="login-content-center">
          <div class="login-title"/>
          <div class="login-title-chinese">梅赛德斯-奔驰智能展厅管理平台</div>
          <div class="login-title-en">
            <em>Mercedes-Benz Intelligent Showroom Management Platform（ISMP）</em>
          </div>
          <div class="loading-wait">
            <em class="el-icon-loading"/> <br> 正在登录中...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CryptoJS from 'crypto-js'
export default {
  data() {
    return {
      keyH: 'Rgs',
      keyM: '#09',
      keyL: 'yu'
    }
  },
  mounted() {
    setTimeout(() => {
      this.login()
    }, 1500)
  },
  beforeDestroy() {
    window.removeEventListener('message', () => {})
  },
  methods: {
    login() {
      const that = this
      // 为给定 ID 的 user 创建请求
      axios.get(this.otherUrl + `sso/daimler/login/portal`)
        .then(function(res) {
          window.open(res.data)
          window.addEventListener('message', function(e) {
            var params = e.data
            var tokenInfo = JSON.parse(params.token)
            console.log(tokenInfo)
            if (!tokenInfo.role || tokenInfo.role.indexOf('TERMINAL_BO') < 0) {
              that.$message.warning('该账户无平台访问权限')
              return
            }
            localStorage.setItem('token', tokenInfo.access_token)
            localStorage.setItem('userName', tokenInfo.username)
            localStorage.setItem('userId', tokenInfo.userId)
            localStorage.setItem('roles', tokenInfo.role ? tokenInfo.role.join(',') : '')
            localStorage.setItem('singlePoint', true)
            that.$message.success('登录成功')
            that.$router.push({ name: 'smartScreen', query: { v: new Date().getTime() }})
          })
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    // login() {
    //   const that = this
    //   // 为给定 ID 的 user 创建请求
    //   axios.get(this.otherUrl + `sso/daimler/login/portal`)
    //     .then(function(res) {
    //       console.log(res.data)
    //       axios.get(that.otherUrl + `sso/daimler/par`)
    //         .then(function(res1) {
    //           const client_id = that.decryptByDES(res1.data.data.ci)
    //           const client_secret = that.decryptByDES(res1.data.data.cs)
    //           window.open(res.data.replace('{client_id}', client_id).replace('{client_secret}', client_secret))
    //           window.addEventListener('message', function(e) {
    //             var params = e.data
    //             var tokenInfo = JSON.parse(params.token)
    //             console.log(tokenInfo)
    //             if (!tokenInfo.role || tokenInfo.role.indexOf('TERMINAL_BO') < 0) {
    //               that.$message.warning('该账户无平台访问权限')
    //               return
    //             }
    //             localStorage.setItem('token', tokenInfo.access_token)
    //             localStorage.setItem('userName', tokenInfo.username)
    //             localStorage.setItem('userId', tokenInfo.userId)
    //             localStorage.setItem('roles', tokenInfo.role ? tokenInfo.role.join(',') : '')
    //             localStorage.setItem('singlePoint', true)
    //             that.$message.success('登录成功')
    //             that.$router.push({ name: 'smartScreen', query: { v: new Date().getTime() }})
    //           })
    //         })
    //     })
    //     .catch(function(error) {
    //       console.log(error)
    //     })
    // },
    // 解密
    decryptByDES(ciphertext) {
      ciphertext = (ciphertext + '').replace(/\n*$/g, '').replace(/\n/g, '')
      var keyHex = CryptoJS.enc.Utf8.parse(this.keyH + this.keyM + this.keyL)
      // var keyHex = CryptoJS.enc.Utf8.parse(this.getKeyH + this.getKeyM() + this.getKeyL())
      var decrypted = CryptoJS.DES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
      }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })
      return decrypted.toString(CryptoJS.enc.Utf8)
    }
  }
}
</script>

<style lang="less" scoped>
@import url('@/gobal/css/common.less');
.login-box {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: top;
	.login-container {
		width: 100%;
		height: 50%;
		background-color: rgba(5, 9, 12, 1);
    background-image: url('../../assets/benz-bg.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
		.login-content {
			width: 420px;
			height: 558px;
			position: absolute;
      margin-left: calc(~'50% - 210px');
			top: calc(~'50% - 279px');
			background-color: @light-bg;
			box-shadow: 0 0 10px fade(#262626, 30%);
			display: flex;
			justify-content: center;
			&-center {
				width: 100%;
				margin-top: 30/192rem;
				.login-title {
          width: 100%;
          height: 162px;
          background-image: url('../../assets/benz-logo.png');
          background-repeat: no-repeat;
          background-position-x: center;
          background-position-y: bottom;
          background-size: 47%;
				}
        .login-title-chinese {
          font-size: 20px;
          color: #000000;
          font-weight: bold;
          text-align: center;
        }
        .login-title-en {
          // font-family:  '微软雅黑 Oblique', '微软雅黑', sans-serif;
          font-size: 16px;
          color: #333333;
          text-align: center;
        }
        .loading-wait {
          text-align: center;
          padding-top: 50px;
          box-sizing: border-box;
          font-size: 18px;
          line-height: 40px;
          em {
            font-size: 38px;
          }
        }
			}
		}
	}
}
</style>
