<template>
  <CommonPage :show-page="false" class="o r g-manager-box" @query="search" @reset="reset">
    <template slot="filters">
      <el-form ref="defaultForm" :model="filters" :rules="rules" inline label-width="90px">
        <el-form-item label="车牌号：" prop="carPlateNumber">
          <el-input v-model="filters.carPlateNumber" placeholder="请输入车牌号/VIN"/>
        </el-form-item>
        <el-form-item label="日期：" prop="datetimerange">
          <el-date-picker
            v-model="filters.datetimerange"
            type="datetimerange"
            clearable
            start-placeholder="开始日期"
            end-placeholder="结束日期"/>
        </el-form-item>
      </el-form>
    </template>
    <template slot="btnOptions">
      <div>
        <!-- <Edit ref="edit" @refresh="queryPage(templateFilters)"/> -->
        <!-- <reception ref="reception" @refresh="queryPage(templateFilters)"></reception> -->
      </div>
    </template>
    <template slot="dataTable">
      <CommonTable
        :header-list="headerList"
        :table-data="tableData"
        :loading="loading"
        :pager="pager"
        show-index
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
import { mapActions } from 'vuex'
export default {
  components: {
    CommonPage,
    CommonTable
  },
  data() {
    const that = this
    return {
      headerList: [
        { label: '车牌/VIN', param: 'carPlateNumber' },
        { label: '进/出场时间', param: 'createTime' },
        // { label: '出场时间', param: 'updateTime' },
        { label: '品牌 车系', render: (h, data) => {
          let text = data.row.carInfo && data.row.carInfo.brand ? data.row.carInfo.brand : ''
          text += data.row.carInfo && data.row.carInfo.serial ? ' ' + data.row.carInfo.serial : ''
          return h('span', {}, text)
        }
        },
        // { label: '是否接待',
        //   render: (h, data) => {
        //     return h('span', {}, data.row.status === 0 ? '停用' : '启用')
        //   }
        // },
        { label: '颜色', render: (h, data) => {
          return h('span', {}, data.row.carInfo ? data.row.carInfo.color || '' : '')
        } },
        { label: '道闸名称', param: 'gateName' },
        // { label: '客户', param: 'organizationType' },
        // { label: '业务人员', param: 'organizationType' },
        { label: '状态',
          render: (h, data) => {
            // return h('span', {}, that.carStatuses.length > 0 ? that.carStatuses.find(item => item.code === data.row.status)['description'] : '')
            return h('span', {}, data.row.type === '12' ? '在场' : '离场')
          }
        },
        { label: '类型', render: (h, data) => {
          const text = data.row.carInfo && data.row.carInfo.carSource ? data.row.carInfo.carSource === 'EXTERNAL' ? '外部车辆' : '内部车辆' : ''
          return h('span', {}, text)
        }
        },
        { label: '备注', param: 'remark' }
        // {
        //   label: '操作',
        //   width: '150px',
        //   render: (h, data) => {
        //     return h('div', {}, [
        //       h('span', {
        //         on: {
        //           click: () => {
        //             this.edit(2, data.row)
        //           }
        //         },
        //         class: 'table-text-btn'
        //       }, [
        //         h('em', { class: 'el-icon-edit' }),
        //         '编辑'
        //       ]),
        //       h('span', {
        //         on: {
        //           click: () => {
        //             this.deleteApprove(data.row)
        //           }
        //         },
        //         class: 'table-text-btn'
        //       }, [
        //         h('em', { class: 'el-icon-delete' }),
        //         '删除'
        //       ])
        //     ])
        //   }
        // }
      ],
      tableData: [],
      loading: false,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filters: {
        datetimerange: [],
        carPlateNumber: ''
      },
      options: [{
        label: 'test',
        value: 1
      }],
      rules: {},
      templateFilters: {}
    }
  },
  computed: {
    carSources() {
      return this.$store.state.common.constData.carSources || []
    },
    carStatuses() {
      return this.$store.state.common.constData.carStatuses || []
    }
  },
  created() {
    this.search()
  },
  methods: {
    ...mapActions(['refreshOrg']),
    queryPage(requestParams) {
      this.loading = true
      const customParams = {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        pageStart: 1,
        // dateFrom: this.filters.datetimerange && this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[0]) : '',
        // dateTo: this.filters.datetimerange && this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[1], 'yyyy-MM-dd 23:59:59') : '',
        dateFrom: this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[0], 'yyyy-MM-dd hh:mm:ss') : '',
        dateTo: this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[1], 'yyyy-MM-dd hh:mm:ss') : '',
        shopId: Number(localStorage.getItem('shopId'))
      }
      const params = this.$util.preProcessData(Object.assign({}, requestParams, customParams))

      this.$util.post(this, {
        url: this.baseUrl + '/car/attendance/pageable',
        params,
        config: {},
        success: (res) => {
          this.templateFilters = this.$util.deepCopy(requestParams)
          this.pager.total = res.data.totalElements || 0
          this.tableData = res.data.content || []
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    search() {
      this.pager.currentPage = 1
      this.queryPage(this.filters)
    },
    reset() {
      this.$refs.defaultForm.resetFields()
      this.pager.currentPage = 1
      this.search()
    },
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage(this.templateFilters)
    },
    handleSizeChange(pageSize) {
      this.pager.pageSize = pageSize
      this.queryPage(this.templateFilters)
    },
    reception(type, datas = {}) {
      this.$refs.reception.show(datas, type)
    },
    edit(type, datas = {}) {
      this.$refs.edit.show(datas, type)
    },
    deleteApprove(item) {
      this.$confirm(`是否确认删除预约`, '预约删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$util.delete(this, {
        //   url: this.baseUrl + `/organization/${item.organizationId}/delete`,
        //   config: null,
        //   success: () => {
        //     this.$message.success('接待删除成功')
        //     this.refreshOrg(this)
        //     this.queryPage(this.templateParams)
        //   }
        // })
      }).catch(() => {
        // 取消的提示
      })
    }
  }
}
</script>

<style lang="less">
  .org-manager-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 16/192rem 16/192rem 16/192rem;
  }
</style>
