<!--  -->
<template>
  <CommonPage :show-filter="false" class="version-management-box">
    <template slot="dataTable">
      <CommonTable
        :show-index="true"
        :header-list="headerList"
        :table-data="tableData"
        :show-page="false"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
export default {
  name: '',
  components: {
    CommonPage,
    CommonTable
  },
  props: {},
  data() {
    const that = this
    return {
      headerList: [
        { label: '设备编号', param: 'deviceNo' },
        { label: '设备名称', param: 'deviceName' },
        { label: '设备IP', param: 'deviceIp' },
        { label: '进出口', param: 'type', render: (h, data) => {
          return h('span', {}, data.row.type === '1' ? '入口' : '出口')
        } },
        { label: '在线状态', render: (h, data) => {
          return h('span', {}, data.row.status === 1 ? '在线' : '离线')
        } },
        { label: '离线时间', param: 'updateTime' }
      ],
      tableData: []
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const params = {
        shopId: Number(localStorage.getItem('shopId'))
      }
      this.$util.post(this, {
        url: this.baseUrl + '/report/device/status/statistics',
        params,
        config: {},
        success: (res) => {
          this.tableData = res.data || []
        },
        error: (res) => {
          console.log(res)
        }
      })
    }
  }
}

</script>

<style lang='less'>

</style>
