<!--  -->
<template>
  <div class="overview-data-box">
    <el-row :gutter="12">
      <el-col v-loading="isLoadingShop" :span="12" class="statistics-echats">
        <el-card
          shadow="hover"
          class="statistics-card"
          style="overflow-y: scroll"
        >
          <div>
            <p class="statistics-card-title" style="margin-bottom: 10px">
              商家状态统计
            </p>
            <div
              v-for="(item, index) in businessStatusList"
              :key="index"
              style="margin-bottom: 15px"
            >
              <div class="statistics-card-content">
                <div class="statistics-card-content-left">
                  <div :style="item.cricle" class="cricle"/>
                  <div>{{ item.name }}</div>
                </div>
                <div class="statistics-card-content-right">
                  <span class="statistics-card-content-right-num">{{
                    item.current || '0'
                  }}</span>
                  / <span>{{ item.all }}</span
                  >家
                </div>
              </div>
              <el-progress
                :text-inside="true"
                :stroke-width="18"
                :percentage="
                  item.current
                    ? parseFloat(
                      ((item.current / item.all) * 100).toFixed(
                        2
                      )
                    )
                    : 0
                "
                :status="item.status"
              />
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col v-loading="isLoadingArea" :span="12" class="statistics-echats">
        <el-card shadow="hover" class="statistics-card">
          <div>
            <div id="dealerDistribution" class="traffic-statistics"/>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row v-loading="isLoadingCar" :gutter="30">
      <el-col
        v-for="(item, index) in statisticsList"
        :span="8"
        :key="index"
        style="marginbottom: 10px"
      >
        <el-card shadow="hover">
          <div class="statistics-all">
            <div class="statistics-all-tips">
              <span>{{ item.lable }}</span>
              <el-tooltip
                v-if="item.tip"
                :content="item.tips"
                class="item"
                effect="dark"
                placement="top"
              >
                <em class="el-icon-info" />
              </el-tooltip>
            </div>
            <div class="statistics-all-num">
              <span class="num">{{ carData[item.key] || '0' }}</span>
              {{ item.unit }}
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 岗亭记录 -->
    <el-row :gutter="12">
      <el-col :span="24" class="statistics-echats">
        <el-card shadow="hover" class="statistics-card">
          <div id="sentryRecord" class="traffic-statistics"/>
        </el-card>
      </el-col>
    </el-row>

    <!-- 车流量统计、门店流量统计 -->
    <!-- <el-row :gutter="12">
      <el-col :span="12" class="statistics-echats">
         <el-card shadow="hover" class="statistics-card">
           <div class="traffic-statistics" id="trafficStatistics"></div>
         </el-card>
      </el-col>
      <el-col :span="12" class="statistics-echats">
         <el-card shadow="hover" class="statistics-card">
           <div class="traffic-statistics" id="storeTrafficRanking"></div>
         </el-card>
      </el-col>
    </el-row> -->
    <!-- 投屏次数统计 -->
    <el-row :gutter="12">
      <el-col :span="24" class="statistics-echats">
        <el-card shadow="hover" class="statistics-card">
          <div id="registrationsNumber" class="traffic-statistics"/>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="12">
      <el-col :span="12" class="statistics-echats">
        <el-card
          shadow="hover"
          class="statistics-card"
          style="overflow-y: scroll"
        >
          <div>
            <p class="statistics-card-title" style="margin-bottom: 10px">
              投屏频时长前十经销商
            </p>
            <el-table :data="duration10TableData" style="width: 100%">
              <!-- <el-table-column
                prop="date"
                label="排名">
              </el-table-column> -->
              <el-table-column label="排名" type="index" width="100"/>
              <el-table-column prop="shopName" label="店名"/>
              <el-table-column prop="duration" label="投屏时长（s）"/>
              <el-table-column prop="times" label="投屏次数"/>
            </el-table>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12" class="statistics-echats">
        <el-card shadow="hover" class="statistics-card">
          <div>
            <p class="statistics-card-title" style="margin-bottom: 10px">
              投屏频次前十经销商
            </p>
            <el-table :data="time10TableData" style="width: 100%">
              <!-- <el-table-column
                prop="date"
                label="排名">
              </el-table-column> -->
              <el-table-column label="排名" type="index" width="100"/>
              <el-table-column prop="shopName" label="店名"/>
              <el-table-column prop="times" label="投屏次数 "/>
              <el-table-column prop="duration" label="投屏平均时长（s）"/>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import echarts from 'echarts'
import { objArrtransArr } from '@/utils/report'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      // 商家状态统计
      isLoadingShop: false,
      businessStatusList: [
        {
          name: '启用智慧道闸',
          cricle: 'backgroundColor: #409EFF',
          key: 'gate',
          current: '0',
          all: '0'
        },
        {
          name: '启用智慧投屏',
          cricle: 'backgroundColor: #67C23A',
          current: '0',
          key: 'screen',
          all: '0',
          status: 'success'
        },
        {
          name: '启用智慧WiFi',
          cricle: 'backgroundColor: #E6A23C',
          current: '0',
          all: '0',
          key: 'wifi',
          status: 'warning'
        },
        {
          name: '测试中商家',
          cricle: 'backgroundColor: #F56C6C',
          current: '0',
          all: '0',
          key: 'testing',
          status: 'exception'
        }
      ],

      // 商家分布统计
      isLoadingArea: false,

      // 车辆统计
      carData: {},
      isLoadingCar: false,

      statisticsList: [
        {
          lable: '进场车辆',
          tips: '',
          key: 'approach',
          unit: '辆'
        },
        {
          lable: '在场车辆',
          tips: '',
          key: 'present',
          unit: '辆'
        },
        {
          lable: '离场车辆',
          tips: '',
          key: 'departure',
          unit: '辆'
        },
        {
          lable: '奔驰车辆',
          tips: '',
          key: 'benz',
          unit: '辆'
        },
        {
          lable: '预约车辆',
          tips: '',
          key: 'appointment',
          unit: '辆'
        },
        {
          lable: '内部车辆',
          tips: '',
          key: 'internal',
          unit: '辆'
        }
      ],

      duration10TableData: [],
      time10TableData: []
    }
  },
  computed: {
    // 功能展示区
    functionalAreaList() {
      const arr = this.$store.state.common.sourceData || []
      return arr.filter(item => {
        return item.subtype === 'SCREEN_FUNCTION_AREA'
      })
    }
  },
  mounted() {
    // 车流量统计charts
    // this.trafficStatistics()
    // 门店流量排行
    // this.storeTrafficRanking()
    this.getPageData()
    // 投屏设备注册数据列表
    // this.registrationsNumber();
  },

  methods: {
    getPageData() {
      this.shopStatics()
      this.areaStatics()
      this.carStatics()
      this.sentryboxStatics()
      this.screenStatics()
      this.duration10Statics() // 投屏时长
      this.time10Statics() // 投屏次数
    },
    // 商家状态统计
    shopStatics() {
      this.isLoadingShop = true
      this.$util.get(this, {
        url: this.baseUrl + `/report/shop/status/statistics`,
        config: {},
        success: (res) => {
          if (res && res.succeed && res.data) {
            this.businessStatusList[0].current = res.data.gate
            this.businessStatusList[0].all = res.data.total
            this.businessStatusList[1].current = res.data.screen
            this.businessStatusList[1].all = res.data.total
            this.businessStatusList[2].current = res.data.wifi
            this.businessStatusList[2].all = res.data.total
            this.businessStatusList[3].current = res.data.testing
            this.businessStatusList[3].all = res.data.total
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isLoadingShop = false
        }
      })
    },

    // 经销商分布
    // /ismp/report/shop/area/statistics
    areaStatics() {
      this.isLoadingArea = true
      this.$util.get(this, {
        url: this.baseUrl + `/report/shop/area/statistics`,
        config: {},
        success: (res) => {
          if (res.succeed) {
            const data = objArrtransArr(res.data, 'count', 'area')
            // 经销商分布
            this.dealerDistribution(data)
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isLoadingArea = false
        }
      })
    },

    // 车辆统计
    // /ismp/report/car/statistics
    carStatics() {
      this.isLoadingCar = true
      this.$util.get(this, {
        url: this.baseUrl + `/report/car/statistics`,
        config: {},
        success: (res) => {
          if (res.succeed) {
            console.log('车辆统计分布', res.data)
            this.carData = res.data
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isLoadingCar = false
        }
      })
    },

    // 岗亭记录
    // /ismp/report/sentrybox/statistics
    sentryboxStatics() {
      this.isLoadingCar = true
      this.$util.get(this, {
        url: this.baseUrl + `/report/sentrybox/statistics`,
        config: {},
        success: (res) => {
          if (res.succeed) {
            const data = this.dealSentryData(res.data)
            // 港亭记录
            this.sentryRecord(data)
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isLoadingCar = false
        }
      })
    },

    // 处理岗亭数据为合理的数据格式
    dealSentryData(data) {
      const resData = {
        approachData: [],
        departureData: [],
        time: []
      }
      data.forEach((item) => {
        resData.approachData.push(item.approach)
        resData.departureData.push(item.departure)
        resData.time.push(item.time)
      })
      return resData
    },

    // 投屏次数统计
    screenStatics() {
      this.isLoadingCar = true
      this.$util.get(this, {
        url: this.baseUrl + `/report/screen/area/projection/statistics`,
        config: {},
        success: (res) => {
          if (res.succeed) {
            setTimeout(() => {
              const list = res.data.filter(item => !!item['functionalArea'])
              console.log(list, this.functionalAreaList)
              list.forEach(item => {
                if (item.functionalArea && item.functionalArea !== '') {
                  item.functionalArea = this.functionalAreaList.find(itemArea => itemArea.metadataId === Number(item.functionalArea))['name']
                }
              })
              this.registrationsNumber(list.map(item => item.functionalArea), list.map(item => item.times))
            }, 2000)
            // console.log('投屏记录', res.data);
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isLoadingCar = false
        }
      })
    },

    // 投屏经销商时长前十统计
    duration10Statics() {
      this.isLoadingCar = true
      this.$util.get(this, {
        url: this.baseUrl + `/report/screen/projection/duration10/statistics`,
        config: {},
        success: (res) => {
          if (res.succeed) {
            // console.log('投屏前十统计', res.data);
            // this.carData = res.data;
            this.duration10TableData = res.data
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isLoadingCar = false
        }
      })
    },
    // 投屏经销商次数前十统计
    time10Statics() {
      this.isLoadingCar = true
      this.$util.get(this, {
        url: this.baseUrl + `/report/screen/projection/time10/statistics`,
        config: {},
        success: (res) => {
          if (res.succeed) {
            this.time10TableData = res.data
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.isLoadingCar = false
        }
      })
    },

    trafficStatistics() {
      const myChart = echarts.init(
        document.getElementById('trafficStatistics')
      )
      const option = {
        title: {
          text: '本周客户车流量统计  1000次'
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            itemStyle: {
              normal: {
                color: function() {
                  return '#409EFF'
                }
              }
            },
            type: 'bar',
            barWidth: 20 // 柱图宽度
          }
        ]
      }
      option && myChart.setOption(option)
    },
    storeTrafficRanking() {
      const myChart = echarts.init(
        document.getElementById('storeTrafficRanking')
      )
      const option = {
        title: {
          text: '门店流量排行'
        },
        tooltip: {
          trigger: 'axis',
          show: true,
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar',
            itemStyle: {
              normal: {
                color: function() {
                  return '#F56C6C'
                }
              }
            },
            barWidth: 20 // 柱图宽度
          }
        ]
      }
      option && myChart.setOption(option)
    },
    // 投屏次数统
    registrationsNumber(label, data) {
      const myChart = echarts.init(
        document.getElementById('registrationsNumber')
      )
      const option = {
        title: {
          text: '投屏次数统计'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: label
        },
        yAxis: {
          type: 'value'
        },
        grid: {
          left: '3%',
          right: '4%',
          // bottom: '20%',
          containLabel: true
        },
        series: [
          {
            data: data,
            type: 'bar',
            showBackground: true,
            itemStyle: {
              normal: {
                color: '#74A0FB'
              }
            },
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }
      option && myChart.setOption(option)
    },

    // 经销商分布
    dealerDistribution(data) {
      const myChart = echarts.init(
        document.getElementById('dealerDistribution')
      )
      const option = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: '经销商分布'
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 20,
          top: 20,
          bottom: 20
        },
        series: [
          {
            // name: '经销商分布',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      }
      option && myChart.setOption(option)
    },
    // 岗亭记录
    sentryRecord(data) {
      const myChart = echarts.init(document.getElementById('sentryRecord'))
      const option = {
        title: {
          text: '港亭记录',
          x: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          bottom: 25
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '20%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: [0, 0.01],
          axisLabel: {
            rotate: 30 // 旋转30度
          },
          data: data.time
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '进场',
            type: 'bar',
            data: data.approachData
          },
          {
            name: '出场',
            type: 'bar',
            data: data.departureData
          }
        ]
      }
      option && myChart.setOption(option)
    }
  }
}
</script>

<style lang='less'>
.overview-data-box {
  padding: 0 20/192rem;
  > div {
    margin-bottom: 20/192rem;
  }
  .statistics-all {
    &-tips {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10/192rem;
    }
    &-num {
      .num {
        font-size: 30/192rem;
        font-weight: bold;
      }
    }
  }
  .statistics-echats {
    margin-top: 20/192rem;
    position: relative;
    .statistics-card {
      overflow-y: hidden;
      position: relative;
      height: 400px;
      &-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15/192rem;
        &-left {
          display: flex;
          align-items: center;
          .cricle {
            width: 15/192rem;
            height: 15/192rem;
            border-radius: 50%;
            margin-right: 5/192rem;
          }
        }
        &-right {
          &-num {
            font-size: 22/192rem;
            font-weight: bold;
          }
        }
      }
      // padding: 20/192rem;
      .statistics-card-title {
        font-size: 23/192rem;
        font-weight: bold;
      }
    }
    .statistics-card::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .traffic-statistics {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 400px;
      margin: 20/192rem;
    }
  }
}
</style>
