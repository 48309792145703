const pushMenuMap = (menus, data) => {
  menus.forEach(item => {
    data[item.rescourceCode] = item.name
    if (item.children && item.children.length) {
      pushMenuMap(item.children, data)
    }
  })
}
export default {
  state: () => ({
    menuList: [
      {
        name: '数据统计',
        resourceId: '0',
        icon: 'el-icon-pie-chart',
        children: [
          {
            name: '停车报告',
            resourceId: '0-3',
            rescourceCode: 'parkingReport',
            parentId: '0'
          },
          {
            name: '投屏报告',
            resourceId: '0-2',
            rescourceCode: 'screencastReport',
            parentId: '0'
          },
          {
            name: '数据看板',
            resourceId: '0-1',
            rescourceCode: 'overviewData',
            parentId: '0'
          }
          // {
          //   name: 'WIFI认证报告',
          //   resourceId: '0-4',
          //   rescourceCode: 'developing',
          //   parentId: '0'
          // }
        ]
      },
      {
        name: '道闸管理',
        resourceId: '1',
        icon: 'el-icon-coordinate',
        children: [
          {
            name: '外部车辆管理',
            resourceId: '1-1',
            parentId: '1',
            children: [
              {
                name: '车辆管理',
                resourceId: '1-1-1',
                rescourceCode: 'vehicleManager',
                parentId: '1-1'
              },
              {
                name: '放行审批',
                resourceId: '1-1-2',
                rescourceCode: 'releaseApproval',
                parentId: '1-1'
              },
              {
                name: '车辆接待',
                resourceId: '1-1-3',
                rescourceCode: 'vehicleReception',
                parentId: '1-1'
              },
              {
                name: '预约管理',
                resourceId: '1-1-4',
                rescourceCode: 'appointmentManagement',
                parentId: '1-1'
              }
            ]
          },
          {
            name: '内部车辆管理',
            resourceId: '1-2',
            parentId: '1',
            children: [
              {
                name: '内部车辆管理',
                resourceId: '1-2-1',
                rescourceCode: 'internalVehicleManagement',
                parentId: '1-2'
              },
              {
                name: '车辆类型配置',
                resourceId: '1-2-2',
                rescourceCode: 'vehicleTypeManage',
                parentId: '1-2'
              }
            ]
          },
          {
            name: '道闸日志管理',
            resourceId: '1-3',
            parentId: '1',
            children: [
              {
                name: '出入日志查询',
                resourceId: '1-3-1',
                rescourceCode: 'vehicleLogManagement',
                parentId: '1-3'
              }
            ]
          }
        ]
      },
      {
        name: '设备运维',
        resourceId: '2',
        icon: 'el-icon-s-platform',
        children: [
          {
            name: '智能投屏',
            resourceId: '2-1',
            rescourceCode: 'smartScreen',
            parentId: '2'
          },
          {
            name: '智能Wifi',
            resourceId: '2-2',
            rescourceCode: 'developing',
            parentId: '2'
          },
          {
            name: '智能道闸',
            resourceId: '2-3',
            rescourceCode: 'smartGate',
            parentId: '2'
          }
        ]
      },
      {
        name: '机构管理',
        resourceId: '3',
        icon: 'el-icon-office-building',
        children: [
          {
            name: '机构&门店',
            resourceId: '3-1',
            parentId: '3',
            children: [
              {
                name: '机构设置',
                resourceId: '3-1-1',
                rescourceCode: 'orgManager',
                parentId: '3-1'
              },
              {
                name: '门店管理',
                resourceId: '3-1-2',
                rescourceCode: 'shopManager',
                parentId: '3-1'
              }
            ]
          },
          {
            name: '账号角色',
            resourceId: '3-2',
            rescourceCode: 'developing',
            parentId: '3',
            children: [
              {
                name: '账号管理',
                resourceId: '3-2-1',
                rescourceCode: 'userManager',
                parentId: '3-2'
              },
              {
                name: '角色管理',
                resourceId: '3-2-2',
                rescourceCode: 'roleManager',
                parentId: '3-2'
              }
            ]
          }
        ]
      },
      {
        name: '系统设置',
        resourceId: '4',
        icon: 'el-icon-setting',
        children: [
          {
            name: '资料设置',
            resourceId: '4-1',
            rescourceCode: 'developing',
            parentId: '4'
          },
          {
            name: '系统通知',
            resourceId: '4-2',
            rescourceCode: 'developing',
            parentId: '4'
          },
          {
            name: '修改密码',
            resourceId: '4-3',
            rescourceCode: 'updatePwd',
            parentId: '4'
          },
          {
            name: '登录记录',
            resourceId: '4-4',
            rescourceCode: 'developing',
            parentId: '4'
          },
          {
            name: '版本管理',
            resourceId: '4-5',
            rescourceCode: 'versionManagement',
            parentId: '4'
          }
        ]
      },
      {
        name: '帮助中心',
        resourceId: '5',
        icon: 'el-icon-warning',
        children: [
          {
            name: 'APP下载',
            resourceId: '5-1',
            rescourceCode: 'appDownload',
            parentId: '4'
          }
        ]
      }
    ]
  }),
  mutations: {},
  actions: {},
  getters: {
    menuList(state) {
      return state.menuList || []
    },
    // 获取路由映射关系
    menuMap(state) {
      const resultMap = {}
      pushMenuMap(state.menuList, resultMap)
      return resultMap
    }
  }
}
