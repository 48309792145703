<template>
  <CommonPage :show-page="false" class="o r g-manager-box" @query="search" @reset="reset">
    <template slot="filters">
      <el-form ref="defaultForm" :model="filters" :rules="rules" inline label-width="90px">
        <!-- <el-form-item label="在场状态：" prop="status">
          <el-select v-model="filters.status" clearable placeholder="请选择在场状态">
            <el-option
              v-for="item in carStatuses"
              :key="item.code"
              :label="item.description"
              :value="item.code"/>
          </el-select>
        </el-form-item> -->
        <el-form-item label="车牌号：" prop="carPlateNumber">
          <el-input v-model="filters.carPlateNumber" placeholder="请输入车牌号/VIN"/>
        </el-form-item>
        <el-form-item label="日期：" prop="datetimerange">
          <el-date-picker
            v-model="filters.datetimerange"
            type="datetimerange"
            clearable
            start-placeholder="开始日期"
            end-placeholder="结束日期"/>
        </el-form-item>
        <!-- <el-form-item label="品牌：" prop="brand">
          <el-input v-model="filters.brand"/>
        </el-form-item>
        <el-form-item label="车系：" prop="carSeries">
          <el-input v-model="filters.serial"/>
        </el-form-item>
        <el-form-item label="车型：" prop="type">
          <el-input v-model="filters.type"/>
        </el-form-item>
        <el-form-item label="颜色：" prop="color">
          <el-select v-model="filters.color" clearable placeholder="请选择颜色">
            <el-option
              v-for="item in colors"
              :key="item"
              :label="item"
              :value="item"/>
          </el-select>
        </el-form-item> -->
      </el-form>
    </template>
    <template slot="btnOptions">
      <div>
        <Edit ref="edit" @refresh="queryPage(templateFilters)"/>
        <reception ref="reception" @refresh="queryPage(templateFilters)"/>
      </div>
    </template>
    <template slot="dataTable">
      <CommonTable
        :header-list="headerList"
        :table-data="tableData"
        :loading="loading"
        :pager="pager"
        show-selection
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
import Edit from './edit.vue'
import { mapActions } from 'vuex'
import reception from './reception.vue'
export default {
  components: { CommonPage, CommonTable, Edit, reception },
  data() {
    const that = this
    return {
      headerList: [
        { label: '车牌号/VIN', render: (h, data) => {
          return h('div', {}, data.row.carPlateNumber || data.row.carVinNumber)
        } },
        // { label: '在场状态',
        //   render: (h, data) => {
        //     return h('span', {}, that.carStatuses.find(item => item.code === data.row.status)['description'])
        //   }
        // },
        { label: '品牌', render: (h, data) => {
          return h('div', {}, data.row.carInfo ? data.row.carInfo.brand : '')
        } },
        { label: '车系', render: (h, data) => {
          return h('div', {}, data.row.carInfo ? data.row.carInfo.serial : '')
        } },
        { label: '颜色', render: (h, data) => {
          return h('span', {}, data.row.carInfo ? data.row.carInfo.color || '' : '')
        } },
        // { label: '是否接待',
        //   render: (h, data) => {
        //     return h('span', {}, data.row.status === 0 ? '停用' : '启用')
        //   }
        // },
        { label: '申请人', render: (h, data) => {
          return h('div', {}, data.row.createUserInfo.userName || '')
        } },
        { label: '申请时间', param: 'createTime' },
        { label: '入场时间', param: 'approachTime' },
        {
          label: '操作',
          width: '250px',
          render: (h, data) => {
            const actions = []
            if (data.row.flag === 'N') {
              if (Number(data.row.reviewer) === Number(localStorage.getItem('userId'))) {
                actions.push(h('span', {
                  on: {
                    click: () => {
                      this.reception(1, data.row)
                    }
                  },
                  class: 'table-text-btn'
                }, [
                  h('em', { class: 'el-icon-tickets' }),
                  '审批'
                ]))
              }
            } else {
              console.log(that.approvalFlags.filter(item => item.code === data.row.flag))
              actions.push(h('span', {}, [
                h('em'),
                that.approvalFlags.filter(item => item.code === data.row.flag)[0]['description']
              ]))
            }
            return h('div', {}, actions)
          }
        }
      ],
      tableData: [],
      loading: false,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filters: {
        carPlateNumber: '',
        datetimerange: [],
        brand: '',
        carSeries: '',
        type: '',
        color: '',
        status: ''
      },
      colors: that.$store.state.common.colors,
      rules: {},
      templateFilters: {}
    }
  },
  computed: {
    carStatuses() {
      return this.$store.state.common.constData.carStatuses || []
    },
    approvalFlags() {
      return this.$store.state.common.constData.approvalFlags || []
    }
  },
  created() {
    this.search()
    this.$bus.$on('gobalFresh', () => {
      this.reset()
    })
  },
  methods: {
    ...mapActions(['refreshOrg']),
    queryPage(requestParams) {
      this.loading = true
      console.log(requestParams)
      const params = this.$util.preProcessData(Object.assign({}, requestParams, {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        pageStart: 1,
        // dateFrom: this.filters.datetimerange && this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[0]) : '',
        // dateTo: this.filters.datetimerange && this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[1], 'yyyy-MM-dd 23:59:59') : '',
        createTimeFrom: this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[0], 'yyyy-MM-dd hh:mm:ss') : '',
        createTimeTo: this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[1], 'yyyy-MM-dd hh:mm:ss') : '',
        shopId: Number(localStorage.getItem('shopId'))
        // flag: 'N'
      }))
      this.$util.post(this, {
        url: this.baseUrl + '/audit/pageable',
        params,
        config: {},
        success: (res) => {
          this.templateFilters = this.$util.deepCopy(requestParams)
          this.pager.total = res.data.totalElements || 0
          this.tableData = res.data.content || []
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    search() {
      this.pager.currentPage = 1
      this.queryPage(this.filters)
    },
    reset() {
      this.$refs.defaultForm.resetFields()
      this.pager.currentPage = 1
      this.search()
    },
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage(this.templateFilters)
    },
    handleSizeChange(pageSize) {
      this.pager.pageSize = pageSize
      this.queryPage(this.templateFilters)
    },
    reception(type, datas = {}) {
      console.log(datas)
      this.$refs.reception.show(datas, type)
    },
    edit(type, datas = {}) {
      this.$refs.edit.show(datas, type)
    }
  }
}
</script>

<style lang="less">
  .org-manager-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 16/192rem 16/192rem 16/192rem;
  }
</style>
