export function objArrtransArr(olddata, oldval, oldname) {
  const newArr = []
  olddata.forEach((item) => {
    // 定义数组内部对象形式
    const obj = {}
    obj.value = item[oldval]
    obj.name = item[oldname]
    // 将对象数据推到数组中
    newArr.push(obj)
  })
  return newArr
}
