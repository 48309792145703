import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginAccount from '@/views/login'
import Login from '@/views/login/login'
import Main from '@/views/main'
import NotFound from '@/views/not-found'
import SmartScreen from '@/views/device-manager/smart-screen'

// 组织角色
import UserManager from '@/views/org-manager/account-role/user-manager' // 用户管理
import RoleManager from '@/views/org-manager/account-role/role-manager' // 角色管理
import ShopManager from '@/views/org-manager/org-shop/shop-manager' // 门店管理
import OrgManager from '@/views/org-manager/org-shop/org-manager' // 组织机构

import Developing from '@/views/developing' // 开发中

// 数据统计
import OverviewData from '@/views/statistics/overview-data' // 数据看板
import ScreencastReport from '@/views/statistics/screencast-report' // 投屏报告
import ParkingReport from '@/views/statistics/parking-report' // 停车报告

// 设备管理
import SmartGate from '@/views/device-manager/smart-gate' // 智能道闸

// 道闸管理
// 外部车辆管理
import VehicleManager from '@/views/barrier-manager/external-vehicle-management/vehicle-manage' // 车辆管理
import ReleaseApproval from '@/views/barrier-manager/external-vehicle-management/release-approval' // 放行审批
import VehicleReception from '@/views/barrier-manager/external-vehicle-management/vehicle-reception' // 车辆接待
import AppointmentManagement from '@/views/barrier-manager/external-vehicle-management/appointment-management' // 预约管理
// 内部车辆管理
import InternalVehicleManagement from '@/views/barrier-manager/internal-vehicle-management/internal-vehicle-management'
import VehicleTypeManage from '@/views/barrier-manager/internal-vehicle-management/vehicle-type-manage/index.vue'
import VehicleLogManagement from '@/views/barrier-manager/barrier-log-manager/vehicle-log-management'

// 版本管理
import VersionManagement from '@/views/system-manager/version-management'
// 修改密码
import UpdatePwd from '@/views/system-manager/update-pwd'

// 帮助中心
// app下载
import AppDownload from '@/views/help-center/app-download/index.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/loginAccount',
      name: 'loginAccount',
      component: LoginAccount
    },
    {
      path: '/',
      redirect: '/main/'
    },
    {
      path: '404',
      name: '404',
      component: NotFound
    },
    {
      name: 'main',
      path: '/main',
      component: Main,
      children: [
        {
          name: 'developing',
          path: '/developing',
          component: Developing
        },
        {
          name: 'overviewData',
          path: '/overviewData',
          component: OverviewData
        }, // 数据看板
        {
          name: 'screencastReport',
          path: '/screencastReport',
          component: ScreencastReport
        }, // 投屏报告
        {
          name: 'parkingReport',
          path: '/parkingReport',
          component: ParkingReport
        }, // 停车报告
        {
          name: 'vehicleManager',
          path: '/vehicleManager',
          component: VehicleManager
        }, // 车辆管理
        {
          name: 'releaseApproval',
          path: '/releaseApproval',
          component: ReleaseApproval
        }, // 放行审批
        {
          name: 'vehicleReception',
          path: '/vehicleReception',
          component: VehicleReception
        }, // 车辆接待
        {
          name: 'appointmentManagement',
          path: '/appointmentManagement',
          component: AppointmentManagement
        }, // 预约管理
        {
          name: 'internalVehicleManagement',
          path: '/internalVehicleManagement',
          component: InternalVehicleManagement
        }, // 内部车辆管理
        {
          name: 'vehicleTypeManage',
          path: '/vehicleTypeManage',
          component: VehicleTypeManage
        }, // 车辆类型配置
        {
          name: 'vehicleLogManagement',
          path: '/vehicleLogManagement',
          component: VehicleLogManagement
        }, // 出入日志管理
        {
          path: '/',
          name: 'smartScreen',
          component: SmartScreen
        }, // 投屏设备
        {
          name: 'smartGate',
          path: '/smartGate',
          component: SmartGate
        }, // 智能道闸
        {
          path: '/userManager',
          name: 'userManager',
          component: UserManager
        }, // 用户管理
        {
          path: '/roleManager',
          name: 'roleManager',
          component: RoleManager
        }, // 角色管理
        {
          path: '/shopManager',
          name: 'shopManager',
          component: ShopManager
        }, // 门店管理
        {
          path: '/orgManager',
          name: 'orgManager',
          component: OrgManager
        }, // 组织机构
        {
          path: '/updatePwd',
          name: 'updatePwd',
          component: UpdatePwd
        }, // 版本管理
        {
          path: '/versionManagement',
          name: 'versionManagement',
          component: VersionManagement
        }, // 帮助中心
        {
          path: '/appDownload',
          name: 'appDownload',
          component: AppDownload
        }
      ]
    }
  ]
})
const specPageMap = {
  LOGIN: 'login', // D8登录
  NOTFOUND: '404', // 404
  LOGINACCOUNT: 'loginAccount' // 账号密码登录
}
const specPage = [specPageMap.LOGIN, specPageMap.NOTFOUND, specPageMap.LOGINACCOUNT] // 特殊路由
router.beforeEach((to, from, next) => {
  if (to.name === 'appDownload') {
    next()
  } else {
    if (!to.name) {
      next({ name: specPageMap.NOTFOUND })
    } else if (!localStorage.getItem('token') && specPage.indexOf(to.name) < 0) {
      next({ name: specPageMap.LOGIN })
    } else if ([specPageMap.LOGIN, specPageMap.LOGINACCOUNT].indexOf(to.name) >= 0 && localStorage.getItem('token')) {
      next({ name: from.name })
    } else {
      next()
    }
  }
})
export default router
