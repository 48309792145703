<!-- 版本管理 -->
<template>
  <CommonPage :show-page="false" class="version-management-box" @query="search" @reset="reset">
    <template slot="filters">
      <el-form ref="defaultForm" :model="filters" :rules="rules" inline label-width="90px">
        <el-form-item label="版本类型：" prop="versionType">
          <el-select v-model="filters.versionType" clearable placeholder="请选择版本">
            <el-option
              v-for="item in versionTypes"
              :key="item.code"
              :label="item.description"
              :value="item.code" />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <template slot="btnOptions">
      <div>
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="editVersion(1)">新增版本</el-button>
        <EditVersion ref="editVersion" @refresh="queryPage(templateFilters)"/>
      </div>
    </template>
    <template slot="dataTable">
      <CommonTable
        :header-list="headerList"
        :table-data="tableData"
        :loading="loading"
        :pager="pager"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
import EditVersion from './edit-version.vue'
import { mapActions } from 'vuex'
export default {
  components: { CommonPage, CommonTable, EditVersion },
  data() {
    const that = this
    return {
      headerList: [
        { label: '版本类型', render: (h, data) => {
          return h('span', {}, that.versionTypes.filter(item => item.code === data.row.versionType)[0].description)
        } },
        { label: '创建人', param: 'createBy' },
        { label: '创建时间', param: 'createTime' },
        { label: '链接', param: 'versionFile', render: (h, data) => {
          return h('div', {
            on: {
              click: () => {
                this.copyDownLoad(data.row)
              }
            },
            class: 'tag-read',
            style: 'cursor: pointer'
          }, data.row.versionFile)
        } },
        { label: '备注', param: 'remark' },
        { label: '状态', render: (h, data) => {
          return h('span', {}, data.row.status === 0 ? '停用' : '启用')
        } }
        // {
        //   label: '操作',
        //   width: '200px',
        //   render: (h, data) => {
        //     return h('div', {}, [
        //       h('span', {
        //         on: {
        //           click: () => {
        //             this.editVersion(2, data.row)
        //           }
        //         },
        //         class: 'table-text-btn'
        //       }, [
        //         h('em', { class: 'el-icon-edit' }),
        //         '编辑'
        //       ]),
        //       h('span', {
        //         on: {
        //           click: () => {
        //             this.deleteOrg(data.row)
        //           }
        //         },
        //         class: 'table-text-btn'
        //       }, [
        //         h('em', { class: 'el-icon-delete' }),
        //         '删除'
        //       ])
        //     ])
        //   }
        // }
      ],
      tableData: [],
      loading: false,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filters: {
        versionType: ''
      },
      rules: {},
      templateFilters: {}
    }
  },
  computed: {
    versionTypes() {
      return this.$store.state.common.constData.versionTypes || []
    }
  },
  created() {
    this.search()
  },
  methods: {
    ...mapActions(['refreshOrg']),
    queryPage(requestParams) {
      this.loading = true
      const params = this.$util.preProcessData(Object.assign({}, requestParams, {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        pageStart: 1
      }))
      this.$util.post(this, {
        url: this.baseUrl + '/version/find/pageable',
        params,
        config: {},
        success: (res) => {
          this.templateFilters = this.$util.deepCopy(requestParams)
          this.pager.total = res.data.totalElements || 0
          this.tableData = res.data.content || []
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    search() {
      this.pager.currentPage = 1
      this.queryPage(this.filters)
    },
    reset() {
      this.$refs.defaultForm.resetFields()
      this.pager.currentPage = 1
      this.search()
    },
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage(this.templateFilters)
    },
    handleSizeChange(pageSize) {
      this.pager.pageSize = pageSize
      this.queryPage(this.templateFilters)
    },
    editVersion(type, datas = {}) {
      this.$refs.editVersion.show(datas, type)
    },
    deleteOrg(item) {
      this.$confirm(`是否确认删除组织-${item.organizationName}`, '组织删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$util.delete(this, {
          url: this.baseUrl + `/organization/${item.organizationId}/delete`,
          config: null,
          success: () => {
            this.$message.success('组织删除成功')
            this.refreshOrg(this)
            this.queryPage(this.templateParams)
          }
        })
      }).catch(() => {
        // 取消的提示
      })
    },
    // 复制下载链接
    copyDownLoad(data) {
      var copyInput = document.createElement('input')
      copyInput.setAttribute('value', data.versionFile)
      document.body.appendChild(copyInput)
      copyInput.select()
      try {
        var copyed = document.execCommand('copy')
        if (copyed) {
          document.body.removeChild(copyInput)
          this.$message.success('复制成功')
        }
      } catch {
        this.$message.warning('该浏览器不支持复制')
      }
    }
  }
}
</script>

<style lang="less">
  .version-management-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 16/192rem 16/192rem 16/192rem;
  }
</style>
