export default {
  state: () => ({
    shopId: localStorage.getItem('shopId') ? Number(localStorage.getItem('shopId')) : 0

  }),
  mutations: {
    setShopId(state, shopId) {
      state.shopId = shopId
      localStorage.setItem('shopId', Number(shopId))
    }
  },
  actions: {},
  getters: {

  }
}
