<template>
  <el-dialog :title="type === 1 ? '新增组织' : '编辑组织'" :visible="visible" :close-on-click-modal="false" class="form-inline-edit" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item label="车牌号/VIN：" prop="plateNumber">
        <el-input v-model="datas.plateNumber" placeholder="请输入车牌号/VIN" disabled/>
      </el-form-item>
      <el-form-item label="车牌类型：" prop="plateTyep">
        <el-radio-group v-model="datas.plateTyep">
          <el-radio label="1">男</el-radio>
          <el-radio label="0">女</el-radio>
          <el-radio label="2">保密</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from '@/gobal/lib/validate.js'
import { BASE_EDIT_FORM } from './index-config'
export default {
  data() {
    return {
      visible: false,
      type: 1,
      loading: false,
      datas: {
        plateNumber: '',
        platetype: ''
      },
      rules: {
        plateNumber: [{ required: true, message: '请输入车牌号/VIN', trigger: 'blur' }],
        platetype: [{ required: true, message: '请输入车牌号/VIN', trigger: 'change' }]
      },
      version: 0
    }
  },
  computed: {},
  methods: {
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const url = this.baseUrl + '/organization/' + (this.type === 1 ? 'create' : 'update')
          const customParams = {}
          if (this.type === 2) {
            customParams.version = this.version
          }
          const params = Object.assign({}, this.datas, customParams)
          if (this.type === 1) {
            delete params.userId
          } else {
            delete params.userPassword
            delete params.roleIds
            delete params.shopIds
          }
          const requestType = this.type === 1 ? 'post' : 'put'
          this.$util[requestType](this, {
            url,
            params,
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.$message.success((this.type === 1 ? '新增' : '修改') + '成功')
                this.$emit('refresh')
                this.cancel()
              }
            },
            complete: () => {
              this.loading = false
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    show(data, type) {
      this.type = type
      if (type !== 1) {
        this.datas = Object.assign({}, BASE_EDIT_FORM, data)
        this.version = data.version
      }
      this.visible = true
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas = Object.assign({}, BASE_EDIT_FORM, {})
      this.version = 0
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">

</style>
