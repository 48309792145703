<template>
  <CommonPage :show-page="false" class="o r g-manager-box" @query="search" @reset="reset">
    <template slot="filters">
      <el-form ref="defaultForm" :model="filters" :rules="rules" inline label-width="90px">
        <el-form-item label="在场状态：" prop="status">
          <el-select v-model="filters.status" clearable placeholder="请选择在场状态">
            <el-option
              v-for="item in carStatuses"
              :key="item.code"
              :label="item.description"
              :value="item.code" />
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号：" prop="carPlateNumber">
          <el-input v-model="filters.carPlateNumber" placeholder="请输入车牌号"/>
        </el-form-item>
      </el-form>
    </template>
    <template slot="btnOptions">
      <div>
        <el-button v-if="isShowBotton('1-2-1-1')" type="primary" size="medium" icon="el-icon-plus" @click="edit(1, {})">新建车辆</el-button>
        <Edit ref="edit" @refresh="queryPage(templateFilters)"/>
        <reception ref="reception" @refresh="queryPage(templateFilters)" />
      </div>
    </template>
    <template slot="dataTable">
      <CommonTable
        :header-list="headerList"
        :table-data="tableData"
        :loading="loading"
        :pager="pager"
        show-index
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
import Edit from './edit.vue'
import { mapActions } from 'vuex'
import reception from './reception.vue'
import util from '@/utils/util.js'
export default {
  components: { CommonPage, CommonTable, Edit, reception },
  data() {
    const that = this
    return {
      headerList: [
        {
          label: '经销商', render: (h) => {
            return h('span', {}, localStorage.getItem('shopName'))
          }
        },
        {
          label: '车牌/VIN',
          render: (h, data) => {
            return h('span', {}, data.row.carPlateNumber ? data.row.carPlateNumber : data.row.carVinNumber)
          }
        },
        { label: '进场时间', param: 'approachTime' },
        { label: '出场时间', param: 'departureTime' },
        {
          label: '品牌 车系',
          render: (h, data) => {
            const text = (data.row.brand || '') + ' ' + (data.row.serial || '')
            return h('span', {}, text)
          }
        },
        // { label: '是否接待',
        //   render: (h, data) => {
        //     return h('span', {}, data.row.status === 0 ? '停用' : '启用')
        //   }
        // },
        { label: '颜色', param: 'color' },
        { label: '类型',
          render: (h, data) => {
            return h('span', {}, data.row.carCategory ? that.carCategoryList.find(item => item.code === data.row.carCategory) && that.carCategoryList.find(item => item.code === data.row.carCategory)['description'] : '')
          }
        },
        // { label: '申请者', param: 'organizationType' },
        // { label: '审批者', param: 'organizationType' },
        { label: '在场状态',
          render: (h, data) => {
            return h('span', {}, that.carStatuses.find(item => item.code === data.row.status) && that.carStatuses.find(item => item.code === data.row.status)['description'])
          }
        },
        { label: '备注', param: 'remark' },
        {
          label: '操作',
          width: '250px',
          render: (h, data) => {
            const res = []
            if (util.isShowBotton('1-2-1-2', this)) {
              res.push(h('span', {
                on: {
                  click: () => {
                    this.edit(2, data.row)
                  }
                },
                class: 'table-text-btn'
              }, [
                h('em', { class: 'el-icon-edit' }),
                '编辑'
              ]))
            }
            return h('div', {}, res)
          }
        }
      ],
      tableData: [],
      loading: false,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filters: {
        status: '',
        carPlateNumber: ''
      },
      options: [{
        label: 'test',
        value: 1
      }],
      rules: {},
      templateFilters: {}
    }
  },
  computed: {
    carStatuses() {
      return this.$store.state.common.constData.carStatuses || []
    },
    carCategoryList() {
      return this.$store.state.common.constData.carSources[1].carCategories || []
    }
  },
  created() {
    this.search()
    this.$bus.$on('gobalFresh', () => {
      this.reset()
    })
  },
  methods: {
    ...mapActions(['refreshOrg']),
    isShowBotton(key) {
      return util.isShowBotton(key, this)
    },
    queryPage(requestParams) {
      this.loading = true
      const customParams = {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        sortField: 'createTime',
        pageStart: 1,
        shopId: Number(localStorage.getItem('shopId')),
        carSource: 'INTERNAL'
      }
      const params = this.$util.preProcessData(Object.assign({}, requestParams, customParams))
      const url = this.baseUrl + '/car/pageable'
      this.$util['post'](this, {
        url,
        params,
        config: {},
        success: (res) => {
          this.templateFilters = this.$util.deepCopy(requestParams)
          this.pager.total = res.data.totalElements || 0
          this.tableData = res.data.content || []
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    search() {
      this.pager.currentPage = 1
      this.queryPage(this.filters)
    },
    reset() {
      this.$refs.defaultForm.resetFields()
      this.pager.currentPage = 1
      this.search()
    },
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage(this.templateFilters)
    },
    handleSizeChange(pageSize) {
      this.pager.pageSize = pageSize
      this.queryPage(this.templateFilters)
    },
    reception(type, datas = {}) {
      this.$refs.reception.show(datas, type)
    },
    edit(type, datas = {}) {
      this.$refs.edit.show(datas, type)
    },
    deleteApprove(item) {
      this.$confirm(`是否确认删除`, '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$util.delete(this, {
        //   url: this.baseUrl + `/organization/${item.organizationId}/delete`,
        //   config: null,
        //   success: () => {
        //     this.$message.success('接待删除成功')
        //     this.refreshOrg(this)
        //     this.queryPage(this.templateParams)
        //   }
        // })
      }).catch(() => {
        // 取消的提示
      })
    }
  }
}
</script>

<style lang="less">
  .org-manager-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 16/192rem 16/192rem 16/192rem;
  }
</style>
