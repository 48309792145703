<template>
  <div class="developing-box">
    <em class="el-icon-office-building"/>
    <div class="tip-text">功能建设中...</div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.developing-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 20px;
  color: #777777;
  em {
    font-size: 200px;
  }
  .tip-text {
    width: 100%;
    margin-top: 20px;
    height: 50px;
    text-align: center;
  }
}
</style>
