<template>
  <div class="update-pwd-box">
    <div class="update-pwd-content">
      <el-form ref="defaultForm" :model="form" :rules="rules" label-width="95px">
        <el-form-item label="原密码：" prop="oldPassword">
          <el-input v-model="form.oldPassword" type="password" placeholder="请输入原密码"/>
        </el-form-item>
        <el-form-item label="新密码：" prop="newPassword">
          <el-input v-model="form.newPassword" type="password" placeholder="请输入新密码"/>
        </el-form-item>
        <el-form-item label="确认密码：" prop="ensurePwd">
          <el-input v-model="form.ensurePwd" type="password" placeholder="请输入新密码"/>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" @click="save">保 存</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        ensurePwd: ''
      },
      rules: {
        oldPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        ensurePwd: [{ required: true, message: '请确认新密码', trigger: 'blur' },
          { required: true, validator: // 验证用户名
            (rule, value, callback) => {
              if (value && this.form.newPassword !== value) {
                callback(new Error('两次密码输入不一致'))
              } else {
                callback()
              }
            }, trigger: 'blur' }]
      }
    }
  },
  methods: {
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          this.$util.put(this, {
            url: this.baseUrl + '/user/setPassword',
            params: {
              oldPassword: this.form.oldPassword,
              newPassword: this.form.newPassword
            }, success: () => {
              this.$message.success('修改密码成功')
              this.$refs.defaultForm.resetFields()
              this.$util.logOut(this)
              this.loading = false
            },
            complete: () => {
              this.loading = false
            }
          })
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .update-pwd-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .update-pwd-content {
      width: 400px;
      height: 400px;
      text-align: center;
      box-sizing: border-box;
      padding: 20px;
    }
  }
</style>
