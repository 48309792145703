<template>
  <CommonPage :show-page="false" class="o r g-manager-box" @query="search" @reset="reset">
    <template slot="filters">
      <el-form ref="defaultForm" :model="filters" :rules="rules" inline label-width="90px">
        <el-form-item label="日期：" prop="datetimerange">
          <el-date-picker
            v-model="filters.datetimerange"
            type="datetimerange"
            clearable
            start-placeholder="开始日期"
            end-placeholder="结束日期"/>
        </el-form-item>
        <el-form-item label="客户姓名：" prop="customerName">
          <el-input v-model="filters.customerName" placeholder="请输入客户姓名"/>
        </el-form-item>
      </el-form>
    </template>
    <template slot="btnOptions">
      <div>
        <Edit ref="edit" @refresh="queryPage(templateFilters)"/>
        <reception ref="reception" @refresh="queryPage(templateFilters)"/>
      </div>
    </template>
    <template slot="dataTable">
      <CommonTable
        :header-list="headerList"
        :table-data="tableData"
        :loading="loading"
        :pager="pager"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
import Edit from './edit.vue'
import { mapActions } from 'vuex'
import reception from './reception.vue'
export default {
  components: { CommonPage, CommonTable, Edit, reception },
  data() {
    const that = this
    return {
      headerList: [
        { label: '经销商', render: (h) => {
          return h('span', {}, localStorage.getItem('shopName'))
        } },
        { label: '车牌', param: 'carPlateNumber' },
        { label: '姓名', param: 'customerName' },
        { label: '性别', param: 'gender' },
        { label: '手机号', param: 'customerPhone' },
        // { label: '是否接待',
        //   render: (h, data) => {
        //     return h('span', {}, data.row.status === 0 ? '停用' : '启用')
        //   }
        // },
        // { label: '预约日期', param: 'organizationType' },
        { label: '预约时间', param: 'bookTimeFrom' },
        // { label: '入场状态', param: 'organizationType' },
        { label: '在场状态',
          render: (h, data) => {
            return h('span', {}, that.carStatuses.find(item => item.code === data.row.status)['description'])
          }
        },
        { label: '备注', param: 'remark' }
        // {
        //   label: '操作',
        //   width: '250px',
        //   render: (h, data) => {
        //     return h('div', {}, [
        //       h('span', {
        //         on: {
        //           click: () => {
        //             this.edit(2, data.row)
        //           }
        //         },
        //         class: 'table-text-btn'
        //       }, [
        //         h('em', { class: 'el-icon-edit' }),
        //         '编辑'
        //       ]),
        //       h('span', {
        //         on: {
        //           click: () => {
        //             this.deleteApprove(data.row)
        //           }
        //         },
        //         class: 'table-text-btn'
        //       }, [
        //         h('em', { class: 'el-icon-delete' }),
        //         '删除'
        //       ])
        //     ])
        //   }
        // }
      ],
      tableData: [],
      loading: false,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filters: {
        datetimerange: [],
        customerName: ''
      },
      rules: {},
      templateFilters: {}
    }
  },
  computed: {
    carStatuses() {
      return this.$store.state.common.constData.carStatuses || []
    }
  },
  created() {
    this.search()
    this.$bus.$on('gobalFresh', () => {
      this.reset()
    })
  },
  methods: {
    ...mapActions(['refreshOrg']),
    queryPage(requestParams) {
      this.loading = true
      const customParams = {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        dateFrom: this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[0], 'yyyy-MM-dd hh:mm:ss') : '',
        dateTo: this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[1], 'yyyy-MM-dd hh:mm:ss') : '',
        // dateFrom: this.filters.datetimerange && this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[0]) : '',
        // dateTo: this.filters.datetimerange && this.filters.datetimerange.length > 0 ? this.$util.dateFormat(requestParams.datetimerange[1], 'yyyy-MM-dd 23:59:59') : '',
        pageStart: 1,
        shopId: Number(localStorage.getItem('shopId'))
      }
      const params = this.$util.preProcessData(Object.assign({}, requestParams, customParams))

      this.$util.post(this, {
        url: this.baseUrl + '/car/appointment/pageable',
        params,
        config: {},
        success: (res) => {
          this.templateFilters = this.$util.deepCopy(requestParams)
          this.pager.total = res.data.totalElements || 0
          this.tableData = res.data.content || []
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    search() {
      this.pager.currentPage = 1
      this.queryPage(this.filters)
    },
    reset() {
      this.$refs.defaultForm.resetFields()
      this.pager.currentPage = 1
      this.search()
    },
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage(this.templateFilters)
    },
    handleSizeChange(pageSize) {
      this.pager.pageSize = pageSize
      this.queryPage(this.templateFilters)
    },
    reception(type, datas = {}) {
      this.$refs.reception.show(datas, type)
    },
    edit(type, datas = {}) {
      this.$refs.edit.show(datas, type)
    },
    deleteApprove(item) {
      this.$confirm(`是否确认删除预约`, '预约删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$util.delete(this, {
        //   url: this.baseUrl + `/organization/${item.organizationId}/delete`,
        //   config: null,
        //   success: () => {
        //     this.$message.success('接待删除成功')
        //     this.refreshOrg(this)
        //     this.queryPage(this.templateParams)
        //   }
        // })
      }).catch(() => {
        // 取消的提示
      })
    }
  }
}
</script>

<style lang="less">
  .org-manager-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 16/192rem 16/192rem 16/192rem;
  }
</style>
