<template>
  <CommonPage :show-page="false" :loading="loading" class="role-manager-box" @query="search" @reset="reset">
    <template slot="filters">
      <el-form ref="defaultForm" :model="filters" :rules="rules" inline>
        <el-form-item label="角色名称：" prop="roleName">
          <el-input v-model="filters.roleName" placeholder="请输入角色名称"/>
        </el-form-item>
        <el-form-item label="角色编码：" prop="roleCode">
          <el-input v-model="filters.roleCode" placeholder="请输入角色编码"/>
        </el-form-item>
      </el-form>
    </template>
    <template slot="btnOptions">
      <div>
        <el-button type="primary" @click="editRole(1)">新增角色</el-button>
        <EditRole ref="editRole" @refresh="queryPage(templateParams)" />
      </div>
    </template>
    <template slot="dataTable">
      <CommonTable
        :header-list="headerList"
        :table-data="tableData"
        :loading="loading"
        :pager="pager"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"/>
    </template>
  </CommonPage>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
import EditRole from './edit-role.vue'
export default {
  components: { CommonPage, CommonTable, EditRole },
  data() {
    return {
      // table头部
      headerList: [
        {
          label: '角色名称',
          param: 'roleName'
        },
        {
          label: '角色编码',
          param: 'roleCode'
        },
        {
          label: '角色状态',
          render: (h, data) => {
            return h('span', {}, data.row.status === 0 ? '停用' : '启用')
          }
        },
        {
          label: '角色类型',
          param: 'roleType'
        },
        {
          label: '操作',
          width: '200px',
          render: (h, data) => {
            return h('div', {}, [
              h('span', {
                on: {
                  click: () => {
                    this.editRole(2, data.row)
                  }
                },
                class: 'table-text-btn'
              }, [
                h('em', { class: 'el-icon-edit' }),
                '编辑'
              ]),
              h('span', {
                on: {
                  click: () => {
                    this.deleteRole(data.row)
                  }
                },
                class: 'table-text-btn'
              }, [
                h('em', { class: 'el-icon-delete' }),
                '删除'
              ])
            ])
          }
        }
      ],
      tableData: [],
      loading: false,
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filters: {
        roleName: '',
        roleCode: ''
      },
      rules: {},
      templateParams: {} // 临时请求参数
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    /**
     * 分页数据检索
     * @param filters 检索条件
     */
    queryPage(filters) {
      this.loading = true
      const params = Object.assign({}, {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        pageStart: 1
      }, filters)
      this.$util.post(this, {
        url: this.baseUrl + '/authority/role/pageable',
        params,
        config: {},
        success: (res) => {
          this.templateParams = params
          this.pager.total = res.data.totalElements || 0
          this.tableData = res.data.content || []
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    // 查询
    search() {
      this.pager.currentPage = 1
      const params = {
        roleName: this.filters.roleName || undefined,
        roleCode: this.filters.roleCode || undefined
      }
      this.queryPage(params)
    },
    // 重置
    reset() {
      this.$refs.defaultForm.resetFields()
      this.pager.currentPage = 1
      this.pager.pageSize = 10
      this.search()
    },
    // 翻页
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage()
    },
    // 分页显示数量改变
    handleSizeChange(pageSize) {
      this.pager.currentPage = 1
      this.pager.pageSize = pageSize
      this.queryPage()
    },
    /**
     * 编辑角色
     * @param type 1 新增 2 编辑
     * @param datas 数据对象 新增不用🚢
     */
    editRole(type, datas = {}) {
      this.$refs.editRole.show(datas, type)
    },
    // 删除角色
    deleteRole(item) {
      this.$confirm(`是否确认删除角色-${item.roleName}`, '角色删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$util.delete(this, {
          url: this.baseUrl + `/authority/role/${item.roleId}/delete`,
          config: null,
          success: () => {
            this.$message.success('角色删除成功')
            this.queryPage(this.templateParams)
          }
        })
      }).catch(() => {
        // 取消的提示
      })
    }
  }
}
</script>

<style lang="less">
  .role-manager-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding:0 20/192rem 20/192rem 20/192rem;
  }
</style>
