<template>
  <el-dialog :title="type === 1 ? '新增用户' : '编辑用户'" :visible="visible" :close-on-click-modal="false" class="form-inline-edit" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item label="用户编码：" prop="userCode">
        <el-input v-model="datas.userCode" :disabled="2 === type" placeholder="请输入用户编码"/>
      </el-form-item>
      <el-form-item label="用户名称：" prop="userName">
        <el-input v-model="datas.userName" placeholder="请输入用户名称"/>
      </el-form-item>
      <el-form-item v-if="type== 1" label="用户密码：" prop="userPassword">
        <el-input v-model="datas.userPassword" type="password" placeholder="请输入用户密码"/>
      </el-form-item>
      <el-form-item label="用户类型：" prop="userType">
        <el-select v-model="datas.userType" multiple placeholder="请选择用户类型">
          <el-option v-for="(item, index) in userTypeList" :key="index" :label="item.description" :value="item.code"> {{ item.description }} </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属角色：" prop="roleIds">
        <el-select v-model="datas.roleIds" multiple placeholder="请选择角色">
          <el-option v-for="(item, index) in roleList" :key="index" :label="item.roleName" :value="item.roleId"> {{ item.roleName }} </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属经销商：" prop="shopIds">
        <el-select v-model="datas.shopIds" multiple placeholder="请选择经销商">
          <el-option v-for="(item, index) in shopList" :key="index" :label="item.shopName" :value="item.shopId"> {{ item.shopName }} </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="性别：" prop="userName">
        <el-radio-group v-model="datas.userGender">
          <el-radio label="1" value="1">男</el-radio>
          <el-radio label="2" value="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="联系电话：" prop="userPhone">
        <el-input v-model="datas.userPhone" placeholder="请输入联系电话"/>
      </el-form-item>
      <el-form-item label="邮箱：" prop="userEmail">
        <el-input v-model="datas.userEmail" placeholder="请输入邮箱"/>
      </el-form-item>
      <el-form-item label="地址：" prop="userAddr">
        <el-input v-model="datas.userAddr" placeholder="请输入地址"/>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="datas.remark" placeholder="请输入备注"/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from '@/gobal/lib/validate.js'
import { BASE_EDIT_FORM } from './index-config'
export default {
  data() {
    return {
      visible: false,
      type: 1,
      loading: false,
      datas: {
        userName: '',
        userPassword: '',
        userCode: '',
        roleIds: [],
        shopIds: [],
        userAddr: '',
        userGender: '1',
        userEmail: '',
        userPhone: '',
        userId: '',
        userType: '',
        remark: ''
      },
      rules: {
        userName: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
        userCode: [
          { required: true, message: '请输入用户编码', trigger: 'blur' },
          { required: false, validator: validate.validateUserName, trigger: 'blur' }
        ],
        userPassword: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        roleIds: [{ required: true, message: '请选择角色', trigger: 'change', type: 'array' }],
        shopIds: [{ required: true, message: '请选择经销商', trigger: 'change', type: 'array' }],
        userType: [{ required: true, message: '请选择用户类型', trigger: 'change', type: 'array' }],
        userPhone: [{ required: false, validator: validate.validatePhone, trigger: 'blur' }],
        userEmail: [{ required: false, validator: validate.validateEmail, trigger: 'blur' }]
      },
      version: 0
    }
  },
  computed: {
    roleList() {
      return this.$store.state.common.roleList || []
    },
    shopList() {
      return this.$store.state.common.shopList || []
    },
    userTypeList() {
      return this.$store.state.common.constData.userTypes || []
    }
  },
  methods: {
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const url = this.baseUrl + '/user/' + (this.type === 1 ? 'create' : 'update')
          const customParams = {}
          if (this.type === 2) {
            customParams.version = this.version
          }
          const params = Object.assign({}, this.datas, customParams)
          if (this.type === 1) {
            delete params.userId
          } else {
            delete params.userPassword
          }
          params.userType = params.userType.join(',')
          this.$util.post(this, {
            url,
            params,
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.$message.success((this.type === 1 ? '新增' : '修改') + '用户成功')
                this.$emit('refresh')
                this.cancel()
              }
            },
            complete: () => {
              this.loading = false
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    show(data, type) {
      this.type = type
      if (type !== 1) {
        this.datas = Object.assign({}, BASE_EDIT_FORM, data)
        if (data.userType) {
          this.datas.userType = data.userType.split(',')
        }
        this.$util.get(this, {
          url: this.baseUrl + `/user/${data.userId}/join/find`,
          params: {},
          success: (res) => {
            if (res.succeed) {
              const roles = []
              const shops = [];
              (res.data.userRoleInfos || []).forEach(item => {
                roles.push(item.roleId)
              });
              (res.data.userShopInfos || []).forEach(item => {
                shops.push(item.shopId)
              })
              this.datas.roleIds = roles
              this.datas.shopIds = shops
            }
          }
        })
        this.version = data.version
      }
      this.visible = true
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas = Object.assign({}, BASE_EDIT_FORM, {})
      this.version = 0
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">

</style>
