<template>
  <el-dialog :visible="visible" :close-on-click-modal="false" title="查看日志" width="55%" @close="cancel">
    <div class="device-log-box">
      <CommonPage :show-page="false" :show-options="false" @query="search" @reset="reset">
        <template slot="filters">
          <el-form :model="filters" inline>
            <el-form-item label="日期筛选">
              <el-date-picker v-model="filters.date" type="datetimerange" placeholder="选择日期"/>
            </el-form-item>
          </el-form>
        </template>
        <template slot="dataTable">
          <CommonTable
            :header-list="headerList"
            :show-selection="false"
            :table-data="tableData"
            :loading="loading"
            :pager="pager"
            :show-index="false"
            @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"/>
        </template>
      </CommonPage>
    </div>
  </el-dialog>
</template>

<script>
import CommonPage from '@/components/common-page'
import CommonTable from '@/components/common-table'
export default {
  components: {
    CommonPage, CommonTable
  },
  data() {
    return {
      visible: false,
      loading: false,
      filters: {
        date: [],
        screenId: ''
      },
      tableData: [],
      headerList: [
        {
          label: '使用人',
          param: 'updateBy'
        },
        {
          label: '使用情况',
          render: (h, data) => {
            return h('span', {}, data.row.flag === 'Y' ? '投屏中' : '未投屏')
          }
        },
        {
          label: '开始时间',
          param: 'startTime'
        },
        {
          label: '结束时间',
          param: 'endTime'
        },
        {
          label: '时常（分）',
          param: 'long',
          render: (h, data) => {
            let result = 0
            if (data.row.endTime) {
              result = (this.$util.formatDate(data.row.endTime) - this.$util.formatDate(data.row.startTime)) / 1000 / 60
            }
            return h('span', {}, result.toFixed(2))
          }
        },
        {
          label: '投屏码',
          param: 'projectionCode'
        },
        {
          label: '设备IP',
          param: 'screenIp'
        }
      ],
      pager: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      templateFilters: {}
    }
  },
  methods: {
    show(item) {
      this.filters.screenId = item.screenId
      this.visible = true
      this.search()
    },
    cancel() {
      this.visible = false
    },
    handleCurrentChange(current) {
      this.pager.currentPage = current
      this.queryPage(this.templateFilters)
    },
    handleSizeChange(pageSize) {
      this.pager.pageSize = pageSize
      this.queryPage(this.templateFilters)
    },
    search() {
      this.currentPage = 1
      const filters = {
        shopId: localStorage.getItem('shopId'),
        screenId: this.filters.screenId
      }
      if (this.filters.date && this.filters.date[0]) {
        filters.periodFrom = this.filters.date[0].getTime()
        filters.periodTo = this.filters.date[1].getTime()
      }
      this.queryPage(filters)
    },
    queryPage(filters) {
      this.loading = true
      const params = Object.assign({}, {
        perPage: this.pager.pageSize,
        page: this.pager.currentPage,
        pageStart: 1
      }, filters)
      this.$util.post(this, {
        url: this.baseUrl + '/screen/bo/projection/find/pageable',
        params,
        config: {},
        success: (res) => {
          this.templateFilters = params
          this.pager.total = res.data.totalElements || 0
          this.tableData = res.data.content || []
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    reset() {
      this.filters.date = []
      this.pager.currentPage = 1
      this.search()
    }
  }
}
</script>

<style lang="less">
  .device-log-box {
    width: 100%;
    height: 500px;
  }
</style>
