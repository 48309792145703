<template>
  <el-dialog :visible="visible" :close-on-click-modal="false" width="500px" title="添加投屏设备" @close="cancel">
    <el-form ref="defaultForm" :model="defaultForm" :rules="rules" label-width="100px">
      <el-form-item :rules="rules" label="所属门店：">
        {{ defaultForm.shopName }}
      </el-form-item>
      <el-form-item label="设备：" prop="screenId">
        <el-select v-model="defaultForm.screenId" filterable placeholder="请选择设备">
          <el-option v-for="(item, index) in deviceList" :label="item.screenCode" :value="item.screenId" :key="index">
            <el-row style="width: 350px">
              <el-col :span="12">{{ item.screenCode }}</el-col>
              <el-col :span="12">{{ item.updateTime }}</el-col>
            </el-row>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      defaultForm: {
        shopId: '',
        shopName: '',
        screenId: ''
      },
      rules: {
        screenId: [{ required: true, message: '请选择设备', trigger: 'change', type: 'number' }]
      },
      deviceList: [],
      screenMap: {},
      loading: false
    }
  },
  methods: {
    show() {
      this.defaultForm.shopId = localStorage.getItem('shopId')
      this.defaultForm.shopName = localStorage.getItem('shopName')
      this.$util.post(this, {
        url: this.baseUrl + '/screen/bo/find/unassigned',
        params: {},
        config: null,
        errorInfo: false,
        success: (res) => {
          if (res.succeed) {
            this.deviceList = res.data || []
            this.deviceList.sort((firstItem, secondItem) => new Date(secondItem.updateTime).getTime() - new Date(firstItem.updateTime).getTime())
            this.screenMap = {}
            this.deviceList.forEach(item => {
              this.screenMap[item.screenId + ''] = item
            })
          }
        },
        complete: () => {
          this.loading = false
        }
      })
      this.visible = true
    },
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const customParams = {
            shopId: localStorage.getItem('shopId')
          }
          this.$util.post(this, {
            url: this.baseUrl + '/screen/bo/update',
            params: Object.assign({}, this.screenMap[this.defaultForm.screenId + ''], customParams),
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.$message.success('新增投屏设备成功')
                this.$emit('refresh')
                this.cancel()
              }
            },
            complete: () => {
              this.loading = false
            }
          })
          this.cancel()
        } else {
          this.loading = false
        }
      })
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.defaultForm = {
        shopId: '',
        shopName: '',
        screenId: ''
      }
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">
  .device-smart-screen-box {
    .form-label {
      height: 40px;
      line-height: 40px;
    }
    .device-add-btn {
      margin-left: 20/192rem;
    }
    .device-select-container {
      margin-top: 24/192rem;
      width: 100%;
      height: 360px;
      .device-select-item {
        width: 279px;
        height: 30px;
        line-height: 30px;
        display: flex;
        &-label {
          flex: 1;
        }
        &-time {
          width: 145px;
        }
      }
      .el-transfer {
        height: 100%;
        .el-transfer-panel {
          width: 335px;
          height: 100%;
        }
      }
    }

  }
</style>
