<template>
  <div class="login-box">
    <div class="login-container">
      <div class="login-content">
        <div class="login-content-center">
          <div class="login-title"/>
          <div class="login-title-chinese">梅赛德斯-奔驰智能展厅管理平台</div>
          <div class="login-title-en">
            <em>Mercedes-Benz Intelligent Showroom Management Platform（ISMP）</em>
          </div>
          <div class="option-btn">
            <el-input
              v-model="loginForm.userName"
              prefix-icon="el-icon-user"
              placeholder="请输入用户名"
            />
          </div>
          <div class="option-btn">
            <el-input
              v-model="loginForm.pwd"
              prefix-icon="el-icon-lock"
              type="password"
              placeholder="请输入密码"
            />
          </div>
          <div class="option-btn">
            <el-button
              :loading="loading"
              type="primary"
              @click="login"
            >登 录</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        userName: '',
        pwd: ''
      },
      loading: false
    }
  },
  methods: {
    login() {
      this.loading = true
      if (this.loginForm.userName && this.loginForm.pwd) {
        this.toLoginUser()
      } else {
        this.$message.error('请输入用户名和密码！')
        this.loading = false
      }
    },
    toLoginUser() {
      this.$util.post(this, {
        url: this.otherUrl + 'oauth/login/bo',
        params: {
          username: this.loginForm.userName,
          password: this.loginForm.pwd
        },
        config: {},
        success: (res) => {
          if (!res.succeed) {
            this.$message.error(res.error_description)
          } else {
            if (!res.data.role || res.data.role.indexOf('TERMINAL_BO') < 0) {
              this.$message.warning('该账户无平台访问权限')
              return
            }
            localStorage.setItem('token', res.data.access_token)
            localStorage.setItem('userName', this.loginForm.userName)
            localStorage.setItem('userId', res.data.userId)
            localStorage.setItem('roles', res.data.role ? res.data.role.join(',') : '')
            this.$router.push({ name: 'smartScreen', query: { v: new Date().getTime() }})
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="less">
@import url('@/gobal/css/common.less');
.login-box {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: top;
	.login-container {
		width: 100%;
		height: 50%;
		background-color: rgba(5, 9, 12, 1);
    background-image: url('../../assets/benz-bg.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
		.login-content {
			width: 420px;
			height: 558px;
			position: absolute;
      margin-left: calc(~'50% - 210px');
			top: calc(~'50% - 279px');
			background-color: @light-bg;
			box-shadow: 0 0 10px fade(#262626, 30%);
			display: flex;
			justify-content: center;
			&-center {
				width: 100%;
				margin-top: 30/192rem;
				.login-title {
          width: 100%;
          height: 162px;
          background-image: url('../../assets/benz-logo.png');
          background-repeat: no-repeat;
          background-position-x: center;
          background-position-y: bottom;
          background-size: 47%;
				}
        .login-title-chinese {
          font-size: 20px;
          color: #000000;
          font-weight: bold;
          text-align: center;
        }
        .login-title-en {
          // font-family:  '微软雅黑 Oblique', '微软雅黑', sans-serif;
          font-size: 16px;
          color: #333333;
          text-align: center;
        }
				.option-btn {
					width: 100%;
					margin-top: 20/192rem;
          padding: 0 60px;
          box-sizing: border-box;
          .el-input {
            height: 50px;
            &__inner {
              height: 100%;
              font-size: 20px;
              padding-left: 2em;
            }
            .el-input__prefix {
              font-size: 20px;
            }
          }
					.el-button {
						width: 100%;
						padding: 15px 20px;
						font-size: 18px;
					}
				}
			}
		}
	}
}
</style>
