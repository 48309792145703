<template>
  <el-dialog :title="type === 1 ? '新增组织' : '编辑组织'" :visible="visible" :close-on-click-modal="false" class="form-inline-edit" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item label="组织名称：" prop="organizationName">
        <el-input v-model="datas.organizationName" placeholder="请输入组织名称"/>
      </el-form-item>
      <el-form-item label="所属组织：" prop="fatherOrganizationId">
        <el-select
          v-model="datas.fatherOrganizationId
          "
          placeholder="请选择所属组织">
          <el-option
            v-for="(item, index) in orgList"
            :key="index"
            :label="item.organizationName"
            :value="item.organizationId">{{ item.organizationName }}</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="组织类型：" prop="organizationType">
        <el-input v-model="datas.organizationType" placeholder="请输入组织类型"/>
      </el-form-item>
      <el-form-item label="联系电话：" prop="organizationPhone">
        <el-input v-model="datas.organizationPhone" placeholder="请输入联系电话"/>
      </el-form-item>
      <el-form-item label="邮箱：" prop="organizationEmail">
        <el-input v-model="datas.organizationEmail" placeholder="请输入邮箱"/>
      </el-form-item>
      <el-form-item label="地址：" prop="organizationAddr">
        <el-input v-model="datas.organizationAddr" placeholder="请输入地址"/>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="datas.remark" placeholder="请输入备注"/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from '@/gobal/lib/validate.js'
import { BASE_EDIT_FORM } from './index-config'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      visible: false,
      type: 1,
      loading: false,
      datas: {
        fatherOrganizationId: null,
        flag: 'Y',
        organizationAddr: '',
        organizationEmail: '',
        organizationName: '',
        organizationPhone: '',
        organizationType: '',
        remark: '',
        status: 1,
        organizationId: ''
      },
      rules: {
        organizationName: [{ required: true, message: '请输入组织名称', trigger: 'blur' }],
        organizationPhone: [{ required: false, validator: validate.validatePhone, trigger: 'blur' }],
        organizationEmail: [{ required: false, validator: validate.validateEmail, trigger: 'blur' }]
      },
      version: 0
    }
  },
  computed: {
    orgList() {
      return this.$store.state.common.orgList || []
    }
  },
  methods: {
    ...mapActions(['refreshOrg']),
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const url = this.baseUrl + '/organization/' + (this.type === 1 ? 'create' : 'update')
          const customParams = {}
          if (this.type === 2) {
            customParams.version = this.version
          }
          const params = Object.assign({}, this.datas, customParams)
          if (this.type === 1) {
            delete params.userId
          } else {
            delete params.userPassword
            delete params.roleIds
            delete params.shopIds
          }
          const requestType = this.type === 1 ? 'post' : 'put'
          this.$util[requestType](this, {
            url,
            params,
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.refreshOrg(this)
                this.$message.success((this.type === 1 ? '新增' : '修改') + '组织成功')
                this.$emit('refresh')
                this.cancel()
                this.$bus.$emit('refreshOrg')
              }
            },
            complete: () => {
              this.loading = false
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    show(data, type) {
      this.type = type
      if (type !== 1) {
        this.datas = Object.assign({}, BASE_EDIT_FORM, data)
        this.version = data.version
      }
      this.visible = true
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas = Object.assign({}, BASE_EDIT_FORM, {})
      this.version = 0
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">

</style>
