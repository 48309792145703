const BASE_EDIT_FORM = {
  carPlateNumber: '',
  carVinNumber: '',
  platetype: '1',
  vehicleType: '',
  color: '',
  brand: '',
  serial: '',
  type: '',
  gateName: '',
  remark: ''
}
export { BASE_EDIT_FORM }
