<!--  -->
<template >
  <div class="vehicle-type-manage">
    <el-row :gutter="50">
      <el-col :span="12">
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>内部车辆类型</span>
          </div>
          <div v-for="(item, index) in carSources.find(item => item.code === 'INTERNAL')['carCategories']" :key="index" class="item-list">
            {{ item.description }}
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>车辆用途</span>
          </div>
          <div v-for="item in carUsingPurposes" :key="item.code">
            {{ item.description }}
          </div>
        </el-card>
      </el-col>

      <el-col :span="12">
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>临时离场原因</span>
          </div>
          <div v-for="item in carDepartureReason" :key="item.code">
            {{ item.description }}
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
    }
  },
  computed: {
    carSources() {
      return this.$store.state.common.constData.carSources || []
    },
    carUsingPurposes() {
      return this.$store.state.common.constData.carUsingPurposes || []
    },
    carDepartureReason() {
      return this.$store.state.common.constData.carDepartureReason || []
    }
  },
  mounted() {},
  methods: {}
}

</script>

<style lang='less'>
.vehicle-type-manage {
  padding: 20/192rem;
  .el-col {
    margin-bottom: 20/192rem;
  }
  .item-list {
    margin-bottom: 10/192rem;
  }
}

</style>
