<template>
  <el-dialog :title="type === 1 ? '新增角色' : '编辑角色'" :visible="visible" :close-on-click-modal="false" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item label="角色编码：" prop="roleCode">
        <el-input v-model="datas.roleCode" :disabled="2 === type" placeholder="请输入角色编码"/>
      </el-form-item>
      <el-form-item label="角色名称：" prop="roleName">
        <el-input v-model="datas.roleName" placeholder="请输入角色名称"/>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="datas.remark" placeholder="请输入备注"/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      type: 1,
      loading: false,
      datas: {
        roleName: '',
        roleCode: '',
        roleId: '',
        remark: ''
      },
      rules: {
        roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        roleCode: [{ required: true, message: '请输入角色编码', trigger: 'blur' }]
      },
      version: 0
    }
  },
  methods: {
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const url = this.baseUrl + '/authority/role/' + (this.type === 1 ? 'create' : 'update')
          const requestType = this.type === 1 ? 'post' : 'put'
          const customParams = {}
          if (this.type === 2) {
            customParams.version = this.version
          }
          this.$util[requestType](this, {
            url,
            params: Object.assign({}, this.datas, customParams),
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.$message.success((this.type === 1 ? '新增' : '修改') + '角色成功')
                this.$emit('refresh')
                this.cancel()
              }
            },
            complete: () => {
              this.loading = false
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    show(data, type) {
      this.type = type
      if (type !== 1) {
        this.datas = {
          roleName: data.roleName,
          roleCode: data.roleCode,
          roleId: data.roleId,
          remark: data.remark
        }
        this.version = data.version
      }
      this.visible = true
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas.roleId = ''
      this.version = 0
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">

</style>
