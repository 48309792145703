<template>
  <div class="page-nav-box">
    <div class="page-nav-title">
      {{ routerName }}
    </div>
    <div v-if="showNavList.indexOf($route.name) > -1" class="page-nav-shop">
      <!-- <Treeselect v-model="shopId" :options="options" placeholder="请选择门店" @select="selectNode">
        <span slot="option-label" slot-scope="{ node }" :class="{'is-shop': node.raw.isShop}">{{ node.raw.label }}</span>
      </Treeselect> -->
      <el-select v-model="shopId" placeholder="请选择门店" @change="selectShop">
        <el-option
          v-for="item in shopList"
          :key="item.shopId"
          :label="item.shopName"
          :value="item.shopId"
        />
      </el-select>
    </div>
    <page-nav-tip/>
    <div class="page-nav-right">
      <el-button type="text" size="small" icon="el-icon-refresh" @click="emitData">刷新</el-button>
      <el-button type="text" size="small" icon="el-icon-arrow-left" @click="goBack">返回</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pageNavTip from './page-nav-tip'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: {
    Treeselect,
    pageNavTip
  },
  data() {
    return {
      shopId: null,
      shopList: [],
      selectNodeObj: {},
      options: [],
      treeMap: {},
      showNavList: ['smartScreen', 'vehicleManager', 'releaseApproval', 'vehicleReception', 'appointmentManagement', 'screencastReport', 'parkingReport', 'internalVehicleManagement', 'smartGate', 'vehicleLogManagement']
    }
  },
  computed: {
    ...mapGetters(['menuMap']),
    routerName() {
      return this.menuMap[this.$route.name]
    }
  },
  created() {
    this.$nextTick(() => {
      this.shopId = localStorage.getItem('shopId') ? Number(localStorage.getItem('shopId')) : null
    })
    this.initOrg()
    this.$bus.$on('refreshOrg', () => {
      this.initOrg()
    })
  },
  methods: {
    initOrg() {
      this.$util.post(this, {
        url: this.baseUrl + '/organization/find',
        params: {},
        config: {},
        success: (res) => {
          this.initStore(res.data || [])
        }
      })
    },
    assembleTree(templateData, shops) {
      const options = []
      templateData.forEach(item => {
        const newItem = {
          id: item.organizationId + '',
          label: item.organizationName,
          isShop: false
        }
        this.treeMap[item.organizationId + ''] = newItem
        if (!item.fatherOrganizationId) {
          options.push(newItem)
        }
      })
      templateData.forEach(item => {
        if (item.fatherOrganizationId) {
          if (this.treeMap[item.fatherOrganizationId + ''].children) {
            this.treeMap[item.fatherOrganizationId + ''].children.push(this.treeMap[item.organizationId + ''])
          } else {
            this.treeMap[item.fatherOrganizationId + ''].children = [this.treeMap[item.organizationId + '']]
          }
        }
      })
      shops.forEach(item => {
        const newItem = {
          id: item.shopId + '',
          label: item.shopName,
          isShop: true
        }
        if (item.organizationId) {
          if (this.treeMap[item.organizationId + '']?.children) {
            this.treeMap[item.organizationId + ''].children.push(newItem)
          } else {
            this.treeMap[item.organizationId + ''].children = [newItem]
          }
        }
      })
      this.options = options
    },
    initStore(orgs) {
      this.$util.post(this, {
        url: this.baseUrl + '/shop/find',
        params: {},
        config: {},
        success: (res) => {
          this.shopList = res.data
          // if (res.data.length > 0 && !localStorage.getItem('shopId')) {
          //   this.shopId = res.data[0].shopId
          //   localStorage.setItem('shopId', res.data[0].shopId)
          //   localStorage.setItem('shopName', res.data[0].shopName)
          // }
          this.assembleTree(orgs, res.data || [])
        }
      })
    },
    emitData() {
      console.log(111)
      this.$router.go(0)
      this.$bus.$emit('gobalFresh', 'fresh' + new Date())
    },
    goBack() {
      this.$router.back()
    },
    selectShop(id) {
      console.log(this.$store.state)
      localStorage.setItem('shopId', id)
      this.$store.commit('setShopId', id)
      console.log(this.$store.state.report.shopId)

      const shopName = this.shopList.find(item => item.shopId === id)['shopName']
      localStorage.setItem('shopName', shopName)
      this.$bus.$emit('gobalFresh', 'fresh' + new Date())
    },
    selectNode(node) {
      if (!node.isShop) {
        this.$message.warning('请选择门店')
        if (this.selectNodeObj.id) {
          this.$nextTick(() => {
            this.shopId = this.selectNodeObj.id
          })
        }
      } else {
        this.selectNodeObj = node
        localStorage.setItem('shopId', node.id)
        localStorage.setItem('shopName', node.label)
        this.$bus.$emit('gobalFresh', 'fresh' + new Date())
      }
    }
  }
}
</script>

<style lang="less">
  @pageNavHeight: 50px;
  .page-nav-box {
    width: 100%;
    height: @pageNavHeight;
    line-height: @pageNavHeight;
    background-color: @light-bg;
    margin-bottom: 10/192rem;
    .page-nav-title {
      float: left;
      width: 200/192rem;
      font-size: 16px;
      height: 25/192rem;
      border-left: 4/192rem solid @main-color;
      text-indent: 1em;
      line-height: 25/192rem;
      margin-top: 12.5/192rem;
      margin-left: 2em;
    }
    .page-nav-shop {
      width: 300/192rem;
      float: left;
      height: 40/192rem;
      .vue-treeselect {
        margin-top: 8/192rem;
      }
      .vue-treeselect__control {
        display: inline-block;
        height: 36/192rem;
        .vue-treeselect__value-container {
          height: 36/192rem;
        }
        .vue-treeselect__input-container {
          height: 36/192rem;
          input {
            position: relative;
            top: -9/192rem;
          }
        }
      }
    }
    .page-nav-right {
      width: 160px;
      float: right;
      margin-right: 20/192rem;
    }
    .is-shop {
      color: @main-color;
    }
  }
</style>
