<template>
  <el-dialog :title="type === 1 ? '变更接待确认' : type === 2 ? '补录离场信息' : type === 3 ? '电子放行单' : ''" :visible="visible" :close-on-click-modal="false" class="form-inline-edit" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item label="车牌号/VIN：" prop="carPlateNumber">
        <el-input v-model="datas.carPlateNumber" placeholder="请输入车牌号/VIN" disabled/>
      </el-form-item>
      <el-form-item label="客户姓名：" prop="customerName">
        <el-input v-model="datas.customerName" :disabled="type === 2" placeholder="请输入客户姓名"/>
      </el-form-item>
      <el-form-item label="手机号：" prop="customerPhone">
        <el-input v-model="datas.customerPhone" :disabled="type === 2" placeholder="请输入手机号"/>
      </el-form-item>
      <el-form-item label="性别：" prop="customerGender">
        <el-radio-group v-model="datas.customerGender" :disabled="type === 2">
          <el-radio v-for="item in genders" :label="item.code" :key="item.code">{{ item.description }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="里程表信息：" prop="mileage">
        <el-input v-model="datas.mileage" :disabled="type === 2" placeholder="请输入里程表信息"/>
        km
      </el-form-item>
      <el-form-item label="业务类型：" prop="businessType">
        <el-select v-model="datas.businessType" :disabled="type === 2" placeholder="请选择业务类型">
          <el-option
            v-for="item in businessTypeList"
            :key="item.code"
            :label="item.description"
            :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="业务人员：" prop="salesman">
        <el-input v-model="datas.salesman" :disabled="type === 2" placeholder="请输入业务人员"/>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="datas.remark" :autosize="{ minRows: 4}" :disabled="type === 2" type="textarea" placeholder="请输入备注" maxlength="100" show-word-limit/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button v-if="type === 1" :loading="loading" type="primary" @click="save">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from '@/gobal/lib/validate.js'
export default {
  data() {
    return {
      visible: false,
      type: 1, // 1 变更接待 2接待详情
      loading: false,
      datas: {
        carPlateNumber: '',
        customerName: '',
        customerPhone: '',
        customerGender: '',
        salesman: '',
        mileage: '',
        businessType: '',
        remark: ''
      },
      version: 0,
      rules: {
        carPlateNumber: [{ required: true, message: '请输入车牌号/VIN', trigger: 'blur' }],
        customerPhone: [{ required: false, validator: validate.validatePhone, trigger: 'blur' }],
        businessType: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
        businessPeople: [{ required: true, message: '请输入接待人', trigger: 'blur' }]
      }
    }
  },
  computed: {
    genders() {
      return this.$store.state.common.constData.genders || []
    },
    businessTypeList() {
      return this.$store.state.common.constData.businessTypes || []
    },
    sourceData() {
      return this.$store.state.common.sourceData || []
    }
  },
  methods: {
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const url = this.baseUrl + '/customerReception/update'
          // 接待
          const customParams = {
            shopId: Number(localStorage.getItem('shopId')),
            mileage: Number(this.datas.mileage),
            receptionId: this.receptionId,
            version: this.version
          }
          const params = Object.assign({}, this.datas, customParams)
          this.$util['put'](this, {
            url,
            params,
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.$message.success('接待变更成功')
                this.$emit('refresh')
                this.cancel()
              }
            },
            complete: () => {
              this.loading = false
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    // 根据approachId 获取接待信息
    getReceptiveInfo(approachId) {
      const params = {
        approachId: approachId,
        shopId: Number(localStorage.getItem('shopId'))
      }
      const url = this.baseUrl + '/customerReception/pageable'
      this.$util['post'](this, {
        url,
        params,
        config: null,
        errorInfo: false,
        success: (res) => {
          console.log(res)
          const list = res.data.content
          if (list.length > 0) {
            this.receptionId = list[0].receptionId
            this.datas = Object.assign(this.datas, {
              carPlateNumber: list[0].carPlateNumber,
              customerName: list[0].customerName,
              customerPhone: list[0].customerPhone,
              customerGender: list[0].customerGender,
              salesman: list[0].salesman,
              mileage: list[0].mileage,
              businessType: list[0].businessType,
              remark: list[0].remark
            })
          }
        },
        complete: () => {
        }
      })
    },
    show(data, type) {
      const { approachId, carPlateNumber } = data
      this.visible = true
      // 获取接待信息
      this.getReceptiveInfo(approachId)
      this.datas = Object.assign({}, this.datas, { approachId, carPlateNumber })
      this.type = type
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas = Object.assign({}, this.datas, {})
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">

</style>
