<template>
  <el-dialog :title="type === 1 ? '新增版本' : '编辑版本'" :visible="visible" :close-on-click-modal="false" class="form-inline-edit" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item label="版本类型：" prop="versionType">
        <el-select v-model="datas.versionType" clearable placeholder="请选择版本类型">
          <el-option
            v-for="item in versionTypes"
            :key="item.code"
            :label="item.description"
            :value="item.code"/>
        </el-select>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="datas.remark" placeholder="请输入备注"/>
      </el-form-item>
      <el-form-item label="文件：" prop="fileList">
        <el-upload
          ref="upload"
          :limit="1"
          :on-change="handlePreview"
          :on-remove="handleRemove"
          :file-list="datas.fileList"
          :auto-upload="false"
          class="upload-demo"
          drag
          action="https://jsonplaceholder.typicode.com/posts/">
          <i class="el-icon-upload"/>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from '@/gobal/lib/validate.js'
import { BASE_EDIT_FORM } from './index-config'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      visible: false,
      type: 1,
      loading: false,
      datas: {
        fileList: [],
        versionType: '',
        remark: ''
      },
      rules: {
        versionType: [{ required: true, message: '请选择版本类型', trigger: 'change' }],
        fileList: [{ required: true, message: '请上传文件', trigger: 'change' }],
        remark: [{ required: true, message: '请填写备注', trigger: 'blur' }]
      },
      version: 0
    }
  },
  computed: {
    versionTypes() {
      return this.$store.state.common.constData.versionTypes || []
    }
  },
  methods: {
    ...mapActions(['refreshOrg']),
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const url = this.baseUrl + '/version/create/file' + (this.type === 1 ? '' : '')
          const formdata = new FormData()
          formdata.append('versionType', this.datas.versionType)
          formdata.append('remark', this.datas.remark)
          formdata.append('file', this.datas.fileList[0].raw)
          const requestType = this.type === 1 ? 'post' : 'post'
          this.$util[requestType](this, {
            url,
            params: formdata,
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.$message.success((this.type === 1 ? '新增' : '修改') + '版本成功')
                this.$emit('refresh')
                this.cancel()
              }
            },
            complete: () => {
              this.loading = false
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      this.datas.fileList = []
    },
    handlePreview(file) {
      console.log(file)
      this.datas.fileList.push(file)
    },
    show(data, type) {
      this.type = type
      if (type !== 1) {
        this.datas = Object.assign({}, BASE_EDIT_FORM, data)
        this.version = data.version
      }
      this.visible = true
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas = Object.assign({}, BASE_EDIT_FORM, {})
      this.version = 0
      this.loading = false
      this.visible = false
      this.datas.fileList = []
    }
  }
}
</script>

<style lang="less">

</style>
