<!-- 头部展示信息 -->
<template>
  <div class="page-nav-tip">
    <p v-if="routerName === 'vehicleManager'">今日外部车辆累计：入场 {{ vehicleManager.approach || 0 }} 辆，出场 {{ vehicleManager.departure || 0 }} 辆，留场 <span class="keep">{{ vehicleManager.totelPresent || 0 }}</span>  辆</p>
    <p v-else-if="routerName === 'releaseApproval'">本店有 {{ releaseApproval.unApproved || 0 }} 辆车需要审批</p>
    <p v-else-if="routerName === 'vehicleReception'">您已接待 {{ vehicleReception.num }} 辆车</p>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data() {
    const that = this
    return {
      routerName: that.$route.name,
      vehicleManager: {
        // external: 0,
        // externalDeparture: 0
        approach: 0,
        departure: 0,
        totelPresent: 0
      },
      releaseApproval: {
        approved: 0,
        unApproved: 0
      },
      vehicleReception: {
        num: 0
      }
    }
  },
  watch: {
    '$route.name': function(val) {
      this.routerName = val
      this.dealActions()
    }
  },
  mounted() {
    this.dealActions()
  },
  methods: {
    dealActions() {
      const actions = {
        // 车辆管理
        'vehicleManager': () => {
          this.getVehicleManagerInfo()
        },
        // 放行审批
        'releaseApproval': () => {
          console.log(111)
          this.getReleaseApproval()
        },
        'vehicleReception': () => {
          this.getVehicleReception()
        },
        'default': () => {

        }
      }
      const action = actions[this.$route.name] || actions['default']
      action()
    },
    getVehicleManagerInfo() {
      const data = {
        startTime: new Date().toLocaleString('lt-LT'),
        endTime: new Date().toLocaleString('lt-LT'),
        shopId: this.$store.state.report.shopId
      }
      this.isLoadingAttendance = true
      const params = this.$util.preProcessData(Object.assign({}, data))
      const url = this.baseUrl + '/report/car/attendance/statistics'
      this.$util.post(this, {
        // url: this.baseUrl + `/report/car/statistics/${Number(localStorage.getItem('shopId'))}/byType`,
        url,
        params,
        config: {},
        success: (res) => {
          console.log(res)
          if (res.succeed) {
            this.$set(this, 'vehicleManager', res.data)
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {}
      })
    },
    getReleaseApproval() {
      this.$util.get(this, {
        url: this.baseUrl + `/report/audit/statistics/${Number(localStorage.getItem('shopId'))}/byStatus`,
        config: {},
        success: (res) => {
          console.log(res)
          if (res.succeed) {
            this.$set(this, 'releaseApproval', res.data)
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {}
      })
    },
    getVehicleReception() {
      const customParams = {
        perPage: 10000,
        page: 1,
        pageStart: 1,
        shopId: Number(localStorage.getItem('shopId')),
        carSource: 'EXTERNAL',
        flag: 'N'
      }
      const params = this.$util.preProcessData(Object.assign({}, customParams))
      const url = this.baseUrl + '/customerReception/pageable'
      this.$util.post(this, {
        url,
        params,
        config: {},
        success: (res) => {
          console.log(res)
          if (res.succeed) {
            this.vehicleReception.num = res.data.content.length
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    }
  }
}

</script>

<style lang='less'>
  .page-nav-tip {
    display: inline-block;
    margin-left: 50px;
    .keep {
      color: #409EFF;
    }
  }
</style>
