// 修复APP下载页面跨域请求多次弹窗“请求异常”问题

import { Message } from 'element-ui'
let messageInstance = null
const rewriteMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  messageInstance = Message(options)
}
['error', 'success', 'info', 'warning'].forEach(type => {
  rewriteMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return rewriteMessage(options)
  }
})
// 错误提示中使用
export default function handleError(data) {
  if (data instanceof Error) {
    rewriteMessage.error(data.message)
  } else if (typeof data === 'string') {
    rewriteMessage.error(data)
  } else {
    rewriteMessage.error(data.data.msg)
  }
  return window.Promise.reject(data)
}
