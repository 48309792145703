<template>
  <el-dialog :title="type === 1 ? '新建车辆' : '编辑车辆'" :visible="visible" :close-on-click-modal="false" class="form-inline-edit" @close="cancel">
    <el-form ref="defaultForm" :model="datas" :rules="rules" inline label-width="130px">
      <el-form-item v-if="type !== 2" label="车牌类型：" prop="plateType">
        <el-radio-group v-model="datas.plateType">
          <el-radio label="1">正式车牌</el-radio>
          <el-radio label="0">临时车牌</el-radio>
          <el-radio label="2">VIN码</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="datas.plateType === '2'" label="VIN：" prop="carVinNumber">
        <el-input v-model="datas.carVinNumber" :disabled="type !== 1" placeholder="请输入VIN"/>
      </el-form-item>

      <el-form-item v-else-if="datas.plateType === '0' || datas.plateType === '1' || type === 2" label="车牌号：" prop="carPlateNumber">
        <el-input v-model="datas.carPlateNumber" :disabled="type !== 1" placeholder="请输入车牌号" />
      </el-form-item>
      <el-form-item label="车辆类型：" prop="carCategory">
        <el-select v-model="datas.carCategory" clearable placeholder="请选择车辆类型">
          <el-option
            v-for="item in carCategoryList"
            :key="item.code"
            :label="item.description"
            :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="颜色：" prop="color">
        <el-select v-model="datas.color" clearable placeholder="请选择颜色">
          <el-option
            v-for="item in colors"
            :key="item"
            :label="item"
            :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="品牌：" prop="brand">
        <el-input v-model="datas.brand" placeholder="请输入品牌"/>
      </el-form-item>
      <el-form-item label="车系：" prop="serial">
        <el-input v-model="datas.serial" placeholder="请输入车系"/>
      </el-form-item>
      <el-form-item label="车型：" prop="type">
        <el-input v-model="datas.type" placeholder="请输入车型"/>
      </el-form-item>
      <!-- <el-form-item label="道闸口：" prop="gateName">
        <el-input v-model="datas.gateName" placeholder="请输入道闸口"/>
      </el-form-item> -->
      <el-form-item label="手动入场备注：" prop="remark">
        <el-input v-model="datas.remark" :autosize="{ minRows: 4}" type="textarea" placeholder="请输入备注" maxlength="100" show-word-limit/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from '@/gobal/lib/validate.js'
import { BASE_EDIT_FORM } from './index-config'
export default {
  data() {
    const that = this
    return {
      visible: false,
      type: 1,
      loading: false,
      datas: {
        carPlateNumber: '',
        carVinNumber: '',
        carCategory: '',
        platetype: '',
        vehicleType: '',
        color: '',
        brand: '',
        serial: '',
        gateName: '',
        type: '',
        remark: ''
      },
      rules: {
        gateName: [{ required: true, message: '请输入道闸口', trigger: 'change' }],
        plateType: [{ required: true, message: '请选择车牌类型', trigger: 'change' }],
        carPlateNumber: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
        carVinNumber: [{ required: true, message: '请输入VIN', trigger: 'blur' }],
        carCategory: [{ required: true, message: '请选择车辆类型', trigger: 'change' }]
      },
      colors: that.$store.state.common.colors
    }
  },
  computed: {
    sourceData() {
      return this.$store.state.common.sourceData || []
    },
    carCategoryList() {
      return this.$store.state.common.constData.carSources[1].carCategories || []
    }
  },
  methods: {
    handleChange(value) {
      console.log(value)
    },
    save() {
      this.loading = true
      this.$refs['defaultForm'].validate((valid) => {
        if (valid) {
          const url = this.baseUrl + '/car/' + (this.type === 1 ? 'create' : 'update')
          const customParams = {
            carSource: 'INTERNAL',
            // carCategory: 'INTERNAL',
            shopId: Number(localStorage.getItem('shopId')),
            status: 1
          }
          const params = this.$util.preProcessData(Object.assign({}, this.datas, customParams))
          if (this.datas.plateType === '2') {
            // VIN码
            delete params.carPlateNumber
          } else {
            // 车牌号
            delete params.carVinNumber
          }
          this.$util['post'](this, {
            url,
            params,
            config: null,
            errorInfo: false,
            success: (res) => {
              if (res.succeed) {
                this.$message.success((this.type === 1 ? '新增' : '修改') + '成功')
                this.$emit('refresh')
                this.cancel()
              }
            },
            complete: (res) => {
              // 手动入场需要先新增车辆在手动入场 errorCode：12003 是车牌号已经创建 则直接手动入场
              // if (this.type === 1 && res.errorCode === 12003 || res.succeed) {
              //   this.manualEntry(params);
              // } else {
              this.loading = false
              // }
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    // manualEntry (params) {
    //   const url = this.baseUrlWay + '/gate/approach/manual';
    //   this.$util['post'](this, {
    //     url,
    //     params,
    //     config: null,
    //     errorInfo: false,
    //     success: (res) => {
    //       if (res.succeed) {
    //         this.$message.success('手动入场成功')
    //         this.$emit('refresh')
    //         this.cancel()
    //       }
    //     },
    //     complete: (res) => {
    //       this.loading = false
    //     }
    //   })
    // },
    show(data, type) {
      this.type = type
      this.datas = Object.assign({}, BASE_EDIT_FORM, data)
      if (data.carVinNumber && data.carVinNumber !== '') {
        this.$set(this.datas, 'plateType', '2')
      } else {
        this.$set(this.datas, 'plateType', '1')
      }
      this.visible = true
    },
    cancel() {
      this.$refs.defaultForm.resetFields()
      this.datas = Object.assign({}, BASE_EDIT_FORM, {})
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style lang="less">

</style>
