<template>
  <div class="common-page-box">
    <div v-if="showFilter" class="common-page-filters">
      <div class="common-page-filters-filters">
        <slot name="filters"/>
      </div>
      <div class="search-btns">
        <el-button
          :loading="loading"
          type="primary"
          size="medium"
          @click="$emit('query')"
        >查询</el-button
        >
        <el-button
          type="default"
          size="medium"
          plain
          @click="$emit('reset')"
        >重置</el-button
        >
      </div>
    </div>
    <div class="common-page-content">
      <div v-if="showOptions" class="common-page-content-options">
        <slot name="btnOptions"/>
      </div>
      <div class="common-page-content-table">
        <slot name="dataTable"/>
      </div>
      <div v-if="showPage" class="common-page-content-page">
        <slot name="dataPage"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 显示过滤条件
    showFilter: {
      type: Boolean,
      default: true
    },
    // 显示操作表格选项
    showOptions: {
      type: Boolean,
      default: true
    },
    // 显示分页
    showPage: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
@borderRadius: 5/192rem;
.common-page-box {
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	.common-page-filters {
		width: 100%;
		display: flex;
		background-color: @light-bg;
		border-radius: @borderRadius;
		padding-top: 10/192rem;
		padding-left: 20/192rem;
		box-sizing: border-box;
		margin-bottom: 10/192rem;
		&-filters {
			min-height: 40px;
			flex: 1;
		}
		.search-btns {
			width: 180px;
			height: 40px;
		}
	}
	.common-page-content {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		padding: 10/192rem 20/192rem;
		box-sizing: border-box;
		background-color: @light-bg;
		border-radius: @borderRadius;
		flex-grow: 1;
		&-options {
			width: 100%;
			height: 40/192rem;
			margin-bottom: 10/192rem;
		}
		&-table {
			width: 100%;
			flex-grow: 1;
      box-sizing: border-box;
      overflow-x: hidden;
		}
		&-page {
			width: 100%;
			height: 40px;
			padding-top: 20px;
			text-align: right;
		}
	}
}
</style>
