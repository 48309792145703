const BASE_EDIT_FORM = {
  shopName: '',
  dmsCode: '',
  shopId: '',
  organizationId: '',
  shopType: '',
  shopPhone: '',
  shopEmail: '',
  gssnCode: '',
  remark: '',
  autoReleaseAll: 'Y',
  areas: []
}
export { BASE_EDIT_FORM }
