<!-- 帮助中心 -->
<template>
  <div class="app-download-box">
    <el-row class="app-download">
      <el-col :span="10" class="left">
        <img class="phone-img" src="@/assets/phone.png" alt="">
      </el-col>
      <el-col :span="14" class="right">
        <div class="title">
          智慧展厅 - 平台
        </div>
        <div class="tips">
          服务奔驰平台专用App
        </div>
        <div class="download-box">
          <el-row>
            <el-col :span="8">
              <div class="version-tip">
                <img class="version-icon" src="@/assets/ios.png" alt="">iphone版本
              </div>
              <div class="content">
                <!-- <div ref="iosQrCodeUrl" class="qrcode"/>-->
                <img class="version-icon" src="@/assets/ios_code.png" alt="">
              </div>
            </el-col>
            <el-col :span="8">
              <div class="version-tip">
                <img class="version-icon" src="@/assets/android.png" alt="">Android版本
              </div>
              <div class="content android">
                <!-- <div ref="androidQrCodeUrl" class="qrcode"/>-->
                <img class="version-icon" src="@/assets/android_code.png" alt="">
                <div class="version-desc">Android APP扫码下载</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <!-- <el-col :span="12">
        <div class="title">安卓下载</div>
        <div class="content">
          <div class="qrcode" ref="androidQrCodeUrl"></div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title">IOS下载</div>
        <div class="content">
          <div class="qrcode" ref="iosQrCodeUrl"></div>
        </div>
      </el-col> -->
    </el-row>
    <!-- <div class="history-list">
      <div v-if="currentSelectVersion === 1" class="check-box" >
        <el-button type="danger" round>iPhone版</el-button>
        <div class="orher-check-text" @click="currentSelectVersion = 2">Android版</div>
      </div>
      <div v-if="currentSelectVersion === 2" class="check-box" >
        <div class="orher-check-text" @click="currentSelectVersion = 1">iPhone版</div>
        <el-button type="danger" round>Android版</el-button>
      </div>
      <div class="block">
        <el-timeline>
          <template v-if="currentSelectVersion === 1">
            <el-timeline-item v-for="(item, index) in timelineIosList" :key="index" :timestamp="item.updateTime" placement="top">
              <el-card>
                <h4>{{ versionTypes ? versionTypes.find(itemType => itemType.code === item.versionType) && versionTypes.find(itemType => itemType.code === item.versionType)['description'] || '' : '' }}</h4>
                <p>{{ item.remark }}</p>
              </el-card>
            </el-timeline-item>
          </template>
          <template v-if="currentSelectVersion === 2">
            <el-timeline-item v-for="(item, index) in timelineAndroidList" :key="index" :timestamp="item.updateTime" placement="top">
              <el-card>
                <h4>{{ versionTypes ? versionTypes.find(itemType => itemType.code === item.versionType) && versionTypes.find(itemType => itemType.code === item.versionType)['description'] || '' : '' }}</h4>
                <p>{{ item.remark }}</p>
              </el-card>
            </el-timeline-item>
          </template>
        </el-timeline>
      </div>
    </div> -->
  </div>
</template>

<script>
// import QRCode from 'qrcodejs2'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      currentSelectVersion: 1, // 1 iphone 2安卓
      timelineIosList: [],
      timelineAndroidList: []
    }
  },
  computed: {
    versionTypes() {
      return this.$store.state.common.constData.versionTypes || []
    }
  },
  mounted() {
    console.log(this.versionTypes)
    // // 安卓二维码
    this.creatAndroidQrCode()
    // IOS二维码
    this.creatIosQrCode()
    // 获取安卓版本信息
    this.queryPage(2)
    // 获取ios版本信息
    this.queryPage(3)
  },
  methods: {
    // creatAndroidQrCode() {
    //   const params = {}
    //   this.$util.get(this, {
    //     url: this.baseUrl + '/version/2/latest',
    //     params,
    //     config: {},
    //     success: (res) => {
    //       if (res && res.data) {
    //         console.log(res, res.data.versionFile)
    //         new QRCode(this.$refs.androidQrCodeUrl, {
    //           text: res.data.versionFile || '', // 需要转换为二维码的内容
    //           width: 150,
    //           height: 150,
    //           colorDark: '#000000',
    //           colorLight: '#ffffff',
    //           correctLevel: QRCode.CorrectLevel.H
    //         })
    //       }
    //     }
    //   })
    // },
    // 临时写死方案
    creatAndroidQrCode() {
      // new QRCode(this.$refs.androidQrCodeUrl, {
      //   text: 'https://d1018ns3ismp0000.s3.cn-north-1.amazonaws.com.cn/8a059a20-8550-4845-ad5c-84f44d011dd9.apk' || '', // 需要转换为二维码的内容
      //   width: 150,
      //   height: 150,
      //   colorDark: '#000000',
      //   colorLight: '#ffffff',
      //   correctLevel: QRCode.CorrectLevel.H
      // })
    },
    creatIosQrCode() {
      // const params = {}
      // this.$util.get(this, {
      //   url: this.baseUrl + '/version/3/latest',
      //   params,
      //   config: {},
      //   success: (res) => {
      //     if (res && res.data) {
      //       console.log(res)
      //       new QRCode(this.$refs.iosQrCodeUrl, {
      //         text: res.data.versionFile || '', // 需要转换为二维码的内容
      //         width: 150,
      //         height: 150,
      //         colorDark: '#000000',
      //         colorLight: '#ffffff',
      //         correctLevel: QRCode.CorrectLevel.H
      //       })
      //     }
      //   }
      // })
    },
    // 临时写死方案
    // creatIosQrCode() {
    //   new QRCode(this.$refs.iosQrCodeUrl, {
    //     text: 'https://d1018ns3ismp0000.s3.cn-north-1.amazonaws.com.cn/8a059a20-8550-4845-ad5c-84f44d011dd9.apk' || '', // 需要转换为二维码的内容
    //     width: 150,
    //     height: 150,
    //     colorDark: '#000000',
    //     colorLight: '#ffffff',
    //     correctLevel: QRCode.CorrectLevel.H
    //   })
    // },
    queryPage(type) {
      this.loading = true
      const params = this.$util.preProcessData(Object.assign({}, {
        perPage: 9999,
        page: 1,
        pageStart: 1,
        versionType: type
      }))
      this.$util.post(this, {
        url: this.baseUrl + '/version/find/pageable',
        params,
        config: {},
        success: (res) => {
          if (res && res.data && res.data.content) {
            console.log(res.data.content)
            if (type === 2) {
              this.timelineAndroidList = res.data.content
            }
            if (type === 3) {
              this.timelineIosList = res.data.content
            }
          }
        },
        error: (res) => {
          console.log(res)
        },
        complete: () => {
          this.loading = false
        }
      })
    }
  }
}

</script>

<style lang='less'>
.app-download-box {
  padding-top: 50/192rem;
  .app-download {
    display: flex;
    // align-items: center;
  }
  .phone-img {
    width: 70%;
    margin-right: 50px;
  }
  .left {
    text-align: right;
  }
  .right {
    display: flex;
    flex-direction: column;
    margin-top: 100/192rem;
    .download-box {
      margin-top: 40/192rem;
    }
    .title {
      font-size: 40/192rem;
    }
    .tips {
      font-size: 30/192rem;
      color: rgb(153, 153, 153);
      margin: 20/192rem 0;
    }
    .version-tip {
      width: 180px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 60px;
      border: 1px solid rgba(245, 108, 108, 1);
      color: rgba(245, 108, 108, 1);
      .version-icon {
        width: 25/192rem;
        margin-right: 10/192rem;
      }
    }
  }
  .content {
    text-align: left;
    margin-top: 20/192rem;
    padding-left: 16px;
    .qrcode {
      display: inline-block;
      margin: 0px auto;
    }
    img{
      width: 150px;
      height: 150px;
    }
    &.android {
      margin-top: 26/192rem;
      img{
        width: 130px;
        height: 130px;
        display: block;
      }
      .version-desc{
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
      }
    }
  }
  .history-list {
    margin: 50/192rem 0;
    .check-box {
      padding-left: 50/192rem;
      margin: 0 auto;
      width: 1200/192rem;
      margin-bottom: 20/192rem;
      .orher-check-text {
        display: inline-block;
        padding: 10/192rem 30/192rem;
        cursor: pointer;
      }
    }
    .block {
      margin: 0 auto;
      width: 1200/192rem;
    }
  }
}
</style>
